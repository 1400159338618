import React, {useState} from 'react'
import {motion} from 'framer-motion'
import city1 from '../../../Assests/space/city1.png'
import city2 from '../../../Assests/space/city2.png'
import city3 from '../../../Assests/space/city3.png'
import planet1 from '../../../Assests/space/planet1.png'
import planet2 from '../../../Assests/space/planet2.png'
import { teamMembers } from "../../../static/data";


const Testimoney = () => {

  const [expandedIndex, setExpandedIndex] = useState(null)

  const handleCardClick = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index)
  }

  const cardVariants = {
    expanded: {
      width: "400px"
    },
    collapsed: {
      width: '200px'
    }
  }

  const cardImages = [city1, city2, city3, planet1, planet2]

  const cardDescriptions = [
    'This is a description, write whatever you need here, this is just text for a test',
    'This is a description, write whatever you need here, this is just text for a test',
    'This is a description, write whatever you need here, this is just text for a test',
    'This is a description, write whatever you need here, this is just text for a test',
    'This is a description, write whatever you need here, this is just text for a test',
  ]
  return (
   <section className='mt-20'>
      <h2 data-aos="fade-up" className="mb-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center ">
        Meet Our Team{" "}
        <span className="text-[#A71200]">
         
        </span>
      </h2>
      <p     data-aos="fade-up"
              className="text-sm text-gray-500 tracking-wide leading-5 text-center mb-4">
        At TOMA, we are driven by a passion for innovation and a commitment to
        revolutionizing legal and compliance solutions.
        <br />
        Our diverse team brings together expertise from various disciplines to
        create a platform that empowers businesses and individuals alike.
      </p>
      <div data-aos="fade-up" className='mt-12 flex flex-col md:flex-row justify-center items-center gap-5'>
        {teamMembers.map((member, index) => (
            <motion.div            
            key={index}
            className={`card cursor-pointer h-[500px] bg-cover bg-center rounded-[20px] ${index === expandedIndex ? 'expanded': ''}`}
            variants={cardVariants}
            initial="collapsed"
            animate={index === expandedIndex ? 'expanded': 'collapsed'}
            transition={{duration: 0.5}}
            onClick={() => handleCardClick(index)}
            style={{
                            backgroundImage: `url(${cardImages[index]})`,

            }}
          >
             <div className="bg-gray-100 dark:bg-[#780D00] text-center p-8 rounded-lg transition duration-300 ease-in-out hover:bg-gradient-to-r from-orange-500 to-orange-800  hover:text-white">
              <img
                src={member.imageUrl}
                className="mx-auto rounded-full mb-4 w-36 h-36 object-cover"
                alt="pic"
              />
              <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
              <div className="text-gray-500 italic mb-4">{member.position}</div>
              <ul className="flex justify-center mt-4 mb-4">
                {member.socialLinks.map((social, idx) => (
                  <li key={idx} className="mx-2">
                    <a
                      href={social.link}
                      className={`p-2 rounded-full ${social.color}`}
                    >
                      <i className={social.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
               <p className="text-gray-700">{member.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
   </section>
  )
}

export default Testimoney


// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/free-mode";

// import { FreeMode, Pagination } from "swiper/modules";

// import { RxArrowTopRight } from "react-icons/rx";
// import { ServiceData } from "../../../static/data";

// const Testimoney = () => {
//   return (
//     <div className="flex items-center justify-center flex-col h-[900px] bg-[#6c34af]">
//       <Swiper
//         breakpoints={{
//           340: {
//             slidesPerView: 2,
//             spaceBetween: 15,
//           },
//           700: {
//             slidesPerView: 3,
//             spaceBetween: 15,
//           },
//         }}
//         freeMode={true}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[FreeMode, Pagination]}
//         className="max-w-[90%] lg:max-w-[80%]"
//       >
//         {ServiceData.map((item) => (
//           <SwiperSlide key={item.title}>
//             <div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[400px] lg:w-[350px] overflow-hidden cursor-pointer">
//               <div
//                 className="absolute inset-0 bg-cover bg-center"
//                 style={{ backgroundImage: `url(${item.backgroundImage})` }}
//               />
//               <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
//               <div className="relative flex flex-col gap-3">
//                 <item.icon className="text-blue-600 group-hover:text-blue-400 w-[32px] h-[32px]" />
//                 <h1 className="text-xl lg:text-2xl">{item.title} </h1>
//                 <p className="lg:text-[18px]">{item.content} </p>
//               </div>
//               <RxArrowTopRight className="absolute bottom-5 left-5 w-[35px] h-[35px] text-white group-hover:text-blue-500 group-hover:rotate-45 duration-100" />
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default Testimoney;