import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonials } from "../../../static/data";

// const testimonials = [
//   {
//     id: 1,
//     name: 'Leslie Alexander',
//     role: 'Freelance React Developer',
//     image: 'https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png',
//     testimonial: '“You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page,”',
//   },
//   {
//     id: 2,
//     name: 'Michael Foster',
//     role: 'UI/UX Designer',
//     image: 'https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png',
//     testimonial: '“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users alike.”',
//   },
//   {
//     id: 3,
//     name: 'Sarah Williams',
//     role: 'Product Manager',
//     image: 'https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female-1.png',
//     testimonial: '“I cannot believe that I have got a brand new website after getting Omega. It was super easy to edit and publish.”',
//   },
// ];

const Testimonials = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,    
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  

  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <p data-aos="fade-up" className="text-lg font-medium text-gray-600 font-pj">what people have said about Toma</p>
          {/* <h2 data-aos="fade-up" className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Our happy clients say about us</h2> */}
        </div>

        <div className="relative mt-10 md:mt-24">
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="px-3">
                <div data-aos="fade-up" className="flex flex-col overflow-hidden shadow-xl mb-10">
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white dark:bg-[#780D00] lg:py-8 lg:px-7 ">
                    <div className="flex-1">
                      <div className="flex items-center">
                        {[...Array(5)].map((_, index) => (
                          <svg
                            key={index}
                            className="w-5 h-5 text-[#FDB241]"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            />
                          </svg>
                        ))}
                      </div>

                      <blockquote className="flex-1 mt-8">
                        <p className="text-lg leading-relaxed text-gray-900 dark:text-white font-pj">{testimonial.testimonial}</p>
                      </blockquote>
                    </div>

                    <div className="flex items-center mt-8">
                      <img
                        className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                        src={testimonial.image}
                        alt={testimonial.name}
                      />
                      <div className="ml-4 ">
                        <p className="text-base font-bold text-gray-900 dark:text-white font-pj">{testimonial.name}</p>
                        <p className="mt-0.5 text-sm font-pj text-gray-600">{testimonial.company}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
