import React, {useState,  useEffect } from "react";
import Custom from "../components/Route/Custom/Custom.jsx";
import Business from "../components/Route/Business/Business.jsx";
import Footer from "../components/Layout/Footer.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Navbar from "../components/Layout/Navbar.jsx";
import HeroSection from "../components/Layout/HeroSection.jsx";
import Testimonials from "../components/Route/Testimoney/Testimonials.jsx";
import Benefits from "../components/Route/Benefits/Benefits.jsx";
import "aos/dist/aos.css";
import Hero from "../components/Layout/Hero.jsx";
import Faq from "../components/Route/Faq/Faq.jsx";

const HomePage = () => {
//    const { ...rest } = props;
//  const [open, setOpen] = React.useState(true);


//  React.useEffect(() => {
//     window.addEventListener("resize", () =>
//       window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
//     );
//   }, []);

  const [orderPopup, setOrderPopup] = React.useState(false);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup);
  };

  return (
    <div className="text-center md:text-left dark:bg-[#490800] dark:text-white ">
    {/* <Sidebar open={open} onClose={() => setOpen(false)} /> */}
      <Navbar active={1} handleOrderPopup={handleOrderPopup}/>
      {/* <Hero /> */}
      <div className="max-w-7xl mx-auto pt-20 px-6">
        <HeroSection />        
        <Benefits />
        <Custom />
        <Testimonials />
        <Faq />
        <Sponsored />
       
        
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
