import React, { useState, useEffect } from 'react';

const Help = () => {
  const [savedCases, setSavedCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState({});

  useEffect(() => {
    const casesFromStorage = JSON.parse(localStorage.getItem('savedCases') || '[]');
    setSavedCases(casesFromStorage);
    const savedComments = JSON.parse(localStorage.getItem('caseComments') || '{}');
    setComments(savedComments);
  }, []);

  const handleViewCase = (caseData) => {
    setSelectedCase(caseData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCase(null);
  };

  const handleDeleteCase = (caseId) => {
    const updatedCases = savedCases.filter((c) => c.id !== caseId);
    setSavedCases(updatedCases);
    localStorage.setItem('savedCases', JSON.stringify(updatedCases));
  };

  const handleSaveComment = (caseId) => {
    const updatedComments = {
      ...comments,
      [caseId]: (comments[caseId] || []).concat(comment),
    };
    setComments(updatedComments);
    setComment('');
    localStorage.setItem('caseComments', JSON.stringify(updatedComments));
  };

  return (
    <div className="p-6">
      {/* <h1 className="text-4xl font-bold mb-6 text-center">Help - Saved Cases</h1> */}
      <div className="grid gap-6">
        {savedCases.map((caseData) => (
          <div key={caseData.id} className="p-4 border border-gray-300 rounded">
            <h2 className="text-xl font-semibold">{caseData.title}</h2>
            <p><strong>Date:</strong> {caseData.dateTitle}</p>
            <p><strong>Court Type:</strong> {caseData.courtType}</p>

            <div className="flex space-x-4 mt-2">
              <button
                onClick={() => handleViewCase(caseData)}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                View
              </button>
              <button
                onClick={() => handleDeleteCase(caseData.id)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>

            {/* Comments section */}
            <div className="mt-4">
              <h3 className="text-lg font-semibold">Comments:</h3>
              {(comments[caseData.id] || []).map((comm, index) => (
                <p key={index} className="text-gray-700 border-b">{comm}</p>
              ))}
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment..."
                className="w-full p-2 border mt-2 rounded"
              ></textarea>
              <button
                onClick={() => handleSaveComment(caseData.id)}
                className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
              >
                Add Comment
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for viewing a case */}
      {showModal && selectedCase && (
        <>
          {/* Dark overlay */}
          <div className="fixed inset-0 z-50 bg-[#000] opacity-50"></div>

          <div
          className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none flex items-center justify-center"
          id="exampleModalScrollable"
          tabIndex="-1"
          aria-labelledby="exampleModalScrollableLabel"
          aria-hidden="true"
        >
          <div
            className="pointer-events-none relative h-[calc(100%-5rem)] w-auto translate-y-[-50px] opacity-1 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 mt-20 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
          >
            <div className="pointer-events-auto relative flex max-h-[100%] lg:max-w-4xl flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
              <div className="flex justify-between items-center bg-gradient-to-r from-orange-500 to-red-800 p-4 rounded-t-md">
                <h2 className="text-2xl font-bold text-white">Case Content</h2>
                <button
                  onClick={handleCloseModal}
                  className="text-white hover:text-gray-200"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="p-6 overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4 text-center">{selectedCase.title}</h2>
                <p><strong>Date:</strong> {selectedCase.dateTitle}</p>
                <p><strong>Court Type:</strong> {selectedCase.courtType}</p>
                <p><strong>Court Division:</strong> {selectedCase.courtDivision}</p>
                <p><strong>Suit No:</strong> {selectedCase.suitNo}</p>
                <p><strong>Justices:</strong> {selectedCase.justices.join(', ')}</p>
                <p><strong>Appellants:</strong> {selectedCase.appellants.join(', ')}</p>
                <p><strong>Respondents:</strong> {selectedCase.respondents.join(', ')}</p>
                <p><strong>Appellants' Counsel:</strong> {selectedCase.counsel.appellants.join(', ')}</p>
                <p><strong>Respondents' Counsel:</strong> {selectedCase.counsel.respondents.join(', ')}</p>
                <h3 className="mt-4 font-bold">Headnotes:</h3>
                {selectedCase.headnoteList && selectedCase.headnoteList.length > 0 ? (
                  selectedCase.headnoteList.map((headnote, index) => (
                    <div key={index} className="p-4 mb-4 border rounded-lg">
                      <p><strong>{headnote.name}</strong>:</p>
                      <p className="text-gray-700">{headnote.description}</p>
                    </div>
                  ))
                ) : (
                  <p>No headnotes available for this case.</p>
                )}
                <h3 className="mt-4 font-bold">CONTENT:</h3>
                <div
                  className="prose max-w-full text-gray-700"
                  dangerouslySetInnerHTML={{ __html: selectedCase.content || 'No content available' }}
                />
              </div>

              <div className="flex justify-end bg-gradient-to-r from-orange-500 to-red-800 p-4 rounded-b-md">
                <button
                  onClick={handleCloseModal}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Help;
