import React, { useState } from 'react';

const QuestionForm = ({ questions, agreementName, onGenerateContract }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const totalSteps = questions.length;

  // Handle change in input fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: [...(prevData[name] || []), value],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: (prevData[name] || []).filter((item) => item !== value),
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validate current step inputs
  const validateCurrentStep = () => {
    const currentQuestions = questions[step].questions;
    let currentErrors = {};
    currentQuestions.forEach((q) => {
      if (!formData[q.id] && q.type !== 'checkbox') {
        currentErrors[q.id] = 'This field is required';
      }
    });
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  // Go to the next step
  const nextStep = () => {
    if (validateCurrentStep()) {
      if (step < totalSteps - 1) {
        setStep(step + 1);
        setErrors({});
      }
    }
  };

  // Go to the previous step
  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      setErrors({});
    }
  };

  // Submit the form (final step)
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateCurrentStep()) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        onGenerateContract(formData);  // Pass form data to parent component
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-bold mb-4">{agreementName}</h2>

      {/* Progress Bar */}
      <div className="bg-gray-100 dark:bg-[#780D00] p-4 border-4 rounded-xl flex flex-wrap justify-between mb-4">
        {questions.map((section, index) => (
          <div
            key={index}
            onClick={() => setStep(index)}
            className={`cursor-pointer text-center text-sm font-bold p-2 flex-1 ${
              index === step
                ? 'text-gray-800 border-b-4 border-gray-800'
                : 'text-gray-500 border-b-4 border-gray-300'
            }`}
          >
            <div
              className={`w-8 h-8 mx-auto rounded-full ${
                index === step
                  ? 'bg-gray-800 text-white'
                  : 'bg-gray-300 text-gray-600'
              } flex items-center justify-center mb-2`}
            >
              {index + 1}
            </div>
            {section.section}
          </div>
        ))}
      </div>

      <div className="w-full max-w-4xl bg-gray-100 dark:bg-[#780D00] p-6 rounded-lg shadow-md">
        <p className="text-gray-600 mb-2">
          Step {step + 1} of {totalSteps}
        </p>

        <h2 className="text-2xl font-bold mb-4">{questions[step].section}</h2>
        {loading ? (
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            <p className="text-lg font-medium">Generating contract...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {questions[step].questions.map((q) => (
              <div key={q.id} className="mb-4">
                <label htmlFor={q.id} className="block text-sm font-medium mb-2">
                  {q.label}
                </label>

                {/* Render different inputs based on question type */}
                {q.type === 'select' ? (
                  <select
                    id={q.id}
                    name={q.id}
                    value={formData[q.id] || ''}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select an option</option>
                    {q.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : q.type === 'yesno' ? (
                  <div>
                    <label className="mr-4">
                      <input
                        type="radio"
                        name={q.id}
                        value="Yes"
                        onChange={handleChange}
                        checked={formData[q.id] === 'Yes'}
                      />{' '}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={q.id}
                        value="No"
                        onChange={handleChange}
                        checked={formData[q.id] === 'No'}
                      />{' '}
                      No
                    </label>
                  </div>
                ) : q.type === 'multichoice' ? (
                  <div>
                    {q.options.map((option) => (
                      <div key={option} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          name={q.id}
                          value={option}
                          className="form-checkbox h-4 w-4 text-[#490800] border-gray-300 rounded focus:ring-[#490800]"
                          checked={formData[q.id]?.includes(option) || false}
                          onChange={handleChange}
                        />{' '}
                        <label className="ml-2">{option}</label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <input
                    type={q.type || 'text'}
                    id={q.id}
                    name={q.id}
                    value={formData[q.id] || ''}
                    onChange={handleChange}
                    className={`w-full p-2 border dark:bg-[#490800] ${
                      errors[q.id] ? 'border-red-500' : 'border-gray-300'
                    } rounded`}
                  />
                )}

                {errors[q.id] && (
                  <p className="text-red-500 text-xs">{errors[q.id]}</p>
                )}
              </div>
            ))}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-4">
              {step > 0 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-300 p-2 rounded"
                >
                  PREVIOUS
                </button>
              )}
              {step < totalSteps - 1 ? (
                <button
                  type="button"
                  onClick={nextStep}
                  className="px-4 py-2 bg-gradient-to-r from-orange-500 to-red-800 text-white rounded-md"
                >
                  SAVE AND CONTINUE
                </button>
              ) : (
                <button
                  type="submit"
                  className="bg-green-500 p-2 text-white rounded"
                  disabled={loading}
                >
                  GENERATE CONTRACT
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default QuestionForm;





