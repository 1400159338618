import React from "react";
import Signup from "../components/auth/Signup/Signup.jsx"
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const SignupPage = () => {
  return (
     <div className="dark:bg-[#490800] dark:text-white ">

      <Signup />
     </div>
      
      
  );
};

export default SignupPage;
