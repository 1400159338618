import React, { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [write, setWrite] = useState("");
  return (
    <div className="md:flex md:items-center md:justify-between mt-20 mb-20 ">
      <div className="md:w-[40%]">
        <h2 data-aos="fade-up" className="text-xl font-bold text-[#A71200]">
          GET IN TOUCH
        </h2>
        <h2 data-aos="fade-up" className="text-3xl sm:text-5xl lg:text-5xl mt-4 mb-4 tracking-wide">
          We Are Connected To Help Your Business
          <span data-aos="fade-up" className="bg-gradient-to-r from-orange-500 to-orange-800 text-transparent bg-clip-text">
            !
          </span>
        </h2>

        <p data-aos="fade-up">
          We derive fulfillment in breaking down your complex idea into
          meaningful and tangible products for your business growth
        </p>
      </div>
      <div className="md:w-[40%] mt-10 md:mt-0 ">
        <form action="" className="w-full">
          <div className="flex items-center justify-start gap-7 w-full">
            <div data-aos="fade-up" className="w-[50%]">
              <input
                placeholder="Name*"
                name="name"
                type="text"
                autocomplete="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
              />
            </div>
            <div data-aos="fade-up" className="w-[50%]">
              <input
                placeholder="Email*"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
              />
            </div>
          </div>
          <div className="flex items-center justify-start gap-7 w-full mt-5">
            <div data-aos="fade-up" className="w-[50%]">
              <input
                placeholder="Phone"
                name="number"
                type="number"
                autoComplete="number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
              />
            </div>
            <div data-aos="fade-up" className="w-[50%]">
              <input
                placeholder="Subject"
                name="subject"
                type="text"
                autocomplete="text"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
              />
            </div>
          </div>
          <div className="w-full mt-5">
            <div data-aos="fade-up" className="w-full">
              <textarea
                placeholder="Write comments"
                name="story"
                rows="5"
                cols="33"
                value={write}
                onChange={(e) => setWrite(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
              />
              {/* <input
                   placeholder="Write comments"
                   name="name"
                   type="textArea"
                   autocomplete="text"
                   required
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded
             md:text-sm"
                 /> */}
            </div>
          </div>
          <div data-aos="fade-up" className="mt-5">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
            >
              SUBMIT NOW
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
