import React from "react";
import Img1 from "../../../Assests/books/book5.jpeg";
import Img2 from "../../../Assests/books/book6.jpeg";
import Img3 from "../../../Assests/books/book8.jpg";
import { FaStar } from "react-icons/fa";

import j1 from "./Books/Guideline Informal Justice Court_ 241022Opmaak (1)_0 by Funmi Ayeni.pdf"
import j2 from "./Books/African Human Rights Law Journal AA Oba.pdf"
import j3 from "./Books/S&A Report by Funmi Ayeni.pdf"

const ServicesData = [

  {
    id: 1,
    img: Img2,
    title: "S&A Report",
    description:
      "THE INFORMAL JUSTICE COURT PROJECT AT IKOYI & KIRIKIRI FEMALE CORRECTIONAL CENTRES, LAGOS NIGERIA",
       author: "Funmi Ayeni",
      document: j3,
      text: "Download",
  },
  {
    id: 2,
    img: Img1,
    title: "Guideline Informal Justice Court",
    description: "Guideline InfoA practical guideline for setting up an Informal Justice Court in prisons and correctional centres",
      author: "Funmi Ayeni",
      document: j1,
      text: "Download",
      
  },
    {
    id: 3,
    img: Img3,
    title: "African Human Rights Law Journal",
    description:
      "The African Charter on Human and Peoples' Rights and Peoples' Rights and ouster clauses under military regimes in Nigeria: Before and After Sept 11 by AA Oba",
      author: "Funmi Ayeni",
      document: j2,
      text: "Download",
      
    
  },
    
];

const Journal = () => {
  return (
    <>
      <span id="services"></span>
      <div className="py-10">
        <div className="container">
          <div className="text-center mb-20 max-w-[400px] mx-auto">
            <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary ">
              Trending Books
            </p>
            <h1 className="text-3xl font-bold">Best Free Journals</h1>
            <p className="text-xs text-gray-400">
              Browse our collection of best journals
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 md:gap-15 place-items-center">
            {ServicesData.map((service) => (
              <div
                data-aos="zoom-in"
                className="rounded-2xl bg-white dark:bg-[#780D00] hover:bg-primary dark:hover:bg-primary relative shadow-xl duration-high group max-w-[300px]"
              >
                <div className="h-[100px]">
                  <img
                    src={service.img}
                    alt=""
                    className="max-w-[100px] block mx-auto transform -translate-y-14
                  group-hover:scale-105  duration-300 shadow-md"
                  />
                </div>
                <div className="p-4 text-center">
                  <div className="w-full flex items-center justify-center gap-1">
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                  </div>
                  <h1 className="text-xl font-bold">{service.title}</h1>
                  <p className="text-gray-500 group-hover:opacity-50 duration-high text-sm line-clamp-2 mb-4">
                    {service.description}
                  </p>
                   <a
                      href={service.document}
                      download
                      className="text-bluebg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 hover:scale-105 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    >
                      {service.text}
                    </a>               
                  
                  {/* <button
                    className="bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 hover:scale-105 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    
                  >
                    Download
                  </button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Journal;
