import React from "react";
import Footer from "../components/Layout/Footer.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Contact from "../components/Route/Contact.jsx";
import Office from "../components/Route/Office.jsx";
import Navbar from "../components/Layout/Navbar.jsx";

const ContactPage = () => {
  return (
    <div className="text-center md:text-left dark:bg-[#490800] dark:text-white ">
      <Navbar active={5} />
      <div className="max-w-7xl mx-auto px-6">
        <Office />
        <Contact />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
