import React from "react";
import Footer from "../components/Layout/Footer.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Product from "../components/Route/Product.jsx";
import Navbar from "../components/Layout/Navbar.jsx";
import Benefits from "../components/Route/Benefits/Benefits.jsx";

const ProductPage = () => {
  return (
    <div className="dark:bg-[#490800] dark:text-white ">
      <Navbar active={3} />

      <div className="max-w-7xl mx-auto px-6">
        <Product />
        <Benefits />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
}

export default ProductPage