import React from "react";
import { Link } from "react-router-dom";

import video1 from "../../../../Assests/video/video1.mp4";
import video2 from "../../../../Assests/video/video2.mp4";

const Legal = () => {
  return (
    <div className="flex flex-col items-center mt-6 lg:mt-10">
      <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        Toma
        <span className="text-[#A71200]">
          {" "}
          Legal Database
        </span>
      </h1>
      {/* <p className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
        TOMA's Legal Database is the ultimate resource for agile legal
        professionals, institutions, and students.
      </p> */}
       <p className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
       TOMA’s comprehensive legal database organizes laws, cases, and rulings into a user-friendly, easily navigable system, allowing professionals to access critical legal information quickly and efficiently.
      </p>
      <div className="flex justify-center my-10">
        <Link
          to="/products/legal/search"
          className="bg-gradient-to-r from-orange-500 to-orange-800 py-3 px-4 mx-3 rounded-md"
        >
          Get Started
        </Link>
        <Link to="/price" className="py-3 px-4 mx-3 rounded-md border">
          Choose your plan
        </Link>
      </div>
      <div className="mx-auto md:flex md:justify-center md:gap-5">
        <video
          autoPlay
          loop
          muted
          className="rounded-lg md:w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          loop
          muted
          className="rounded-lg md:w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      
    </div>
  );
};

export default Legal;
