import React from 'react'
import Navbar from "../components/Layout/Navbar.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Footer from "../components/Layout/Footer.jsx";
import Vault from "../components/Route/Vault/Vault.jsx";


const VaultPage = () => {
  return (
   <div className='dark:bg-[#490800] dark:text-white '>
      <Navbar />
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col items-center mt-6 lg:mt-10 mb-10">
          <h1  data-aos="fade-up" className="text-4xl sm:text-6xl lg:text-6xl text-center tracking-wide">
           Toma KYC Portal
            <span className="text-[#A71200]">
              {" "}
                and ID
            </span>
          </h1>
        </div>
        <Vault />       
        <Sponsored />
      </div>

      <Footer />
    </div>
  )
}

export default VaultPage