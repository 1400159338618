import CITForm from "./FormDocuments/FIRS/CIT-FORM.pdf";
import EmployeeRemForm from "./FormDocuments/FIRS/EmployeeRemForm.xlsx"
import EmployerDeclaration from "./FormDocuments/FIRS/FORM-H1Employer-Declaration-2.xlsx"
import IndividualTaxpayer from "./FormDocuments/FIRS/Individual-Taxpayer-Registration-Form-1.pdf"
import IndividualTax from "./FormDocuments/FIRS/Individual-Tax-Clearance-Certificate-Application-Form.pdf"
import NonIndividualTax from "./FormDocuments/FIRS/Non-Individual-Tax-Clearance-Certificate-Application-Form.pdf"
import NonIndividualTaxpayer from "./FormDocuments/FIRS/Non-Individual-Taxpayer-Registration-Form-.pdf"
import PersonalIncome from "./FormDocuments/FIRS/Personal-Income-Tax-Return-Form-A.pdf"
import Vat from "./FormDocuments/FIRS/VAT-FORM-20-02-20.pdf"

import EmployerCertPayTax from "./FormDocuments/LIRS/EmployersCertPayTax.pdf"
import ETaxRenewalForm from "./FormDocuments/LIRS/ETaxRenewalForm.pdf"
import IncomeTax from "./FormDocuments/LIRS/income-tax-form-a.pdf"
import LagosIncomeTax from "./FormDocuments/LIRS/Lagos Income Tax Returns.pdf"
import LagosCapGainTaxAssess from "./FormDocuments/LIRS/LagosCapGainTaxAssess.pdf"
import ETCCForm from "./FormDocuments/LIRS/Org-eTCCForm.pdf"
import PAYE from "./FormDocuments/LIRS/PAYE-2.xlsx"
import TaxForm from "./FormDocuments/LIRS/TAX-FORM-H1.pdf"
import WHT from "./FormDocuments/LIRS/WHT-2.xlsx"

import GeneralAppAllocationStateLand from "./FormDocuments/InLand Revenue Lagos/GeneralAppAllocationStateLand.pdf"

import AppEnhancementGuaranteeCover from "./FormDocuments/CBN Forms/AppEnhancementGuaranteeCover.pdf"
import AppExtensionGuarantee from "./FormDocuments/CBN Forms/AppExtensionGuarantee.pdf"
import AppGuaranteeAgricCreditScheme from "./FormDocuments/CBN Forms/AppGuaranteeAgricCreditScheme.pdf"
import creditfacilityapplication from "./FormDocuments/CBN Forms/creditfacilityapplication.pdf"
import DailyReturns from "./FormDocuments/CBN Forms/DAILY RETURNS TEMPLATE.pdf"
import GuaraanteeClaim from "./FormDocuments/CBN Forms/GuaraanteeClaim.pdf"

import AdditionalStrengthBiowaiverNAFDAC from "./FormDocuments/NAFDAC Forms/Additional-Strength-Biowaiver-Form-NAFDAC2249226413.docx"
import AccessActivePharmaceuticalIngredient from "./FormDocuments/NAFDAC Forms/Letter-of-Access-for-Active-Pharmaceutical-Ingredient-Master-File-APIMF-26377.docx"
import AccessCertificateSuitabilityCEP from "./FormDocuments/NAFDAC Forms/Letter-of-Access-for-Certificate-of-Suitability-CEP-26376.docx"
import NAFDACQIS from "./FormDocuments/NAFDAC Forms/NAFDAC-QIS-Template26453.docx"
import NAFDACQOS from "./FormDocuments/NAFDAC Forms/NAFDAC-QOS-PD26379.docx"

import PFCert from "./FormDocuments/Pencom Forms/Application-form-for-PFC-Licence.doc"

import Summons from "./FormDocuments/court forms/FORM OF EX PARTE ORIGINATING SUMMONS.pdf"

export const regulatoryForms = {
  "FIRSForms": {
    "title": "Federal Inland Revenue Service (LIRS) Tax Forms",
    "forms": [
      {
        "name": "CIT Form",
        "description": "The CIT Form is used for filing Company Income Tax (CIT) returns. It is an annual filing that reports the total income, allowable deductions, and tax payable by corporate entities. This form helps calculate the income tax due for the fiscal year.",
        "submissionInstructions": [
          {
            "text": "Access the form",
            "document": CITForm,
          },
          "Prepare your company's financial statements and calculate your taxable income.",
          "Complete the CIT Form with details of your company’s income, expenses, and tax payable.",
          "Submit the form along with supporting documents (e.g., financial statements) and payment of taxes, if any."
        ],
        "submissionPortal": {
          "link": "https://taxpromax.firs.gov.ng",
          "address": "Federal Inland Revenue Service (FIRS), Revenue House, Plot 1719, Kaduna Street, Off Ahmadu Bello Way, Central Business District, Abuja, Nigeria."
        },
         "submissionsPortal": {
          "link": "https://taxpromax.firs.gov.ng",
          "address": "Federal Inland Revenue Service (FIRS), Revenue House, Plot 1719, Kaduna Street, Off Ahmadu Bello Way, Central Business District, Abuja, Nigeria."
        }
      },
      {
        "name": "Employee Remittance Form",
        "description": "Used by employers to remit the Pay-As-You-Earn (PAYE) tax deductions for their employees to the FIRS.",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill it.",
            "document": EmployeeRemForm,
          },
         
          "Log in to the LIRS eTax portal or the FIRS e-TaxPay platform.",
          "Complete the form with employee details and tax deductions.",
          "Upload the completed form and submit it via the portal.",
          "Make payment and obtain confirmation.",
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net",
          "address": "LIRS office, Plot 35 Muhammadu Buhari Way, Central Business District, Abuja."
        }
      },
      {
        "name": "Employer Declaration Form",
        "description": "This form is submitted by employers to declare the commencement or termination of employment for employees.",
        "submissionInstructions": [
          {
            "text":  "Access the form and fill it.",
            "document": EmployerDeclaration,
          },
          "Log in to the FIRS e-Services platform.",
          "Upload the form to the platform and submit",
        ],
        "submissionPortal": {
          "link": "https://apps.firs.gov.ng/e-Services/",
          "address": "submit at any FIRS office in your state or region"
        }
      },
      {
        "name": "Individual Tax Clearance Certificate Application Form",
        "description": "Individuals use this form to apply for a Tax Clearance Certificate, proving that they have complied with all personal tax obligations.",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill",
            "document": IndividualTax,
          },
          "Log in to the FIRS TCC Application portal",
          "Complete the form with personal income details.",
          "Submit the form and any required documentation via the portal."
        ],
        "submissionPortal": {
          "link": "https://tcc.firs.gov.ng",
          "address": "FIRS headquarters"
        }
      },
       {
        "name": "Individual Taxpayer Registration Form",
        "description": "Used by individuals to register with FIRS for tax purposes and obtain a Tax Identification Number (TIN).",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill",
            "document": IndividualTaxpayer,
          },
          "Log in to the FIRS e-Registration portal.",
          "Fill out the form with personal information.",
          "Submit the form via the portal to obtain a TIN."
        ],
        "submissionPortal": {
          "link": "https://apps.firs.gov.ng/tinregistration",
          "address": "FIRS headquarters"
        }
      },
       {
        "name": "Non-Individual Tax Clearance Certificate (TCC) Application Form",
        "description": "Used by non-individual entities (corporations, businesses) to apply for a TCC, proving compliance with corporate tax obligations",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill",
            "document": NonIndividualTax,
          },
          "Complete the form and submit it on the FIRS TCC portal.",
          "Submit supporting documents as required."
        ],
        "submissionPortal": {
          "link": "https://tcc.firs.gov.ng",
          "address": "local FIRS office."
        }
      },
       {
        "name": "Non-Individual Taxpayer Registration Form",
        "description": "This form is for non-individual entities to register with the FIRS for tax purposes and receive a TIN.",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill",
            "document": NonIndividualTaxpayer,
          },
          "Log in to the FIRS TCC Application portal",
          "Complete the form with company information.",
          "Submit via the portal for TIN generation."
        ],
        "submissionPortal": {
          "link": "https://apps.firs.gov.ng/tinregistration ",
          "address": "FIRS Office"
        }
      },
       {
        "name": "Personal Income Tax Return Form A",
        "description": "This form is used by individuals to declare their income and calculate their personal tax obligations for the year.",
        "submissionInstructions": [
           {
            "text":  "Access the form and fill",
            "document": PersonalIncome,
          },
          "Log in to the FIRS eFiling platform",
          "Fill out income details and any deductions.",
          "Submit the completed form electronically."
        ],
        "submissionPortal": {
          "link": "https://apps.firs.gov.ng/eFiling/",
          "address": "Any FIRS office across Nigeria."
        }
      },
       {
        "name": "VAT Form 002 (VAT Returns)",
        "description": "VAT Form 002 is used by registered businesses to file their monthly Value Added Tax (VAT) returns. It reports the amount of VAT collected from customers and the VAT paid on business purchases. This form is essential for remitting VAT to the Federal Inland Revenue Service (FIRS).",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": Vat,
          },
          "Fill out VAT Form 002 detailing VAT collected (output VAT) and VAT paid on purchases (input VAT).",
          "Calculate the net VAT payable (output VAT minus input VAT).",
          "Submit the completed form along with the payment of VAT (if applicable)."
        ],
        "submissionPortal": {
          "link": "https://taxpromax.firs.gov.ng",
          "address": "Federal Inland Revenue Service (FIRS) Revenue House, Plot 1719, Kaduna Street, Off Ahmadu Bello Way, Central Business District, Abuja, Nigeria."
        }
      },
      // Add other LIRS Forms
    ]
  },
    "LIRSForms": {
    "title": "Lagos Inland Revenue Service (LIRS) Tax Forms",
    "forms": [
      {
        "name": "Employer’s Certificate of Pay and Tax Form - Lagos State",
        "description": "This form is used by employers to certify the pay and tax deducted from employees within a financial year, ensuring that tax obligations are met and correctly reported.",
        "submissionInstructions": [
          {
            "text":  "Access the form",
            "document": EmployerCertPayTax,
          },
          "Complete the form with employer details, total pay, and tax deductions for all employees and sign",
          "Submit online via the LIRS e-Tax portal or physically at any LIRS tax office"
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
      {
        "name": "Employee Tax Renewal Form - Lagos State",
        "description": "Employees use this form to renew their annual tax registration, ensuring that their tax records are up-to-date and reflecting current employment details.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": ETaxRenewalForm,
          },
          "Fill in the updated employment and tax details.",
          "Submit online via the LIRS eTax portal."
        ],
         "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Employee Remittance Form - Lagos State",
        "description": "This form is used by employers to remit tax payments deducted from employees' salaries to the Lagos State tax authorities.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": ETaxRenewalForm,
          },
          "Complete the form with employee information and tax amounts to be remitted.",
          "Submit online or at the nearest LIRS office along with payment proof."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Income Tax Form - Lagos State",
        "description": "This is a standard form used by individual taxpayers to declare income and calculate taxes owed to the Lagos State Government.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": LagosIncomeTax,
          },
          "Fill in your income details, deductions, and tax calculations.",
          "Submit it online via the LIRS eTax portal or physically at any LIRS office."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Tax Clearance Certificate Application Form - Lagos State",
        "description": "This form is used by individuals or businesses to apply for a Tax Clearance Certificate (TCC), which confirms that the taxpayer has fulfilled all tax obligations.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": IndividualTax,
          },
          "Complete with taxpayer details and proof of tax payments.",
          "Submit the form online via the LIRS eTax portal or at an LIRS office."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Individual Taxpayer Registration Form - Lagos State",
        "description": "This form is for individuals registering for the first time as taxpayers in Lagos State, assigning them a taxpayer identification number (TIN).",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": IndividualTaxpayer,
          },
          "Provide your personal and financial details.",
          "Submit via the LIRS eTax portal or at a tax office in Lagos."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Income Tax Form for Return of Income and Claims for Allowances and Relief - Lagos State",
        "description": "This form is used by individuals to file annual income tax returns, including claims for allowances and reliefs.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": IncomeTax,
          },
          "Complete the form by declaring your income, allowable deductions, and claims.",
          "Submit it online via the eTax portal or physically at any LIRS office."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Capital Gains Tax Year of Assessment Form - Lagos State",
        "description": "This form is used to declare and assess capital gains tax on the disposal of chargeable assets during a financial year.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": LagosCapGainTaxAssess,
          },
          "Declare details of the asset disposal and gains realized.",
          "Submit through the LIRS eTax portal or in person at a tax office."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "Organization eTCC Form - Lagos State",
        "description": "Organizations use this form to apply for an electronic Tax Clearance Certificate, which certifies that the organization has no outstanding tax obligations.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": ETCCForm,
          },
          "Fill out the form with the organization’s tax information.",
          "Submit online through the portal."
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net/",
          "address": "Lagos State LIRS office"
        }
      },
       {
        "name": "PAYE Form (Pay-As-You-Earn Returns)",
        "description": "PAYE Form is used to file the monthly tax returns for employees under the Pay-As-You-Earn (PAYE) system. It includes details of income earned by employees, taxes deducted, and remitted to the tax authority. Employers are responsible for submitting PAYE returns for all employees.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": PAYE,
          },
          "Complete the PAYE Form with details of employee earnings and taxes deducted for the month.",
          "Remit the PAYE deductions to the appropriate State Internal Revenue Service.",
          "Submit the form along with the payment receipt.",
        ],
        "submissionPortal": {
          "link": "https://etax.lirs.net ",
          "address": "For Lagos: Lagos State Internal Revenue Service (LIRS) Good Shepherd Building, Alausa, Ikeja, Lagos, Nigeria. For other states, contact the relevant State Internal Revenue Service office"
        }
      },
       
      // Add other CBN Forms
    ]
  },
  "EIRSForms": {
    "title": "Edo Inland Revenue Service (EIRS) Tax Forms",
    "forms": [
      {
        "name": "Tax Form - Edo State",
        "description": "This is a generic tax form for individuals or organizations in Edo State used for filing taxes and remittances to the Edo State Internal Revenue Service.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": TaxForm,
          },
          "Complete with required tax details",
          "Submit at any EIRS office or through the state’s digital platform."
        ],
        "submissionPortal": {
          "link": "https://eirs.gov.ng",
          "address": "ERIS Office"
        }
      }
      // Add other NAFDAC Forms
    ]
  },
   "LandsBureauForms": {
    "title": "Lagos State Lands Bureau Forms",
    "forms": [
     {
        "name": "Application for Allocation of State Lands - Lagos State",
        "description": "This form is used by individuals or organizations applying to acquire state-owned land within Lagos State for personal or commercial purposes.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": GeneralAppAllocationStateLand,
          },
          "Provide the required personal or corporate details, project purpose, and land location.",
          "Submit the completed form at the Lagos State Lands Bureau Office or through the state’s digital services platform."
        ],
        "submissionPortal": {
          "link": "https://landsbureau.lagosstate.gov.ng",
          "address": "Lagos State Lands Bureau, Block 13, The Secretariat, Alausa, Ikeja, Lagos."
        }
      },
      {
        "name": "Land Use Charge Assessment Form",
        "description": "This form is used to assess the land use charge for properties in Lagos State. It helps determine the tax payable on land and property.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": GeneralAppAllocationStateLand,
          },
          "Provide details about the property, including location, size, and usage.",
          "Submit the form for assessment."
        ],
        "submissionPortal": {
          "link": "https://landsbureau.lagosstate.gov.ng",
          "address": "Lagos State Lands Bureau, Alausa Secretariat, Ikeja, Lagos."
        }
      },
      {
        "name": "Governor's Consent Application Form",
        "description": "The Governor's Consent Form is required when transferring ownership of land in Lagos State. It is necessary for validating the legal transfer of property rights.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": GeneralAppAllocationStateLand,
          },
          "Fill out the form with accurate property transfer details.",
          "Submit the form along with required documents such as deed of assignment and proof of ownership."
        ],
        "submissionPortal": {
          "link": "https://landsbureau.lagosstate.gov.ng",
          "address": "Lagos State Lands Bureau, Alausa Secretariat, Ikeja, Lagos."
        }
      }
      // Add other Lands Bureau Forms
    ]
  },
  "CBNForms": {
    "title": "Central Bank of Nigeria (CBN) Forms",
    "forms": [
      {
        "name": "Form M: Import Declaration Form",
        "description": "Form M is required for the importation of goods into Nigeria. It serves as a declaration to the Central Bank of Nigeria (CBN) and is used to facilitate the import process, ensuring compliance with foreign exchange regulations.",
        "submissionInstructions": [
          "Obtain the form from the CBN website or your bank.",
          "Complete the form with accurate details regarding the imported goods, including the description, value, and country of origin.",
          "Submit the completed Form M to your bank alongside necessary documents (pro forma invoice, bill of lading, etc.).",
          "The bank will validate and submit it to the CBN for approval.",
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Central Bank of Nigeria, Plot 33, Abdulkadir Ahmed Street, Central Business District, Abuja, Nigeria."
        }
      },
      {
        "name": "Form A: Application for Purchase of Foreign Exchange for Services",
        "description": "Form A is used for the payment of foreign currency for services rendered by non-residents, including consultancy, management services, and other professional services. This form ensures that all transactions comply with CBN guidelines.",
        "submissionInstructions": [
          "Download and fill out Form A from the CBN website",
          "Provide details such as the service description, amount, and recipient information.",
          "Submit the completed form to your bank along with supporting documents (contract agreements, invoices).",
          "The bank will review and submit the application to the CBN for processing."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Central Bank of Nigeria, Plot 33, Abdulkadir Ahmed Street, Central Business District, Abuja, Nigeria."
        }
      },
       {
        "name": "Form NXP: Non-Oil Export Proceeds Form",
        "description": "Form NXP is a declaration form used by exporters for non-oil products. It facilitates the repatriation of export proceeds and compliance with foreign exchange regulations. This form is essential for ensuring that funds are properly accounted for and reported.",
        "submissionInstructions": [
          "Obtain Form NXP from the CBN website or your bank.",
          "Complete the form with necessary details about the exported goods, including the value, description, and destination.",
          "Submit the form to your bank, accompanied by relevant export documents (invoice, bill of lading, etc.).",
          "The bank will process the form and send it to the CBN for approval."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Central Bank of Nigeria, Plot 33, Abdulkadir Ahmed Street, Central Business District, Abuja, Nigeria."
        }
      },
       {
        "name": "Application for Enhancement of Guarantee Cover",
        "description": "This form is used by financial institutions to apply for an increase in the guarantee coverage provided under the Agricultural Credit Guarantee Scheme Fund (ACGSF). The enhancement increases the amount of loan coverage by the scheme.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": AppEnhancementGuaranteeCover,
          },
          "Submit the completed form through the CBN online portal or via the relevant financial institution acting as the intermediary."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Agricultural Credit Guarantee Scheme Fund, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },
       {
        "name": "Application for Extension of Guarantee",
        "description": "This form allows for the extension of an existing guarantee provided under the ACGSF. It is typically used when loan terms are modified and an extension of the guarantee period is required.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": AppExtensionGuarantee,
          },
          "Submit the completed form through the CBN online portal or via the relevant financial institution acting as the intermediary."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
           "address": "Agricultural Credit Guarantee Scheme Fund, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },
       {
        "name": "Application for Guarantee of the Agricultural Credit Guarantee Scheme",
        "description": "This form is used by farmers and agribusinesses applying for a loan guarantee under the Agricultural Credit Guarantee Scheme to secure credit from financial institutions for agricultural projects.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": AppGuaranteeAgricCreditScheme,
          },
          "Submit the completed form through the CBN online portal or via the relevant financial institution acting as the intermediary."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
           "address": "Agricultural Credit Guarantee Scheme Fund, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },
       {
        "name": "Daily Returns Template",
        "description": "This template is used by financial institutions to report their daily transactions and balances to the CBN as part of regulatory compliance. This includes data related to the guarantees issued under the Agricultural Credit Guarantee Scheme.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": DailyReturns,
          },
          "Provide details such as the service description, amount, and recipient information.",
          "Submit it via the CBN's secure online portal or by email to the CBN's designated department for daily returns."
        ],
        "submissionPortal": {
          "link": "dailyreturns@cbn.gov.ng",
          "address": "Banking Supervision Department, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },
       {
        "name": "Guarantee Claims",
        "description": "This form is submitted by financial institutions to claim the guarantee from the Agricultural Credit Guarantee Scheme Fund (ACGSF) in case of default by the borrower. It ensures that the institution recovers part of the loan amount from the scheme.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": GuaraanteeClaim,
          },
          "Completed forms are submitted by the lending institution to the CBN through the ACGSF platform or via the physical submission at CBN branches."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Agricultural Credit Guarantee Scheme Fund, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },

       {
        "name": "Application for Credit Facilities",
        "description": "This form is used by individuals, businesses, or farmers seeking credit facilities under any of the CBN's financial schemes, including the Agricultural Credit Guarantee Scheme Fund. It is submitted to apply for loans guaranteed by the CBN.",
        "submissionInstructions": [
            {
            "text":  "Access the form",
            "document": creditfacilityapplication,
          },
          "Submit the form through a participating financial institution, which will handle processing with the CBN."
        ],
        "submissionPortal": {
          "link": "https://www.cbn.gov.ng",
          "address": "Credit Guarantee Department, Central Bank of Nigeria, 33 Tafawa Balewa Way, Central Business District, Abuja."
        }
      },
      // Add other CBN Forms
    ]
  },
  "NAFDACForms": {
    "title": "National Agency for Food and Drug Administration and Control (NAFDAC) Forms",
    "forms": [
      {
        "name": "NAFDAC Product Registration Form",
        "description": "This form is for companies or individuals looking to register regulated products such as food, drugs, cosmetics, medical devices, and chemicals with the National Agency for Food and Drug Administration and Control (NAFDAC). Registering a product ensures it meets NAFDAC standards for safety, efficacy, and quality before it can be sold or distributed in Nigeria.",
        "submissionInstructions": [
          "Visit the NAFDAC e-Registration Portal: You can access the product registration portal via NAFDAC e-Registration Portal.",
          "Create an Account: New users must create an account by filling in basic information about their company and product category",
          "Complete the Form: Provide detailed information about the product, including the composition, packaging, labeling, and safety data.",
          "Complete the Form: Provide detailed information about the product, including the composition, packaging, labeling, and safety data.",
          "Submit Required Documents: Attach supporting documents such as a Certificate of Incorporation, product labeling, evidence of payment, and samples (if applicable).",
          "Pay the Applicable Fees:Make payment for registration through the designated bank or the e-portal payment system.",
          "Submit Form: Review and submit the form online. Once the application is approved, NAFDAC will issue a product registration number.",
        ],
        "submissionPortal": {
          "link": "https://registration.nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
       {
        "name": "NAFDAC Permit Application Process Form",
        "description": "The NAFDAC Permit Application Form is used to apply for approval to import or export regulated goods such as food, drugs, cosmetics, medical devices, and chemicals. This permit is essential to ensure that the goods comply with Nigerian regulatory standards and are safe for public use.",
        "submissionInstructions": [
          "Visit the NAFDAC Permit Portal: Access the permit application portal via NAFDAC Permit Portal.",
          "Create an Account or Log In: If you are a first-time user, register an account on the portal; returning users can log in with existing credentials.",
          "Complete the Form: Provide details on the goods being imported or exported, including product description, quantity, and the purpose of import/export.",
          "Submit Supporting Documents: Documents like the invoice, bill of lading, proforma invoice, and Certificate of Analysis must be attached as part of the submission.",
          "Make Payment: Pay the required permit application fees through the provided payment gateway or bank.",
          "Submit Form: Submit the completed form along with the supporting documents. The application will be reviewed by NAFDAC, and a permit will be issued if the application meets the required criteria.",
        ],
        "submissionPortal": {
          "link": "NAFDAC Permit Portal",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      {
        "name": "Application for a Biowaiver: Additional Strength Form (NAFDAC)",
        "description": "This form is used to apply for a Biowaiver for an additional strength of a pharmaceutical product. A biowaiver allows a waiver from conducting bioequivalence studies for a product that has a different strength than the one already approved, provided the pharmaceutical product is similar in other respects (e.g., formulation, route of administration).",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": AdditionalStrengthBiowaiverNAFDAC,
          },
          "Submit the completed form, along with required documentation (e.g., previously approved strength data, product composition) through the NAFDAC e-portal.",
          "Pay the applicable fees through the portal and provide the payment receipt as part of the application package"
        ],
        "submissionPortal": {
          "link": "https://nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      {
        "name": "Letter of Access for APIMF (Active Pharmaceutical Ingredient Master File)",
        "description": "This Letter of Access allows a marketing authorization applicant to reference the Active Pharmaceutical Ingredient Master File (APIMF) of a third-party API manufacturer. This provides NAFDAC with confidential information regarding the active ingredient’s quality without disclosing proprietary details to the applicant",
        "submissionInstructions": [
           {
            "text":  "Access the letter",
            "document": AccessActivePharmaceuticalIngredient,
          },
          "The API manufacturer must provide the Letter of Access to the marketing authorization holder.",
          "Submit the Letter of Access along with the product dossier through the NAFDAC online submission portal, ensuring all necessary sections of the API documentation are included.",
          "Pay the processing fee through the portal and upload the receipt."
        ],
        "submissionPortal": {
          "link": "https://nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      {
        "name": "Letter of Access for CEP (Certificate of Suitability)",
        "description": "This Letter of Access enables the holder of a Certificate of Suitability (CEP) to allow a third-party manufacturer to reference the quality of an active pharmaceutical ingredient in their application for a marketing authorization. The CEP demonstrates that the active ingredient conforms to European Pharmacopoeia standards.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": AccessCertificateSuitabilityCEP,
          },
          "Submit the Letter of Access as part of the product dossier through the NAFDAC online submission portal.",
          "Pay the relevant fees via the portal and attach the payment receipt to the application."
        ],
        "submissionPortal": {
          "link": "https://nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      {
        "name": "Quality Information Summary (QIS) Template",
        "description": "The Quality Information Summary (QIS) is a template designed to summarize critical quality information about a pharmaceutical product. It includes data such as manufacturing processes, quality control, and compliance with relevant standards.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": NAFDACQIS,
          },
          "Complete the form with all relevant quality information about the product.",
          "Submit the completed template along with the full product dossier through the NAFDAC e-portal.",
          "Ensure that all sections of the form are completed accurately before submission."
        ],
        "submissionPortal": {
          "link": "https://nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      {
        "name": "Quality Overall Summary: Product Dossier (QOS-PD) Template",
        "description": "The Quality Overall Summary (QOS-PD) is a high-level summary of the information provided in the Product Dossier. It highlights the quality aspects of the pharmaceutical product, including manufacturing, stability, and testing protocols.",
        "submissionInstructions": [
           {
            "text":  "Step 1: Download the QOS-PD Template from the NAFDAC website.",
            "document": NAFDACQOS,
          },
          "Step 2: Fill out the template, ensuring all relevant sections (product composition, testing, stability) are addressed comprehensively.",
          "Step 3: Upload the completed QOS-PD as part of the product dossier via the NAFDAC submission portal.",
          "Step 4: Attach any required supporting documents and ensure the product dossier meets NAFDAC’s submission standards.",
        ],
        "submissionPortal": {
          "link": "https://nafdac.gov.ng/",
          "address": "National Agency for Food and Drug Administration and Control Plot 2032, Olusegun Obasanjo Way, Wuse Zone 7, Abuja, Nigeria."
        }
      },
      // Add other NAFDAC Forms
    ]
  },
  "PENCOMForms": {
    "title": "PENCOM Forms",
    "forms": [
      {
        "name": "Pension Clearance Certificate Application Form",
        "description": "The Pension Fund Custodian Licence Application Form is required for organizations applying for a Pension Fund Custodian (PFC) license. A PFC holds pension fund assets in trust, ensuring that all contributions are managed in accordance with the provisions of the Pension Reform Act.",
        "submissionInstructions": [
           {
            "text":  "Access the form",
            "document": PFCert,
          },
          "Submit form along with the required documents (Application Letter, Certificate of Incorporation, Shareholding Structure, Tax Clearance Certificate, Paid-up Capital Evidence, Financial Statements, Org Structure etc)  either online or at a designated PENCOM office."
        ],
        "submissionPortal": {
          "link": "https://www.pencom.gov.ng",
          "address": "PENCOM Office, Plot 174, Adetokunbo Ademola Crescent, Wuse II, Abuja."
        }
      },
      // Add other PENCOM Forms
    ]
  },
  "CourtForms": {
    "title": "Court Forms",
    "forms": [
      {
        "name": "Writ of Summons",
        "description": "A Writ of Summons is used to formally notify a defendant that a lawsuit has been filed against them and requires them to respond in court.",
        "submissionInstructions": [
          "Access the form from the appropriate court or online court portal.",
          "Fill out the form with the necessary details, including the names of the plaintiff and defendant, and the nature of the case.",
          "Submit the form to the court registry for filing."
        ],
        "submissionPortal": {
          "link": "https://www.fcthighcourt.gov.ng",
          "address": "High Court of the Federal Capital Territory, Abuja, Nigeria."
        }
      }
      // Add other Court Forms
    ]
  }
}
