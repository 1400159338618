import React from 'react'

const Product = () => {
  return (
    <div className="mt-10">
      <h2 data-aos="zoom-in" className="text-3xl sm:text-5xl lg:text-6xl text-center mt-6 tracking-wide">
        Simple to understand,{" "}
        <span className="text-[#A71200]">
          Easy to navigate...
        </span>
      </h2>
      <p data-aos="fade-up" className="mt-10 text-lg text-center text-neutral-500">
        The TOMA platform is designed with you in mind, ensuring that each{" "}
        <br />
        product is intuitive, powerful, and tailored to your unique needs.{" "}
      </p>
    </div>
  );
}

export default Product

