
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer.js";
import { geoipReducer } from "./reducers/geoipReducer.js";
import { currencyReducer } from './reducers/currencyReducer'

const store = configureStore({
  reducer: {
    auth: authReducer, 
    geoip: geoipReducer,  
    currency: currencyReducer,
  },

});


export default store;


