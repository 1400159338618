
import Navbar from "../components/Layout/Navbar";
import Sponsored from "../components/Route/Sponsored";
import Testimonials from "../components/Route/Testimoney/Testimonials";
import Footer from "../components/Layout/Footer";
import { Tabs, Tab } from "../components/common/Tabs/Tabs.jsx";
import React, { useState, useRef  } from 'react';
import QuestionForm from '../components/Products/CustomTemplate/Contracts/QuestionForm';
import { agreementsData } from '../components/Products/CustomTemplate/Agreements/agreementsData';
import AgreementThumbnails from '../components/Products/CustomTemplate/Agreements/AgreementThumbnails ';
import { FaLessThan } from "react-icons/fa";
import { Editor, EditorState, ContentState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  generateBusinessConsultantAgreement,
  generateNDAAgreement,
  generateTenancyAgreement,
  generateMOUAgreement,
  generatePreIncorporationAgreement,
  generateEmploymentAgreement,
  generateMediumTermLoanFacilityAgreement,
  generateDemandGuarantorPayment,
  generateIndemnityAgreement,
  generateLetterOfCharge,
  generateLetterOfCredit,
  generateLetterOfDemand,
  generateLoanAgreement,
  generateMultimediaPublicityPrivacyReleaseAgreement,
  generateSoftwareAppPrivacyPolicy,
  generateTransferOfABillOfSale,
  generateTenantsNoticeOfIntentToMoveOut,
  generateNoticeOfRevocation,
  generateLetterOfIntent,
  generateNoticeOfDefault,
} from '../components/Products/CustomTemplate/Contracts/agreementTemplates';
import { regulatoryForms } from "../components/Products/CustomTemplate/Forms/regulatoryForms.js"
import html2pdf from 'html2pdf.js';
import { v4 as uuidv4 } from 'uuid';

const TemplatePage = () => {
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);

   const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
    const [clientEmail, setClientEmail] = useState('');
      const [savedContractContent, setSavedContractContent] = useState(null);
        const pdfContentRef = useRef(null);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedForm(null); // Reset form when a new category is selected
  };

  const handleFormSelect = (form) => {
    setSelectedForm(form);
  };

  const handleSelectAgreement = (agreement) => {
    setSelectedAgreement(agreement);
  };

  const handleGenerateContract = (formData) => {
    let contractTemplate = '';

    if (selectedAgreement === 'BUSINESS CONSULTANT AGREEMENT') {
      contractTemplate = generateBusinessConsultantAgreement(formData);
    } else if (selectedAgreement === 'NON-DISCLOSURE AGREEMENT') {
      contractTemplate = generateNDAAgreement(formData);
    } else if (selectedAgreement === 'TENANCY AGREEMENT') {
      contractTemplate = generateTenancyAgreement(formData);
    } else if (selectedAgreement === 'MEMORANDUM OF UNDERSTANDING') {
      contractTemplate = generateMOUAgreement(formData);
    } else if (selectedAgreement === 'PRE-INCORPORATION AGREEMENT') {
      contractTemplate = generatePreIncorporationAgreement(formData);
    } else if (selectedAgreement === 'EMPLOYMENT AGREEMENT') {
      contractTemplate = generateEmploymentAgreement(formData);
    } else if (selectedAgreement === 'AGREEMENT GRANTING MEDIUM TERM LOAN FACILITY') {
      contractTemplate = generateMediumTermLoanFacilityAgreement(formData);
    } else if (selectedAgreement === 'DEMAND TO GUARANTOR FOR PAYMENT') {
      contractTemplate = generateDemandGuarantorPayment(formData);
    } else if (selectedAgreement === 'INDEMNITY AGREEMENT') {
      contractTemplate = generateIndemnityAgreement(formData);
    } else if (selectedAgreement === 'LETTER OF CHARGE') {
      contractTemplate = generateLetterOfCharge(formData);
    } else if (selectedAgreement === 'LETTER OF CREDIT') {
      contractTemplate = generateLetterOfCredit(formData);
    } else if (selectedAgreement === 'LETTER OF DEMAND') {
      contractTemplate = generateLetterOfDemand(formData);
    } else if (selectedAgreement === 'LOAN AGREEMENT') {
      contractTemplate = generateLoanAgreement(formData);
    } else if (selectedAgreement === 'MULTIMEDIA PUBLICITY/PRIVACY RELEASE') {
      contractTemplate = generateMultimediaPublicityPrivacyReleaseAgreement(formData);
    } else if (selectedAgreement === 'TRANSFER OF A BILL OF SALE') {
      contractTemplate = generateTransferOfABillOfSale(formData);
    } else if (selectedAgreement === 'Tenant\'s Notice of Intent to Move Out') {
      contractTemplate = generateTenantsNoticeOfIntentToMoveOut(formData);
    } else if (selectedAgreement === 'NOTICE OF REVOCATION') {
      contractTemplate = generateNoticeOfRevocation(formData);
    } else if (selectedAgreement === 'LETTER OF INTENT') {
      contractTemplate = generateLetterOfIntent(formData);
    } else if (selectedAgreement === 'NOTICE OF DEFAULT') {
      contractTemplate = generateNoticeOfDefault(formData);
    } 

    const contentState = ContentState.createFromText(contractTemplate);
    setEditorState(EditorState.createWithContent(contentState)); // Populate the editor with the generated contract
    setShowEditor(true); // Show the editor with the contract
  };

const getAgreementQuestions = (agreement) => {
    if (agreement === 'BUSINESS CONSULTANT AGREEMENT') {
        return agreementsData.BusinessConsultantAgreement;
    } else if (agreement === 'TENANCY AGREEMENT') {
        return agreementsData.TenancyAgreement;
    } else if (agreement === 'NON-DISCLOSURE AGREEMENT') {
        return agreementsData.NonDisclosureAgreement;
    } else if (agreement === 'MEMORANDUM OF UNDERSTANDING') {
        return agreementsData.MemorandumOfUnderstanding;
    } else if (agreement === 'SERVICE LEVEL AGREEMENT') {
        return agreementsData.ServiceLevelAgreement;
    } else if (agreement === 'PRE-INCORPORATION AGREEMENT') {
        return agreementsData.PreIncorporationAgreement;
    } else if (agreement === 'EMPLOYMENT AGREEMENT') {
        return agreementsData.EmploymentAgreement;
    } else if (agreement === 'AGREEMENT GRANTING MEDIUM TERM LOAN FACILITY') {
        return agreementsData.MediumTermLoanFacilityAgreement;
    } else if (agreement === 'INDEMNITY AGREEMENT') {
        return agreementsData.IndemnityAgreement;
    } else if (agreement === 'LETTER OF CHARGE') {
        return agreementsData.LetterOfCharge;
    } else if (agreement === 'LETTER OF CREDIT') {
        return agreementsData.LetterOfCredit;
    } else if (agreement === 'LETTER OF DEMAND') {
        return agreementsData.LetterOfDemand;
    } else if (agreement === 'LOAN AGREEMENT') {
        return agreementsData.LoanAgreement2;
    } else if (agreement === 'MULTIMEDIA PUBLICITY/PRIVACY RELEASE') {
        return agreementsData.MultimediaPublicityPrivacyReleaseAgreement;
    } else if (agreement === 'TRANSFER OF A BILL OF SALE') {
        return agreementsData.TransferOfBillOfSale;
    } else if (agreement === 'Tenant\'s Notice of Intent to Move Out') {
        return agreementsData.TenantsNoticeOfIntentToMoveOut;
    } else if (agreement === 'LETTER OF INTENT') {
        return agreementsData.LetterOfIntent;
    } else if (agreement === 'NOTICE OF DEFAULT') {
        return agreementsData.NoticeOfDefault;
    } 
    // Add more if statements for other agreements
    else {
        return null;
    }
};


  const sendInvitationEmail = async () => {
    // Replace with your email sending API endpoint or service
    const emailBody = convertToRaw(editorState.getCurrentContent()).blocks.map(block => block.text).join("\n");

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: clientEmail,
          subject: 'Invitation to Sign Contract',
          body: `Please review and sign the contract at the following link:\n\n${emailBody}`,
        }),
      });
      if (response.ok) {
        alert("Invitation sent successfully!");
      } else {
        alert("Failed to send the invitation. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };



  const handleSaveContract = () => {
    const contractContent = convertToRaw(editorState.getCurrentContent()).blocks.map(block => block.text).join("\n");

    const contractData = {
      id: uuidv4(),
      title: selectedAgreement || "Generated Contract",
      content: contractContent,
      date: new Date().toLocaleDateString()
    };

    // Save to local storage
    const existingContracts = JSON.parse(localStorage.getItem("contracts") || "[]");
    localStorage.setItem("contracts", JSON.stringify([...existingContracts, contractData]));

    setSavedContractContent(contractContent); // Save content to state
    alert("Contract saved successfully!");
  };

    const handleDownloadPdf = () => {
    if (!savedContractContent) {
      alert("Please save the contract before downloading.");
      return;
    }

    // Create an inline HTML content for PDF generation
    const contentHtml = `
      <div style="font-family: Arial, sans-serif; padding: 20px;">
        <h1 style="text-align: center;">${selectedAgreement || "Generated Contract"}</h1>
        <p>${savedContractContent.replace(/\n/g, "<br />")}</p>
      </div>
    `;

    const options = {
      margin: 1,
      filename: `${selectedAgreement || "Generated_Contract"}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, scrollY: 0 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(options).from(contentHtml).toPdf().save();
  };
  // Function to go back to agreement selection
  const handleBackToAgreementSelection = () => {
    setSelectedAgreement(null);
    setShowEditor(false);
  };

  return (
    <div className="dark:bg-[#490800] dark:text-white text-[#000]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col items-center mt-6 lg:mt-10 mb-10">
          <h1 className="text-4xl sm:text-6xl lg:text-6xl text-center tracking-wide">
            Customizable
            <span className="text-[#A71200]"> Templates</span>
          </h1>
        </div>
        <div>
          <Tabs data-aos="fade-up">
            <Tab label="Contracts and Agreements">
              <div className="py-4">
                <div className="p-6">
                  {!selectedAgreement ? (
                    <AgreementThumbnails onSelectAgreement={handleSelectAgreement} />
                  ) : (
                    <div>
                      {!showEditor ? (
                        <div className="">
                        {/* Back to Agreement Selection Button */}
                          <div className="mb-6">
                            <button
                              onClick={handleBackToAgreementSelection}
                              className="rounded-md transition duration-300 bg-gray-300 text-black p-2 rounded-md dark:bg-[#490800] dark:hover:bg-[#780D00] dark:border-2 flex items-center gap-2 font-bold"
                            >
                             <FaLessThan /> Back to Categories
                            </button>
                          </div>
                          <QuestionForm
                            questions={getAgreementQuestions(selectedAgreement)}
                            agreementName={selectedAgreement}
                            onGenerateContract={handleGenerateContract}
                          />
                          
                        </div>
                      ) : (
                        <div className="generated-contract">
                         {/* Back Button in the Editor */}
                          <div className="mb-6">
                            <button
                              onClick={handleBackToAgreementSelection}
                              className="rounded-md transition duration-300 bg-gray-300 text-black p-2 rounded-md dark:bg-[#490800] dark:hover:bg-[#780D00] dark:border-2 flex items-center gap-2 font-bold"
                            >
                             <FaLessThan /> Back to Categories
                            </button>
                          </div>
                          <h1 className="text-xl mb-4 font-bold text-center">GENERATED {selectedAgreement}</h1>
                          <div className="editable-document border border-gray-300 rounded p-4">
                            <Editor
                              editorState={editorState}
                              onChange={setEditorState}
                              placeholder="Edit your contract here..."
                            />
                          </div>
                          <div className="mt-10">
                                {/* Email input and invitation send button */}
                          <input
                            type="email"
                            value={clientEmail}
                            onChange={(e) => setClientEmail(e.target.value)}
                            placeholder="Enter client's email address"
                            className="border-2 p-2 mb-4 rounded w-full text-center"
                          />
                          <button
                            onClick={async () => {
                              const emailContent = convertToRaw(editorState.getCurrentContent()).blocks
                                .map(block => block.text)
                                .join("\n");
                              try {
                                const response = await fetch('/api/send-email', {
                                  method: 'POST',
                                  headers: { 'Content-Type': 'application/json' },
                                  body: JSON.stringify({
                                    to: clientEmail,
                                    subject: 'Please Sign Your Contract',
                                    body: emailContent
                                  })
                                });
                                alert(response.ok ? 'Invitation sent successfully' : 'Failed to send email');
                              } catch (error) {
                                console.error('Email send error:', error);
                              }
                            }}
                            className="bg-red-500 text-white px-4 py-2 rounded flex  items-center justify-center mx-auto"
                          >
                            Send Invitation to Sign
                          </button>
                          </div>
                        

                          <div className="flex items-center justify-between space-x-4 mt-4">

                          {/* Save Contract Button */}
                          <button onClick={handleSaveContract} className="bg-green-500 text-white px-4 py-2 rounded">
                            Save Contract
                          </button>

                          {/* Download as PDF Button */}
                          <button onClick={handleDownloadPdf} className="bg-blue-500 text-white px-4 py-2 rounded">
                            Download as PDF
                          </button>
                        </div>    
                                                          
                        
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Tab>
             <Tab label="Regulatory and Compliance Forms">
              <div className="py-4">
                <div className="p-6">
                {/* Category Selection */}
                  {!selectedCategory ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {Object.keys(regulatoryForms).map((categoryKey) => (
                        <div
                          key={categoryKey}
                          className=" p-6 shadow-lg rounded-lg cursor-pointer hover:bg-gray-200 dark:bg-[#780D00] dark:hover:bg-opacity-50 transition"
                          onClick={() => handleCategorySelect(categoryKey)}
                        >
                          <h3 className="text-xl font-bold">{regulatoryForms[categoryKey].title}</h3>
                        </div>
                      ))}
                    </div>
                  ) : !selectedForm ? (
                    // Form List
                    <div>
                      <button
                        className="mb-4 bg-gray-300 text-black p-2 rounded-md dark:bg-[#490800] dark:hover:bg-[#780D00] dark:border-2 flex items-center transition duration-300 gap-2 font-bold"
                        onClick={() => setSelectedCategory(null)}
                      >
                      <FaLessThan />  Back to Categories
                      </button>
                      <h2 className="text-2xl font-bold mb-4">{regulatoryForms[selectedCategory].title}</h2>
                      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 list-disc">
                        {regulatoryForms[selectedCategory].forms.map((form) => (
                          <li
                            key={form.name}
                            className="cursor-pointer hover:underline mb-2"
                            onClick={() => handleFormSelect(form)}
                          >
                            {form.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    // Form Description
                    <div>
                      <button
                        className="mb-4 bg-gray-300 text-black p-2 rounded-md dark:bg-[#490800] dark:hover:bg-[#780D00]  flex items-center gap-2 dark:border-white dark:border-2 transition duration-300 font-bold"
                        onClick={() => setSelectedForm(null)}
                      >
                      <FaLessThan />  Back to Forms
                      </button>
                      <h2 className="text-2xl font-bold mb-4">{selectedForm.name}</h2>
                      <p className="mb-4">{selectedForm.description}</p>
                      <h3 className="text-xl font-semibold mb-2">Submission Instructions:</h3>
                      <ul className="list-disc ml-6 mb-4">
                        {selectedForm.submissionInstructions.map((instruction, index) => (
                          <li key={index}>
                            {typeof instruction === 'object' ? (
                              <a
                                href={instruction.document}
                                download
                                className="text-blue-500 underline"
                              >
                                {instruction.text}
                              </a>
                            ) : (
                              instruction
                            )}
                          </li>
                        ))}
                      </ul>
                      {selectedForm.submissionPortal && (
                        <div className="mb-4">
                          <h4 className="text-lg font-semibold">Submission Portal:</h4>
                          <a
                            href={selectedForm.submissionPortal.link}
                            className="text-blue-500 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {selectedForm.submissionPortal.link}
                          </a>
                          <p>{selectedForm.submissionPortal.address}</p>
                        </div>
                      )}
                    </div>
                  )}
                
     
          </div>                      
               
         </div>
        </Tab>   
          </Tabs>
        </div>

        <Testimonials />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
};

export default TemplatePage;


