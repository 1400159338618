import React from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links.jsx";
import routes from "./routes.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../../../Assests/Svg/logo.svg";

const Sidebar = ({open, onClose}) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-[#780D00] dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
<Link to="/"><div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[20px] font-bold uppercase  text-red-800 dark:text-white flex items-center gap-2">
      
              <img src={logo} alt="Logo" className="w-[50px]" />
              <p>Toma Legal</p></div>
            
      </div></Link>
      
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        {/* <SidebarCard /> */}
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
