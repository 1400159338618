import React from "react";
import ai from "../../../Assests/Image/ai.png";
import { CheckCircle2 } from "lucide-react";
import { ApproachItem } from "../../../static/data";

const Approach = () => {
  return (
    <div className="mt-10">
      <h2 data-aos="fade-up" className="mb-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center">
        Our Approach{" "}
        <span className="text-[#A71200]">
          
        </span>
      </h2>
      <p data-aos="fade-up"
              className="text-sm text-gray-500 tracking-wide leading-5 text-center mb-4">
        At the heart of TOMA lies a customer-centric approach. We understand
        that every business is unique, that's why we tailor our solutions to
        meet the specific needs of each user, providing personalized support and
        guidance every step of the way.
      </p>
      <div className="flex flex-wrap items-center justify-center">
        <div className="p-2 w-full mx-auto mt-8 lg:mt-0  lg:w-1/2">
        <div data-aos="zoom-in"><img src={ai} alt="legal" className=" rounded-2xl" /></div>
          
        </div>
        <div className="pt-12 w-full lg:w-1/2">
          {ApproachItem.map((item, index) => (
            <div key={index} className="flex mb-12">
              <div className="bg-green-400 mx-6 text-white h-10 w-10 p-2 justify-center items-center rounded-full">
                <CheckCircle2 />
              </div>
              <div>
                <h5 data-aos="fade-up" className="mt-1 mb-2 text-xl">{item.title}</h5>
                <p data-aos="fade-up" className="text-md text-neutral-500">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Approach;

// <div className={`${styles.section}`}>
    //   <div lassName={`${styles.heading}`}>
    //     <div className="md:flex md:items-start md:justify-around mt-20 mb-20">
    //       <div className="md:w-[40%] mb-10 md:mb-0 ">
    //         <h1 className="lg:text-5xl text-3xl md:mb-0 lg:leading-normal font-semibold text-black">
    //           Our Approach
    //         </h1>
    //         <p className="mt-5">
    //           At the heart of TOMA lies a customer-centric approach. We
    //           understand that every business is unique, that's why we tailor our
    //           solutions to meet the specific needs of each user, providing
    //           personalized support and guidance every step of the way.
    //         </p>
    //         <button className="mt-10 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
    //           Learn More
    //         </button>
    //       </div>
    //       <div className="md:w-[40%]">
    //         <p className="mb-10 ">
    //           Comprehensive Solutions: From legal document management to
    //           compliance monitoring{" "}
    //         </p>
    //         <p className="mb-10 ">
    //           Ease of Use: Our intuitive interface makes it easy for users of
    //           all levels to navigate the platform with confidence{" "}
    //         </p>
    //         <p className="mb-10 ">
    //           Expert Support: Access a team of legal professionals and
    //           compliance experts ready to assist you whenever you need
    //         </p>
    //         <p className="mb-10 ">
    //           Security and Compliance: With TOMA Vault, your data is protected
    //           with the highest levels of security and confidentiality.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
