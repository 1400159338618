// agreementsData.js (contains all the agreements JSON data)
export const agreementsData = {
   "BusinessConsultantAgreement":  [
    {
      "section": "Basic Information",
      "questions": [
        { "id": "todaysDate", "label": "What is the date of this agreement?", "type": "date" },
        { "id": "companyName", "label": "What is the name of the Company?", "type":"text" },
        { "id": "companyAddress", "label": "What is the Company's address and contact information?" },
        { "id": "consultantName", "label": "What is the name of the Consultant?" },
        { "id": "consultantAddress", "label": "What is the Consultant’s address and contact information?" }
      ]
    },
    {
      "section": "Consultation Services",
      "questions": [
        { "id": "servicesProvided", "label": "Explain in detail the specific services will the Consultant provide (e.g., management advice, financial policy, employee terms)?" },
        { "id": "interaction", "label": "Explain in detail how the Consultant will interact with the Company (e.g., meetings, written reports)?" }
      ]
    },
    {
      "section": "Terms of Agreement",
      "questions": [
        { "id": "startDate", "label": "What is the start date of the agreement?", "type": "date" },
        { "id": "endDate", "label": "What is the end date of the agreement?", "type": "date" },
        { "id": "cancellationNoticePeriod", "label": "There is an agreement cancellation notice period of how many days on the part of the Company?", "type": "text" },
        { "id": "timeDedication", "label": "How much time is the Consultant expected to dedicate ? (eg 3 months)", "type": "text" },
        { "id": "workHours", "label": "What is the minimium time the Consultant is required to work per month?", "type": "text" }
      ]
    },
    {
      "section": "Location of Services",
      "questions": [
        { "id": "serviceLocation", "label": "Where will the Consultant provide the services?", "type": "select", "options": ["Company office", "Remote"] }
      ]
    },
    {
      "section": "Payment Details",
      "questions": [
        { "id": "paymentRate", "label": "What is the Consultant’s rate of payment in dollars monthly or per project? (eg $200 per month)" , "type": "text" },
        { "id": "minimumMonthlyPayment", "label": "Is there a minimum monthly payment? If yes, how much?", "type": "text" },
        { "id": "invoiceFrequency", "label": "How frequently will the Consultant submit itemized invoices? (eg. 10 days)", "type": "text" }
      ]
    },
    {
      "section": "Confidentiality",
      "questions": [
        { "id": "confidentialityRequired", "label": "Will the Consultant be required to keep Company information confidential?", "type": "yesno" },
        { "id": "penaltiesForBreach", "label": "Should there be specific penalties for breaching confidentiality?", "type": "yesno" }
      ]
    },
    {
      "section": "Termination Clauses",
      "questions": [
        { "id": "terminationNotice", "label": "How much notice is required to terminate the agreement?", "type": "text" },
        { "id": "earlyTermination", "label": "Should either party have the ability to terminate early without penalty?", "type": "yesno" }
      ]
    },
    {
      "section": "Employment of Others",
      "questions": [
        { "id": "thirdPartyHiring", "label": "Will the Consultant be allowed to hire third parties to complete tasks? If so, under what conditions?", "type": "text" }
      ]
    }
  ],
  "TenancyAgreement": [
    {
      "section": "Basic Information",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement?", "type": "date" },
        { "id": "landlordPertains", "label": "Select the title this agreement pertains to…", "type": "select", "options": ["Landlord", "Landlady", "Leasing Company"]  },
         { "id": "landlordName", "label": "What is the name of the Landlord/Landlady or Leasing Company  address and contact information?" },
        { "id": "landlordAddress", "label": "What is the Landlord/Landlady or Leasing Company  address and contact information?" },
        { "id": "tenantName", "label": "What is the name of the Tenant?" },
        { "id": "tenantAddress", "label": "What is the Tenant’s address and contact information?" }
      ]
    },
    {
      "section": "Property Details",
      "questions": [
        { "id": "propertyType", "label": "What type of property is being rented (Flat/House/Building)?" },
        { "id": "propertyDescription", "label": "Is there any specific description of the premises that needs to be included?" },
         { "id": "propertyAddress", "label": "What is the location of the property?" }
      ]
    },
    {
      "section": "Lease Terms",
      "questions": [
        { "id": "leaseDuration", "label": "What is the duration of the lease (e.g., 1 year)?" },
        { "id": "leaseStartDate", "label": "What is the start date of the lease?" },
        { "id": "rentAmount", "label": "What is the yearly or monthly rent amount (in figures and words)?" },
        { "id": "rentPaymentMethod", "label": "How will the rent be paid (yearly or monthly)?" },
         { "id": "amountInWords", "label": "Write the rent amount words", "type": "text",},
        { "id": "additionalClause", "label": "Include an additional clause, if applicable" }
      ]
    },
    {
      "section": "Tenant's Obligations",
      "questions": [
        { "id": "utilitiesPayment", "label": "Will the tenant pay for electricity and other outgoings? If so, provide details." },
        { "id": "structuralChangesRestrictions", "label": "Are there any restrictions on making structural changes? If yes, what are the conditions?" },
        { "id": "maintenanceRules", "label": "Are there rules for maintaining the interior and fixtures?" }
      ]
    },
    {
      "section": "Additional Clauses",
      "questions": [
        { "id": "prohibitedItems", "label": "Are there any specific behaviors or items that should be prohibited (e.g., chemicals, inflammable products)?" },
        { "id": "additionalClauses", "label": "Do you want to include any additional clauses not listed in the template?" }
      ]
    },
    {
      "section": "Landlord's Obligations",
      "questions": [
        { "id": "inspectionNotice", "label": "How many days’ notice does the Landlord give the tenant for an inspection if needed?" }
      ]
    },
    {
      "section": "Deposits & Payment",
      "questions": [
        { "id": "securityDepositAmount", "label": "What is the amount of the security deposit (typically 5% of annual rent)?" },
        { "id": "depositPercentage", "label": "State the percentage of the annual rent secured for deposit" },
        { "id": "renewalNoticePeriod", "label": "Is there any provision for renewal at the end of the lease term? What are the notice period in days?" }
      ]
    },
    {
      "section": "Termination Clauses",
      "questions": [
        { "id": "terminationNoticePeriod", "label": "Will either party be allowed to terminate the agreement early? What is the notice period?" },
        { "id": "penaltyForEarlyTermination", "label": "If the tenant breaks the lease early, will there be any penalties or loss of deposits?" },
        { "id": "additionalClauseTermination", "label": "Include an additional clause, if applicable" }
      ]
    }
  ],
  "NonDisclosureAgreement": [
    {
      "section": "Basic Info",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement (State Day, Month and Year)?", "type": "date" }
      ]
    },
    {
      "section": "Parties Involved",
      "questions": [
        { "id": "discloser", "label": "Who is the Discloser?" },
         { "id": "discloserAddress", "label": "Address of the Discloser?" },
        { "id": "recipient", "label": "Who is the Recipient?" }
      ]
    },
    {
      "section": "Definition of Terms",
      "questions": [
        { "id": "confidentialDefinition", "label": "Define what constitutes Confidential Information." },
        { 
          "id": "confidentialInformationTypes", 
          "label": "Specify all types of information to be covered under 'Confidential Information.'", 
          "options": [
            "Intellectual property", 
            "Product information", 
            "Business operations", 
            "Customer information", 
            "Marketing and development data"
          ], 
          "type": "checkbox" 
        },
        { 
          "id": "additionalCategories", 
          "label": "Are there any additional categories to include? Clearly state." 
        }
      ]
    },
    {
      "section": "Exclusions to Confidential Information",
      "questions": [
        { 
          "id": "excludedInformation", 
          "label": "Is there any specific information already known to the Recipient that should be excluded?" 
        },
        { 
          "id": "thirdPartyInformation", 
          "label": "How should information from third parties be handled?" 
        }
      ]
    },
    {
      "section": "Non-Disclosure Obligations",
      "questions": [
        { 
          "id": "recipientObligations", 
          "label": "What actions must the Recipient take to ensure confidentiality? (Select all that apply)", 
          "options": [
            "Keep the information confidential", 
            "Use the information only for the specified purposes", 
            "Do not disclose to third parties", 
            "Seek written permission before copying"
          ], 
          "type": "checkbox" 
        }
      ]
    },
    {
      "section": "Disclosure Exceptions",
      "questions": [
        { 
          "id": "disclosureRequiredByLaw", 
          "label": "Can the Recipient disclose information if required by law?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "legalDisclosureProcedures", 
          "label": "What procedures must be followed if disclosure is mandated by legal authorities? Clearly state these procedures." 
        }
      ]
    },
    {
      "section": "Return or Destruction of Confidential Information",
      "questions": [
        { 
          "id": "returnDestructionTimeline", 
          "label": "Specify the manner and timeline for returning or destroying confidential information." 
        }
      ]
    },
    {
      "section": "Survival of Obligations",
      "questions": [
        { 
          "id": "obligationDuration", 
          "label": "For how long should the confidentiality obligations remain effective after the termination of the agreement?" 
        }
      ]
    },
    {
      "section": "Dispute Resolution",
      "questions": [
        { 
          "id": "disputeResolutionMethod", 
          "label": "Should disputes be resolved through:", 
          "options": ["Arbitration", "Mediation", "Court proceedings"], 
          "type": "radio" 
        }
      ]
    },
    {
      "section": "Legal Jurisdiction and Governing Law",
      "questions": [
        { 
          "id": "disputeResolutionJurisdiction", 
          "label": "Based on jurisdiction, what is the specified method of resolving disputes?" 
        }
      ]
    },
    {
      "section": "Responsibility of the Parties",
      "questions": [
        { 
          "id": "penaltyForDisclosure", 
          "label": "Is there a predefined penalty for unauthorized disclosure?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "penaltyFine", 
          "label": "If Yes, specify the fine.", 
          "conditional": "penaltyForDisclosure === 'Yes'" 
        }
      ]
    },
    {
      "section": "Additional Clauses",
      "questions": [
        { 
          "id": "additionalClauses", 
          "label": "Are there any additional clauses or amendments?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "clauseDetails", 
          "label": "If Yes, describe them.", 
          "conditional": "additionalClauses === 'Yes'" 
        }
      ]
    }
  ],
  "MemorandumOfUnderstanding": [
    {
      "section": "General Information",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement (State Day, Month and Year)?", "type": "date" },
        { "id": "party1Name", "label": "What is the name of the first party involved in the MoU?" },
        { "id": "party1Address", "label": "What is the address of first party?" },
         { "id": "party2Name", "label": "What is the name of the second party involved in the MoU?" },
        { "id": "party2Address", "label": "What is the address of second party?" },
        { "id": "registeredOffice", "label": "What is the proposed company's registered office location?" },
        { "id": "mouEffectiveDate", "label": "When will the MoU take effect?", "type": "date" }
      ]
    },
    {
      "section": "Services and Contributions",
      "questions": [
        { "id": "servicesContributed", "label": "What services will each party contribute to the project?" },
        { 
          "id": "jointContribution", 
          "label": "Will there be any joint contribution or shared responsibility?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "jointResponsibilities", 
          "label": "If yes, specify the responsibilities of each party.", 
          "conditional": "jointContribution === 'Yes'" 
        }
      ]
    },
    {
      "section": "Confidentiality",
      "questions": [
        { 
          "id": "confidentialityDurationSpecified", 
          "label": "Is there a specific duration for which confidentiality must be maintained?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "confidentialityDuration", 
          "label": "If yes, specify the timeframe (e.g., 12 months, 24 months).", 
          "conditional": "confidentialityDurationSpecified === 'Yes'" 
        }
      ]
    },
    {
      "section": "Timeline and Milestones",
      "questions": [
        { "id": "projectStartDate", "label": "What is the project start date?", "type": "date" },
        { "id": "completionDate", "label": "What is the estimated completion date?", "type": "date" },
        { "id": "keyMilestones", "label": "What are the key milestones, and how will they be tracked?" }
      ]
    },
    {
      "section": "Deadlock Resolution",
      "questions": [
        { "id": "deadlockResolution", "label": "How will disputes be resolved if the parties reach a deadlock?" },
        { "id": "deadlockNoticePeriod", "label": "What is the period for issuing a deadlock notice (e.g., 20 days)?" },
        { "id": "deadlockConsequences", "label": "What are the consequences of failing to resolve a deadlock?" }
      ]
    },
    {
      "section": "Termination Conditions",
      "questions": [
        { 
          "id": "terminationEvents", 
          "label": "What are the events that would lead to termination of the MoU?", 
          "options": ["Breach of obligations", "Insolvency", "Liquidation", "Other"], 
          "type": "checkbox" 
        },
        { 
          "id": "otherTerminationEvents", 
          "label": "If 'Other,' please specify.", 
          "conditional": "terminationEvents.includes('Other')" 
        },
        { "id": "terminationNoticePeriod", "label": "What notice period is required for termination?" }
      ]
    },
    {
      "section": "General",
      "questions": [
        { 
          "id": "successorsClause", 
          "label": "Complete this clause: 'Parties to this agreement and their respective successors and permitted assigns provided that none of the parties to this agreement shall be entitled to...'" 
        }
      ]
    },
    {
      "section": "Notice Requirements",
      "questions": [
        { "id": "contactDetails", "label": "What are the contact details for delivering notices (e.g., address, email, fax number)?" },
        { "id": "noticeServedHours", "label": "How many hours after posting a notice in Nigeria should it be considered served?" },
        { "id": "noticeServedDays", "label": "How many days for outside Nigeria?" }
      ]
    },
    {
      "section": "Additional Clauses Suggestions",
      "questions": [
        { 
          "id": "governingLaw", 
          "label": "Specify that the MoU will be governed by Nigerian law.", 
          "optional": true 
        },
        { 
          "id": "disputeResolution", 
          "label": "Include a clause for arbitration or mediation before litigation.", 
          "optional": true 
        }
      ]
    }
  ],
  "ServiceLevelAgreement": [
    {
      "section": "Parties",
      "questions": [
        { "id": "serviceProviderName", "label": "What is the name of the Service Provider?" },
        { "id": "customerName", "label": "What is the name of the Customer?" }
      ]
    },
    {
      "section": "Scope of Services",
      "questions": [
        { "id": "servicesProvided", "label": "What specific services are being provided under this SLA?" },
        { "id": "serviceDeliveryMethod", "label": "How will the services be delivered (e.g., online, in-person)?" }
      ]
    },
    {
      "section": "Performance Metrics",
      "questions": [
        { "id": "metrics", "label": "What performance metrics will be used to evaluate the service?" },
        { "id": "targets", "label": "What are the specific targets for each performance metric?" }
      ]
    },
    {
      "section": "Reporting and Review",
      "questions": [
        { "id": "reportingFrequency", "label": "How often will performance reports be generated?" },
        { "id": "reviewMeetingsFrequency", "label": "How often will review meetings be held?" }
      ]
    },
    {
      "section": "Responsibilities",
      "questions": [
        { "id": "serviceProviderResponsibilities", "label": "What are the Service Provider’s responsibilities?" },
        { "id": "customerResponsibilities", "label": "What are the Customer’s responsibilities?" }
      ]
    },
    {
      "section": "Compensation",
      "questions": [
        { "id": "serviceFees", "label": "What are the fees associated with the services provided?" },
        { "id": "paymentTerms", "label": "What are the payment terms (e.g., monthly, annually)?" }
      ]
    },
    {
      "section": "Termination",
      "questions": [
        { "id": "terminationConditions", "label": "Under what conditions can the SLA be terminated?" },
        { "id": "noticePeriod", "label": "What is the notice period for termination?" }
      ]
    },
    {
      "section": "Amendments",
      "questions": [
        { "id": "amendmentProcess", "label": "How can the SLA be amended or updated?" }
      ]
    }
  ], 
  "PreIncorporationAgreement": [
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement (State Day, Month and Year)?", "type": "date" }
      ]
    },
    {
      "section": "Company Information",
      "questions": [
        { "id": "proposedCompanyName", "label": "What is the proposed name of the company?" },
        { "id": "natureOfBusiness", "label": "What is the nature of the business?" },
        { "id": "proposedShareCapital", "label": "What is the proposed share capital?" }
      ]
    },
    {
      "section": "Shareholders and Equity Distribution",
      "questions": [
        { "id": "promoters", "label": "Who are the promoters?" },
        { "id": "ordinaryShares", "label": "How many ordinary shares will be issued?" },
        { "id": "valuePerShare", "label": "What is the value per share?" }
      ]
    },
    {
      "section": "Proposed Company’s Purpose",
      "questions": [
        {
          "id": "companyPurpose",
          "label": "What is the primary purpose or business focus of the proposed company?",
          "options": ["Joint venture", "Investment vehicle", "Research and development", "Other"],
          "type": "select"
        }
      ]
    },
    {
      "section": "Share Subscription",
      "questions": [
        {
          "id": "shareSubscription",
          "label": "Upon incorporation, who will subscribe to the shares, and what percentage will each party hold?",
          "fields": ["Name", "Percentage"]
        }
      ]
    },
    {
      "section": "Business Scope and Activities Prior to Incorporation",
      "questions": [
        { "id": "activitiesPriorToIncorporation", "label": "What activities can the parties engage in before the company is incorporated?" },
        {
          "id": "businessScope",
          "label": "What will be the business scope of the proposed company?",
          "options": ["Manufacturing", "Service provision", "Distribution", "Research and Development"],
          "type": "select"
        }
      ]
    },
    {
      "section": "Accounting and Financial Reporting",
      "questions": [
        { "id": "financialYearEnd", "label": "What will be the proposed company’s financial year-end?" },
        { "id": "financialReports", "label": "What financial reports must be produced, and how often?" }
      ]
    },
    {
      "section": "Accounting and Dividend Policy",
      "questions": [
        { "id": "managementAccountsResponsibility", "label": "Who will be responsible for preparing management accounts?" },
        { "id": "reportFrequency", "label": "How often will financial reports be shared with the parties? (e.g., monthly, quarterly)" },
        { "id": "businessPlanDeadline", "label": "What is the projected annual business plan submission deadline?" }
      ]
    },
    {
      "section": "Confidentiality Clauses",
      "questions": [
        { "id": "confidentialInformation", "label": "Is there any specific information that should be treated as confidential?", "options": ["Yes", "No"], "type": "radio" },
        {
          "id": "confidentialityDuration",
          "label": "How long should confidentiality obligations last after the agreement terminates?",
          "options": ["12 months", "24 months", "Indefinitely"],
          "type": "radio"
        }
      ]
    },
    {
      "section": "Deadlock Resolution",
      "questions": [
        { "id": "deadlockResolution", "label": "How will deadlock situations be handled? (e.g., selling or purchasing shares)" },
        {
          "id": "deadlockNoticePeriod",
          "label": "Should the deadlock notice period be [ ] 20 days, [ ] 30 days, or other? Specify:"
        },
        {
          "id": "counterNoticeTimeframe",
          "label": "What is the timeframe for issuing a counter notice after a deadlock notice is served? (e.g., 21 days)"
        }
      ]
    },
    {
      "section": "Termination and Supremacy Clauses",
      "questions": [
        { "id": "terminationEvents", "label": "What events will lead to termination?" },
        {
          "id": "incorporationIntoDocuments",
          "label": "How will the provisions of this agreement be incorporated into the company’s governing documents?"
        }
      ]
    },
    {
      "section": "Additional Clauses Suggestions",
      "questions": [
        {
          "id": "nonCompeteClause",
          "label": "Include a non-compete clause outlining restricted activities for the parties involved post-termination.",
          "optional": true
        },
        {
          "id": "arbitrationClause",
          "label": "Add a mandatory arbitration clause to resolve disputes more efficiently before heading to court.",
          "optional": true
        }
      ]
    }
  ],
  "EmploymentAgreement": [
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement (State Day, Month and Year)?", "type": "date" }
      ]
    },
    {
      "section": "Parties to the Agreement",
      "questions": [
        { "id": "employerName", "label": "State the Employer?" },
        { "id": "employeeName", "label": "State the Employee?" }
      ]
    },
    {
      "section": "Position and Duties",
      "questions": [
        { "id": "positionTitle", "label": "What is the title of the position the employee is being hired for?" },
        { "id": "primaryDuties", "label": "What are the primary duties and responsibilities?" }
      ]
    },
    {
      "section": "Compensation",
      "questions": [
        { "id": "annualSalary", "label": "What is the annual salary?" },
        { "id": "salaryCurrency", "label": "What currency is the salary denominated in?" },
        { "id": "bonusesBenefits", "label": "Will there be any bonuses, benefits, or reimbursements?", "options": ["Yes", "No"], "type": "radio" }
      ]
    },
    {
      "section": "Duration of Employment",
      "questions": [
        { "id": "employmentCommencement", "label": "When does the employment commence?", "type": "date" },
        { "id": "specifiedTerm", "label": "Is there a specified term of employment?", "options": ["Yes", "No"], "type": "radio" },
        { "id": "employmentTerm", "label": "If Yes, what is the term?", "conditional": "specifiedTerm === 'Yes'" }
      ]
    },
    {
      "section": "Employee Obligations",
      "questions": [
        { "id": "businessRestrictions", "label": "Is the employee restricted from engaging in other business interests?", "options": ["Yes", "No"], "type": "radio" },
        { "id": "restrictionDetails", "label": "If Yes, specify the restrictions.", "conditional": "businessRestrictions === 'Yes'" }
      ]
    },
    {
      "section": "Nondisclosure and Confidentiality",
      "questions": [
        { "id": "confidentialInformation", "label": "What specific business information is considered confidential?" },
        { "id": "additionalConfidentiality", "label": "Are there any additional confidentiality obligations?", "options": ["Yes", "No"], "type": "radio" },
        { "id": "confidentialityDetails", "label": "If Yes, specify them.", "conditional": "additionalConfidentiality === 'Yes'" }
      ]
    },
    {
      "section": "Termination Clauses",
      "questions": [
        { "id": "terminationCircumstances", "label": "Under what circumstances can the contract be terminated by either party?", "options": ["Misconduct", "Redundancy", "Health Issues", "Other"], "type": "select" },
        { "id": "otherTerminationCircumstances", "label": "If 'Other,' specify the circumstances.", "conditional": "terminationCircumstances === 'Other'" }
      ]
    }
  ],
  "MultimediaPublicityPrivacyReleaseAgreement": [
    {
      "section": "Basic Information",
      "questions": [
        { "id": "effectiveDate", "label": "What is the effective date of this agreement?", "type": "date" }
      ]
    },
    {
      "section": "Releasor Information",
      "questions": [
        { "id": "releasorName", "label": "What is the full name of the Releasor?" },
        { "id": "releasorLaws", "label": "Under what laws is the Releasor organized and existing?" },
        { "id": "releasorJurisdiction", "label": "In which country and jurisdiction does the Releasor operate?" }
      ]
    },
    {
      "section": "Content Creator Information",
      "questions": [
        { "id": "creatorName", "label": "What is the full name of the Content Creator (Developer/Creator)?" },
        { "id": "creatorLaws", "label": "What are the laws governing the Content Creator’s organization?" },
        { "id": "creatorAddress", "label": "Where is the head office of the Content Creator located?" }
      ]
    },
    {
      "section": "Territories of Use",
      "questions": [
        { "id": "territories", "label": "What are the geographical details or territories where the Releasor is authorized to use the materials?" }
      ]
    },
    {
      "section": "Materials Details",
      "questions": [
        { "id": "materials", "label": "What specific materials are being released for use (e.g., voice, photographs, visual likeness, etc.)?" }
      ]
    },
    {
      "section": "Ownership Confirmation",
      "questions": [
        {
          "id": "exclusiveOwner",
          "label": "Will the Developer/Creator be the exclusive owner of all rights, including copyright, for the Works?",
          "options": ["Yes", "No"],
          "type": "radio"
        }
      ]
    },
    {
      "section": "Ownership Agreement",
      "questions": [
        {
          "id": "exclusiveRights",
          "label": "Will the Developer/Creator have exclusive rights over advertising and promotional materials containing the Materials?",
          "options": ["Yes", "No"],
          "type": "radio"
        }
      ]
    },
    {
      "section": "Signatures",
      "questions": [
        { "id": "releasorSignature", "label": "What is the name and title of the person representing the Releasor?" },
        { "id": "creatorSignature", "label": "What is the name and title of the person representing the Content Creator?" }
      ]
    },
    {
      "section": "Schedule A – Materials Details",
      "questions": [
        { "id": "scheduleMaterials", "label": "Provide a detailed description of all materials to be included in Schedule A." }
      ]
    }
  ],
  "SoftwareAppPrivacyPolicy": [
    {
      "section": "Company Details",
      "questions": [
        { "id": "companyName", "label": "What is the name of the company responsible for this privacy policy?" }
      ]
    },
    {
      "section": "Data Security",
      "questions": [
        { "id": "securityMeasures", "label": "What specific technology or security measures are used to protect sensitive transactions and data?" }
      ]
    },
    {
      "section": "Data Sharing Policies",
      "questions": [
        { "id": "dataSharingReference", "label": "What is the company name to be used in reference to data-sharing policies?" }
      ]
    },
    {
      "section": "Children’s Privacy",
      "questions": [
        { "id": "minimumAge", "label": "What is the minimum age requirement for users on the platform?" }
      ]
    },
    {
      "section": "Contact Information",
      "questions": [
        { "id": "contactEmail", "label": "What is the email address where users can send privacy-related inquiries?" }
      ]
    },
    {
      "section": "Policy Details",
      "questions": [
        { "id": "effectiveDate", "label": "What is the effective date of this privacy policy?", "type": "date" }
      ]
    }
  ],
  "LoanAgreement2": [
    {
      "section": "Borrower Information",
      "questions": [
        { "id": "borrowerName", "label": "What is the full name of the Borrower?" },
        { "id": "borrowerAddress", "label": "What is the address of the Borrower?" },
        { "id": "borrowerOccupation", "label": "What is the Borrower’s occupation?" },
        { "id": "borrowerSignature", "label": "What is the name of the Borrower signing the agreement?" }
      ]
    },
    {
      "section": "Lender Information",
      "questions": [
        { "id": "lenderName", "label": "What is the full name of the Lender?" },
        { "id": "lenderAddress", "label": "What is the address of the Lender?" },
        { "id": "additionalLenderAddress", "label": "Provide any additional address details for the Lender, if applicable." },
        { "id": "lenderSignature", "label": "What is the name of the Lender signing the agreement?" }
      ]
    },
    {
      "section": "Loan Details",
      "questions": [
        { "id": "loanDate", "label": "On what date will the loan funds be disbursed?", "type": "date" },
        { "id": "loanAmount", "label": "What is the principal amount of the loan, and in what currency?" },
        { "id": "interestRate", "label": "What is the annual interest rate for the loan?" }
      ]
    },
    {
      "section": "Payment Terms",
      "questions": [
        { "id": "partialPaymentMultiple", "label": "Are partial payments required in multiples of a specific amount? If so, what is that amount?" },
        { "id": "minimumPaymentAmount", "label": "What is the minimum payment amount required for partial payments?" },
        { "id": "interestAdjustmentPeriod", "label": "How many days after final payment should the final interest adjustment be made?" }
      ]
    }
  ],
  "LetterOfCharge": [
    {
      "section": "Bank Information",
      "questions": [
        { "id": "bankName", "label": "What is the name of the bank involved in this agreement?" },
        { "id": "bankAddress", "label": "What is the address of the bank involved?" }
      ]
    },
    {
      "section": "Charge Details",
      "questions": [
        { "id": "chargeAmount", "label": "What is the currency and amount to be charged for indebtedness or liabilities?" },
        { "id": "repaymentFailureDetails", "label": "What specific details should apply if repayment fails?" }
      ]
    },
    {
      "section": "Bank Rights",
      "questions": [
        { "id": "rightsCircumstances", "label": "Under what circumstances can the bank exercise its rights without notice?" }
      ]
    },
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement?", "type": "date" }
      ]
    }
  ],
  "IndemnityAgreement": [
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "effectiveDate", "label": "When does this Indemnity Agreement become effective?", "type": "date" },
        { "id": "executionPlace", "label": "Where/What place was this agreement executed?" }
      ]
    },
    {
      "section": "Indemnitor Information",
      "questions": [
        { "id": "indemnitorName", "label": "Who is the indemnitor in this agreement?" },
        { "id": "indemnitorJurisdiction", "label": "Under which country and state laws is the indemnitor organized?" }
      ]
    },
    {
      "section": "Indemnitee Information",
      "questions": [
        { "id": "indemniteeName", "label": "Who is the indemnitee in this agreement?" },
        { "id": "indemniteeJurisdiction", "label": "Under which country and state laws is the indemnitee organized?" }
      ]
    },
    {
      "section": "Consideration Details",
      "questions": [
        { "id": "considerationAmount", "label": "What is the currency and amount of consideration acknowledged?" }
      ]
    },
    {
      "section": "Indemnity Coverage",
      "questions": [
        { "id": "indemnityActs", "label": "Specify the acts or events that necessitate indemnity." },
        { "id": "indemnityStartDate", "label": "What is the start date of indemnity coverage under this agreement?", "type": "date" },
        { "id": "indemnityTerminationDate", "label": "Specify the termination date for this indemnity.", "type": "date" },
        { "id": "terminationCondition", "label": "Specify the termination condition for this indemnity." }
      ]
    },
    {
      "section": "Claims Notification",
      "questions": [
        { "id": "notificationPeriod", "label": "How many days does the indemnitee have to notify the indemnitor of any claims?" }
      ]
    }
  ],
  "TransferOfBillOfSale": [
    {
      "section": "Transfer Details",
      "questions": [
        { "id": "transferDate", "label": "What is the date of the transfer?", "type": "date" }
      ]
    },
    {
      "section": "Party A Information",
      "questions": [
        { "id": "partyAName", "label": "What is the name of Party A?" },
        { "id": "partyAAddress", "label": "What is the address of Party A?" }
      ]
    },
    {
      "section": "Party B Information",
      "questions": [
        { "id": "partyBName", "label": "What is the name of Party B?" },
        { "id": "partyBAddress", "label": "What is the address of Party B?" }
      ]
    },
    {
      "section": "Party C Information",
      "questions": [
        { "id": "partyCName", "label": "What is the name of Party C?" },
        { "id": "partyCAddress", "label": "What is the address of Party C?" }
      ]
    },
    {
      "section": "Original Bill of Sale",
      "questions": [
        { "id": "originalDate", "label": "What is the date of the original Bill of Sale?", "type": "date" },
        { "id": "billOfSaleAmount", "label": "What currency and amount are secured by the Bill of Sale?" }
      ]
    },
    {
      "section": "Indebtedness Details",
      "questions": [
        { "id": "totalIndebtedness", "label": "What is the total indebtedness owed under the Bill of Sale?" },
        { "id": "debtAcknowledgment", "label": "What is the currency and amount of debt acknowledgment from Party A to Party B?" },
        { "id": "titleGuarantee", "label": "What type of title guarantee is transferred by Party A?" },
        { "id": "installmentDebt", "label": "What currency and amount of the indebtedness is payable by Party C in installments?" }
      ]
    }
  ],
  "LetterOfDemand": [
    {
      "section": "Letter Details",
      "questions": [
        { "id": "demandDate", "label": "What is the date of this demand letter?", "type": "date" }
      ]
    },
    {
      "section": "Recipient's Information",
      "questions": [
        { "id": "recipientName", "label": "What is the recipient’s name as used in the letter?" },
        { "id": "recipientBusinessAddress", "label": "What is the recipient’s business address?" },
        { "id": "thirdPartyName", "label": "What is the name of the business or third party that owes the debt?" }
      ]
    },
    {
      "section": "Debt Information",
      "questions": [
        { "id": "amountDue", "label": "What is the currency and total amount owed by the recipient?" },
        { "id": "transactionDescription", "label": "Briefly describe the transaction or agreement that led to this debt (e.g., services rendered, goods supplied, or loan provided)." },
        { "id": "originalDueDate", "label": "What was the original due date for the payment?", "type": "date" },
        { "id": "goodsServicesProvided", "label": "Specify the goods or services provided in good faith." },
        { "id": "overduePeriod", "label": "How many days or weeks has the payment been overdue as of the letter’s date?" }
      ]
    },
    {
      "section": "Settlement Information",
      "questions": [
        { "id": "timeForSettlement", "label": "What is the time frame for the recipient to settle the debt (e.g., 14 days)?" },
        { "id": "bankAccountDetails", "label": "Provide the bank account details where payment should be made." }
      ]
    },
    {
      "section": "Contact Information",
      "questions": [
        { "id": "contactInformation", "label": "What is the contact information for any questions or concerns regarding the matter?" }
      ]
    },
    {
      "section": "Sender’s Information",
      "questions": [
        { "id": "senderName", "label": "What is the sender’s full name?" },
        { "id": "senderPosition", "label": "What is the sender’s position, if applicable?" },
        { "id": "senderCompany", "label": "What is the name of the sender’s company, if applicable?" },
        { "id": "senderContactDetails", "label": "Provide the sender’s contact information." }
      ]
    }
  ],
  "LetterOfCredit": [
    {
      "section": "Company Information",
      "questions": [
        { "id": "companyName", "label": "What is the name of the company that will issue payment?" }
      ]
    },
    {
      "section": "Endorsement Authority",
      "questions": [
        { "id": "endorsementAuthority", "label": "Who has the authority to sign or endorse checks on behalf of the company?" }
      ]
    },
    {
      "section": "Bearer Details",
      "questions": [
        { "id": "designatedBearer", "label": "Who is the designated bearer of this letter?" }
      ]
    },
    {
      "section": "Payment Limits",
      "questions": [
        { "id": "maximumCheckAmount", "label": "What is the maximum allowable amount for each check that can be issued under this letter?" },
        { "id": "totalSumPayable", "label": "What is the total sum the company is willing to pay under this letter?" }
      ]
    },
    {
      "section": "Documentation",
      "questions": [
        { "id": "sumNotation", "label": "How should the checks and amounts be documented on this letter?" }
      ]
    },
    {
      "section": "Beneficiary Information",
      "questions": [
        { "id": "beneficiaryName", "label": "What is the full name of the designated beneficiary?" },
        { "id": "beneficiarySignature", "label": "What is the signature of the designated beneficiary?" }
      ]
    }
  ],
  "MediumTermLoanFacilityAgreement": [
    {
      "section": "Company Details",
      "questions": [
        { "id": "companyName", "label": "What is the name of the company receiving the loan?" },
        { "id": "companyAddress", "label": "What is the address of the company?" },
        { "id": "registeredNumber", "label": "What is the registered number of the borrower’s company?" },
        { "id": "registeredOffice", "label": "What is the address of the company’s registered office?" }
      ]
    },
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of this agreement?", "type": "date" },
        { "id": "salutation", "label": "To whom is the letter addressed (Sir/Madam)?" }
      ]
    },
    {
      "section": "Loan Details",
      "questions": [
        { "id": "loanAmount", "label": "What is the total principal amount of the Naira loan facility?" },
        { "id": "loanPurpose", "label": "What is the purpose of the loan being granted?" },
        { "id": "termsConditions", "label": "Outline the terms and conditions associated with the loan facility." }
      ]
    },
    {
      "section": "Bank Details",
      "questions": [
        { "id": "bankName", "label": "What is the name of the bank providing the loan?" },
        { "id": "bankAddress", "label": "What is the address of the bank branch handling this loan?" },
        { "id": "successorsAssigns", "label": "Is there an inclusion of the bank’s successors and assigns in the agreement?", "options": ["Yes", "No"], "type": "radio" }
      ]
    },
    {
      "section": "Repayment Terms",
      "questions": [
        { "id": "commitmentExpiryDate", "label": "How many months after this letter will the commitment expire?" },
        { "id": "repaymentDates", "label": "Specify the repayment dates of the loan." },
        { "id": "repaymentFrequency", "label": "What is the frequency of the repayments (e.g., every few months)?" },
        { "id": "finalRepaymentDate", "label": "When is the final date for repayment of the loan?", "type": "date" }
      ]
    },
    {
      "section": "Drawing Conditions",
      "questions": [
        { "id": "firstDrawingTime", "label": "By what time should the first drawing occur?" },
        { "id": "drawingNoticeTime", "label": "When must a notice of drawing be sent before each drawing?" },
        { "id": "drawingConditions", "label": "Are there specific conditions under which drawings can be made?" },
        { "id": "drawingDetails", "label": "What additional details are required for each drawing?" },
        { "id": "minimumDrawingAmount", "label": "What is the minimum amount for each drawing?" },
        { "id": "integralMultiple", "label": "What should be the integral multiple for each drawing?" }
      ]
    },
    {
      "section": "Interest Rates",
      "questions": [
        { "id": "interestRate", "label": "What is the interest rate per annum for the loan?" },
        { "id": "defaultInterestRate", "label": "What is the interest rate per annum in the event of default?" },
        { "id": "interestCalculationBasis", "label": "On what basis should interest be calculated (e.g., daily)?" }
      ]
    },
    {
      "section": "Repayment Instalments",
      "questions": [
        { "id": "repaymentInstalmentPercentage", "label": "What percentage of the loan is to be repaid with each instalment?" }
      ]
    },
    {
      "section": "Prepayment Terms",
      "questions": [
        { "id": "prepaymentNoticePeriod", "label": "How many days’ notice is required before prepayment?" },
        { "id": "prepaymentDetails", "label": "Are there specific details about partial prepayments?" }
      ]
    },
    {
      "section": "Cancellation Terms",
      "questions": [
        { "id": "cancellationNoticePeriod", "label": "How many days’ notice is required to cancel part or all of the facility?" },
        { "id": "minimumCancellationPeriod", "label": "What is the minimum duration for which the facility can be canceled?" },
        { "id": "cancellationIntegralMultiple", "label": "What should be the integral multiple for canceling portions of the facility?" }
      ]
    },
    {
      "section": "Financial Obligations",
      "questions": [
        { "id": "auditedFinancialsDate", "label": "Specify the date of the last audited financials of the borrower.", "type": "date" },
        { "id": "securityInterestsExceptions", "label": "Are there any exceptions to the borrower’s restriction on creating security interests?" },
        { "id": "annualReportDueDate", "label": "By how many days after the fiscal year should the annual report be provided?" },
        { "id": "annualReportCopies", "label": "How many copies of the annual audited report should be submitted?" }
      ]
    },
    {
      "section": "Default Conditions",
      "questions": [
        { "id": "ownershipCondition", "label": "Are there conditions on ownership change that trigger an Event of Default?" },
        { "id": "changeOfControlCondition", "label": "Describe any specific change of control conditions." }
      ]
    },
    {
      "section": "Payment Terms",
      "questions": [
        { "id": "paymentCurrency", "label": "In which currency should all payments be made?" },
        { "id": "paymentLocation", "label": "Where should the borrower make payments to the bank?" },
        { "id": "deductionDetails", "label": "Are there specific details about any deductions that may apply to payments?" }
      ]
    },
    {
      "section": "Commitment Commission",
      "questions": [
        { "id": "commitmentCommissionRate", "label": "What is the rate for commitment commission?" },
        { "id": "commitmentPaymentFrequency", "label": "How frequently is the commitment commission due?" }
      ]
    },
    {
      "section": "Expenses",
      "questions": [
        { "id": "expensesCoverage", "label": "Are there other expenses that the borrower must cover?" },
        { "id": "expenseDetails", "label": "What expenses, such as VAT, must the borrower reimburse?" }
      ]
    },
    {
      "section": "Notices",
      "questions": [
        { "id": "noticeToBankAddress", "label": "To which address should notices to the bank be sent?" },
        { "id": "noticeToBorrowerAddress", "label": "What is the address for sending notices to the borrower?" },
        { "id": "communicationDetails", "label": "Describe any specific communication methods permitted for notices." },
        { "id": "confirmationPeriod", "label": "What is the period within which a notice should be confirmed by letter?" },
        { "id": "authorizationConfirmation", "label": "How will the bank verify notice authorizations?" }
      ]
    }
  ],
  "LetterOfIntent": [
    {
      "section": "General Information",
      "questions": [
        { "id": "agreementDate", "label": "Enter the date this Letter of Intent (LOI) will be formalized.", "type": "date" },
        { "id": "purposeOfLOI", "label": "What is the primary purpose of this LOI?", "options": ["Joint Venture", "Partnership", "Asset Purchase"], "type": "select" }
      ]
    },
    {
      "section": "Parties Information",
      "questions": [
        { "id": "partyAName", "label": "Name of the first party involved in the agreement." },
        { "id": "partyARegistrationNumber", "label": "Provide the official registration number of Party A." },
        { "id": "partyAAddress", "label": "Address of Party A's registered office." },
        { "id": "partyBName", "label": "Name of the second party involved in the agreement." },
        { "id": "partyBRegistrationNumber", "label": "Provide the official registration number of Party B." },
        { "id": "partyBAddress", "label": "Address of Party B's registered office." }
      ]
    },
    {
      "section": "Purpose and Transaction Details",
      "questions": [
        { "id": "transactionDescription", "label": "Provide a brief description of the proposed transaction (e.g., 'Joint venture to develop X product,' 'Partnership to distribute Y services')." }
      ]
    },
    {
      "section": "Proposed Terms and Conditions",
      "questions": [
        { "id": "scopeOfTransaction", "label": "Describe in detail the scope of the transaction, including the objectives, anticipated contributions, responsibilities, or commitments of each party." },
        { "id": "consideration", "label": "What is the anticipated consideration or compensation for this transaction?" },
        { "id": "dueDiligenceTimeframe", "label": "Specify the expected duration for completing the due diligence review (e.g., '30 days,' '60 days')." },
        { "id": "exclusivityPeriod", "label": "Indicate the period during which the exclusivity clause applies, in which neither party will engage with third parties for a similar transaction." },
        { "id": "governingJurisdiction", "label": "Identify the specific legal jurisdiction in Nigeria where any disputes arising from this LOI would be resolved." }
      ]
    },
    {
      "section": "Termination Clause",
      "questions": [
        { "id": "terminationTimeframe", "label": "Provide the timeframe after which the LOI will automatically terminate if no formal agreement is reached (e.g., '90 days from the date of the LOI')." }
      ]
    },
    {
      "section": "Signatures Section",
      "questions": [
        { "id": "partyAPrintedName", "label": "Full name of the person signing on behalf of Party A." },
        { "id": "partyATitle", "label": "Title or position of the person signing on behalf of Party A." },
        { "id": "partyBPrintedName", "label": "Full name of the person signing on behalf of Party B." },
        { "id": "partyBTitle", "label": "Title or position of the person signing on behalf of Party B." }
      ]
    }
  ],
  "NoticeOfDefault": [
    {
      "section": "Principal Debtor Information",
      "questions": [
        { "id": "principalDebtorName", "label": "What is the full name of the principal debtor?" },
        { "id": "principalDebtorAddress", "label": "What is the principal debtor’s address?" }
      ]
    },
    {
      "section": "Creditor Information",
      "questions": [
        { "id": "creditorName", "label": "What is the full name of the creditor issuing this notice?" },
        { 
          "id": "creditorType", 
          "label": "Is the creditor an individual or an organization?", 
          "options": ["Individual", "Organization"], 
          "type": "radio" 
        },
        { 
          "id": "organizationIndividuals", 
          "label": "If an organization, provide the names of the individuals responsible for issuing this notice.", 
          "conditional": "creditorType === 'Organization'" 
        }
      ]
    },
    {
      "section": "Agreement Details",
      "questions": [
        { "id": "agreementDate", "label": "What is the date of the Agreement of Guarantee (date the guarantee was signed)?", "type": "date" },
        { "id": "obligationType", "label": "What type of obligation is covered by the agreement? (e.g., Goods supplied, Advances made)" },
        { "id": "paymentTerms", "label": "What specific terms apply to the payment (e.g., interest rate, payment schedule, late fees)?" }
      ]
    },
    {
      "section": "Amount Due",
      "questions": [
        { "id": "amountDue", "label": "What is the exact amount due?" },
        { 
          "id": "includesInterest", 
          "label": "Does this amount include interest, and if so, what rate and terms?" 
        }
      ]
    },
    {
      "section": "Payment Instructions",
      "questions": [
        { "id": "paymentAddress", "label": "Where should payment be made (provide address or account details)?" },
        { "id": "paymentHours", "label": "During which hours is payment accepted (include the starting and ending times)?" }
      ]
    },
    {
      "section": "Timeframe for Payment Before Legal Action",
      "questions": [
        { "id": "paymentDeadline", "label": "What is the deadline for payment before legal action is initiated? (e.g., 10, 30 days from the notice date)" }
      ]
    },
    {
      "section": "Notice Date",
      "questions": [
        { "id": "noticeDate", "label": "What is the date of this Notice of Default?", "type": "date" }
      ]
    }
  ],
  "TenantsNoticeOfIntentToMoveOut": [
    {
      "section": "Notice Date",
      "questions": [
        { "id": "noticeDate", "label": "What is the date of this notice?", "type": "date" }
      ]
    },
    {
      "section": "Landlord or Manager Information",
      "questions": [
        { "id": "landlordName", "label": "What is the full name of the landlord or property manager?" },
        { "id": "landlordAddress", "label": "What is the street address for sending this notice?" },
        { "id": "propertyCityState", "label": "In which city and state is the property located?" }
      ]
    },
    {
      "section": "Rental Property Details",
      "questions": [
        { "id": "rentalPropertyAddress", "label": "What is the full address of the rental property?" }
      ]
    },
    {
      "section": "Notice Period",
      "questions": [
        { "id": "moveOutDate", "label": "What is the intended move-out date?", "type": "date" },
        { 
          "id": "flexibleNoticePeriod", 
          "label": "How many days from today until the move-out date (if providing a flexible notice period)?" 
        }
      ]
    },
    {
      "section": "Tenant Information",
      "questions": [
        { "id": "tenantName", "label": "What is the full name of the tenant giving notice?" }
      ]
    },
    {
      "section": "Additional Clauses (optional)",
      "questions": [
        { 
          "id": "requestSecurityDepositProcedure", 
          "label": "Would you like to include a request for the security deposit return procedure?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "requestMoveOutInspection", 
          "label": "Would you like to request a move-out inspection date?", 
          "options": ["Yes", "No"], 
          "type": "radio" 
        },
        { 
          "id": "forwardingAddressInstructions", 
          "label": "Any specific instructions for the forwarding address or final mail arrangements?" 
        }
      ]
    }
  ],


























  // Add other agreements here...
};


