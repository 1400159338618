import React from 'react'

const PaymentMethod = ({ cardType, cardNumber, logoSrc }) => (
  <div className="relative flex flex-row items-center flex-auto min-w-0 p-6 break-words bg-transparent border border-solid shadow-none md-max:overflow-auto rounded-xl border-[#000] dark:border-slate-700 bg-clip-border dark:!border-[#a711007a]">
    <img className="mb-0 mr-4 w-[10%]" src={logoSrc} alt="logo" />
    <h6 className="mb-0 dark:text-white">{cardNumber}</h6>
    <i className="ml-auto cursor-pointer fas fa-pencil-alt text-slate-700" data-target="tooltip_trigger" data-placement="top"></i>
    <div data-target="tooltip" className="hidden px-2 py-1 text-sm text-white bg-black rounded-lg">
      Edit Card
      <div className="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']" data-popper-arrow></div>
    </div>
  </div>
);

export default PaymentMethod;