import React from 'react'
import CustomTemplate from "../components/Products/CustomTemplate/CustomTemplate.jsx"
import Footer from "../components/Layout/Footer";
import Resources from "../components/Products/CustomTemplate/Resources/Resources.jsx";
import Navbar from "../components/Layout/Navbar";
import Sponsored from "../components/Route/Sponsored";

const CustomTemplatePage = () => {
  return (

    <div className='dark:bg-[#490800] dark:text-white '>
      <Navbar />
      <div className="max-w-7xl mx-auto px-6">
         <CustomTemplate />
        <Resources />
        <Sponsored />
      </div>

      <Footer />
    </div>
   
  )
}

export default CustomTemplatePage