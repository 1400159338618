import React from "react";
import Book1 from "../../../Assests/books/book1.png";
import Book2 from "../../../Assests/books/book2.jpg";
import Book3 from "../../../Assests/books/book3.jpg";

import Img1 from "../../../Assests/books/book5.jpeg";
import Img2 from "../../../Assests/books/book6.jpeg";
import Img3 from "../../../Assests/books/book4.png";
import { FaStar } from "react-icons/fa6";

const booksData = [
  {
    id: 1,
    img: Book1,
    title: "Nigerian Law Decoded",
    rating: 5.0,
    author: "Mabel Adetoun Adebiyi",
     buy: "N140,000",
    rent: "N15,000/weekly",
  },
  {
    id: 2,
    img: Book2,
    title: "Journal of African Law",
    rating: 4.5,
    author: "SOAS",
     buy: "N140,000",
    rent: "N15,000/weekly",
  },
  {
    id: 3,
    img: Book3,
    title: "Law Quarterly",
    rating: 4.7,
    author: "",
     buy: "N140,000",
    rent: "N15,000/weekly",
  },
  {
    id: 4,
     img: Img2,
    title: "S&A Report",
    rating: 4.4,
    author: "Funmi Ayeni",
     buy: "N140,000",
    rent: "N15,000/weekly",
  },
  {
    id: 5,
    img: Img1,
    title: "Guideline Informal Justice",
    rating: 4.5,
    author: "Funmi Ayeni",
     buy: "N140,000",
    rent: "N15,000/weekly",
  },
];

const Books = ({handleOrderPopup}) => {
  return (
    <>
      <div className="mt-14 mb-12">
        <div className="container">
          {/* header */}
          <div className="text-center mb-10 max-w-[600px] mx-auto">
            <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
              Top Books for you
            </p>
            <h1 className="text-3xl font-bold">Top Books</h1>
            <p className="text-xs text-gray-400">
            
            </p>
          </div>

          {/* Body section */}
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 place-items-center gap-5">
              {/* Card */}
              {booksData.map(({ id, img, title, rating, author, rent, buy }) => (
                <div key={id} className="div space-y-3">
                  <img
                    src={img}
                    alt=""
                    className="h-[220px] w-[150px] object-cover rounded-md "
                  />
                  <div>
                    <h3 className="font-semibold">{title}</h3>
                    <p className="text-sm text-gray-700">{author}</p>
                    <div className="flex items-center gap-1">
                      <FaStar className="text-yellow-500" />
                      <span>{rating}</span>
                    </div>
                  </div>
                     {/* <div className="flex items-center justify-between mt-4">
                    <div>
                    <p className="text-sm text-[#780D00] dark:text-white font-bold">{rent}</p>
                     <button
                    className="hover:scale-105 bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    onClick={handleOrderPopup}
                  >
                    Rent
                  </button>
                    </div>

                    <div>
                    <p className="text-sm text-[#780D00] dark:text-white font-bold">{buy}</p>
                     <button
                    className="hover:scale-105 bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    onClick={handleOrderPopup}
                  >
                    Buy
                  </button>
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button className="text-center mt-10 cursor-pointer  bg-primary text-white py-1 px-5 rounded-md">
                View All Books
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Books;
