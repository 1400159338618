import React, { useState } from 'react';
import Footer from '../components/Layout/Footer';
import Navbar from '../components/Layout/Navbar.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

const plans = [
    {
      name: "Free",
      currency: "₦",
      price: "0",
      features: {
        database: false,
        websites: false,
        storage: "100 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Standard",
      currency: "₦",
      price: "99,000",
      features: {
        database: "15",
        websites: "10",
        storage: "500 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Enterprise",
      currency: "₦",
      price: "150,000",
      features: {
        database: "50",
        websites: "50",
        storage: "1 GB",
        serverSpeed: true,
      },
      Enterprise: true,
    },
    {
      name: "Business",
      currency: "₦",
      price: "490,000",
      features: {
        database: "Unlimited",
        websites: "Unlimited",
        storage: "Unlimited",
        serverSpeed: true,
      },
    },
    {
      name: "Free",
      currency: "KES",
      price: "0",
      features: {
        database: false,
        websites: false,
        storage: "100 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Standard",
      currency: "KES",
      price: "88,000",
      features: {
        database: "15",
        websites: "10",
        storage: "500 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Enterprise",
      currency: "KES",
      price: "140,000",
      features: {
        database: "50",
        websites: "50",
        storage: "1 GB",
        serverSpeed: true,
      },
      Enterprise: true,
    },
    {
      name: "Business",
      currency: "KES",
      price: "330,000",
      features: {
        database: "Unlimited",
        websites: "Unlimited",
        storage: "Unlimited",
        serverSpeed: true,
      },
    },

    {
      name: "Free",
      currency: "$",
      price: "0",
      features: {
        database: false,
        websites: false,
        storage: "100 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Standard",
      currency: "$",
      price: "99",
      features: {
        database: "15",
        websites: "10",
        storage: "500 MB",
        serverSpeed: false,
      },
    },
    {
      name: "Enterprise",
      currency: "$",
      price: "150",
      features: {
        database: "50",
        websites: "50",
        storage: "1 GB",
        serverSpeed: true,
      },
      Enterprise: true,
    },
    {
      name: "Business",
      currency: "$",
      price: "490",
      features: {
        database: "Unlimited",
        websites: "Unlimited",
        storage: "Unlimited",
        serverSpeed: true,
      },
    },
  ];


   const pricingData = {
    'Legal database': {
      '24 Hours': [
        { currency: '₦', qty: '5 downloads', price: 5000 },
        { currency: 'KES', qty: '5 downloads', price: 3000 },
        { currency: '$', qty: '5 downloads', price: 5 },
      ],
      '48 Hours': [
        { currency: '₦', qty: '5 downloads', price: 8000 },
        { currency: 'KES', qty: '5 downloads', price: 6000 },
        { currency: '$', qty: '5 downloads', price: 15 },
      ],
    },
    'Customizable template': {
      'Full Access': [
        { currency: '₦', qty: '3 templates', price: 5000 },
        { currency: 'KES', qty: '3 templates', price: 3000 },
        { currency: '$', qty: '3 templates', price: 5 },
      ],
    },
  };



const PricePage = () => {
  const [billingCycle, setBillingCycle] = useState('month');
   const [product, setProduct] = useState('Legal database');
  const [access, setAccess] = useState('');
  const [downloads, setDownloads] = useState('');
  const [price, setPrice] = useState(0);

   const navigate = useNavigate();
   const user = useSelector((state) => state.auth.user);
   const selectedCurrency = useSelector((state) => state.currency.currency);

  const calculatePrice = (price, cycle) => {
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    if (cycle === 'week') {
      return `${selectedCurrency} ${(numericPrice / 4).toFixed(2)}`;
    } else if (cycle === 'year') {
      return `${selectedCurrency} ${(numericPrice * 12).toFixed(2)}`;
    }
    return `${selectedCurrency} ${numericPrice.toFixed(2)}`;
  };

  

 const filteredPlans = user
  ? plans.filter(plan => plan.name !== "Free" && plan.currency === selectedCurrency)
  : plans.filter(plan => plan.currency === selectedCurrency);


  

   const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setProduct(selectedProduct);
    setAccess('');
    setDownloads('');
  };

  const handleAccessChange = (e) => {
    const selectedAccess = e.target.value;
    setAccess(selectedAccess);
    setDownloads('');
  };

  const handleDownloadsChange = (e) => {
    const selectedDownloads = e.target.value;
    setDownloads(selectedDownloads);

        // Calculate the price based on the selected options
    let calculatedPrice = 0;
    if (product === 'Legal database') {
      if (access === '24 Hours' && selectedDownloads === '5') calculatedPrice = 5000;
      else if (access === '24 Hours' && selectedDownloads === '10') calculatedPrice = 8000;
      else if (access === '48 Hours' && selectedDownloads === '5') calculatedPrice = 8000;
      else if (access === '48 Hours' && selectedDownloads === '10') calculatedPrice = 10000;
    } else if (product === 'Customizable template') {
      if (access === 'Full Access' && selectedDownloads === '3') calculatedPrice = 5000;
      else if (access === 'Full Access' && selectedDownloads === '5') calculatedPrice = 8000;
      else if (access === 'Full Access' && selectedDownloads === '7') calculatedPrice = 10000;
      else if (access === 'Full Access' && selectedDownloads === '10') calculatedPrice = 13000;
    }

    setPrice(calculatedPrice);
  };

   
 const selectedPrice =
    downloads
      ? pricingData[product]?.[access]?.find(item => item.currency === selectedCurrency && item.qty === downloads)?.price || 'N/A'
      : '';

  // Mapping product and access options from pricingData
  const productOptions = Object.keys(pricingData);

  const accessOptions = product ? Object.keys(pricingData[product]) : [];

 const downloadOptions = access
  ? pricingData[product][access]
      .filter(item => item.currency === selectedCurrency) // Only show options for the selected currency
      .map(item => item.qty)
  : [];
  
  const handlePaymentClick = () => {
    const paymentData = { product, access, selectedPrice, selectedCurrency };
     localStorage.setItem("paymentData", JSON.stringify(paymentData));
    navigate('/payment');
  };

  const loginPage = () => {
  navigate('/sign-up');
  }



  const handlePaymentNow = (plan, billingCycle) => {
   
    const numericValue = calculatePrice(plan.price, billingCycle);
    console.log(numericValue);
    const amount = parseFloat(numericValue.replace(/[$,₦,KES]/g, ''));
    const paymentData = {
      product: plan.name,
      access: billingCycle,
      selectedPrice: amount,
      selectedCurrency: selectedCurrency,
    };
    console.log(paymentData);
    localStorage.setItem("paymentData", JSON.stringify(paymentData));
    // Navigate to the payment page with the payment data
    navigate('/payment');
  };



  const FeatureRow = ({ feature, values }) => (
    <tr  data-aos="fade-up">
      <td className="py-4 pr-4 font-medium border-b border-r border-gray-200">{feature}</td>
      {values.map((valueObj, index) => (
        <td
          key={index}
          className={`px-4 py-4 text-center border-b border-gray-200 ${
            valueObj.Enterprise ? "text-white bg-gray-900 border-white/20" : ""
          }`}
        >
          {typeof valueObj.value === "boolean" ? (
            valueObj.value ? (
              <svg
                className="w-5 h-5 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              "-"
            )
          ) : (
            valueObj.value
          )}
        </td>
      ))}
    </tr>
  );

  return (
    <div className='dark:bg-[#490800] dark:text-white '>
      <Navbar active={4} />
      <div className="w-[95%] mx-auto pt-5 px-6">
        <section className="py-10 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-xl mx-auto text-center">
              <h2 data-aos="zoom-in" className="text-3xl sm:text-5xl lg:text-6xl text-center mt-6 tracking-wide">
                Pricing{" "}
                <span className="text-[#A71200]">
                  & Plans
                </span>
              </h2>
              <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-gray-600">
                 Our tariff calculator shows you our prices and services. Discover the potential cost savings for your firm.
              </p>
            </div>
<div className='flex md:items-center w-[100%] flex-col md:flex-row gap-10'><div className="bg-white dark:bg-[#780D00] p-8 rounded-lg shadow-lg lg:w-[70%]">
        <h3 data-aos="fade-up" className="text-xl font-semibold mb-4">Calculate prices and benefits</h3>
        <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div >
            <label className="block font-medium mb-2">Products</label>
            <select
              className="w-full p-2 border border-gray-300 rounded  dark:text-[#000]"
              value={product}
              onChange={handleProductChange}
            >
              {productOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block font-medium mb-2">Access</label>
            <select
              className="w-full p-2 border border-gray-300 rounded  dark:text-[#000]"
              value={access}
              onChange={handleAccessChange}
              disabled={!product}
            >
              <option value="">Select Access</option>
              {accessOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block font-medium mb-2">Downloads</label>
            <select
              className="w-full p-2 border border-gray-300 rounded  dark:text-[#000]"
              value={downloads}
              onChange={(e) => setDownloads(e.target.value)}
              disabled={!access}
            >
              <option value="">Select Downloads</option>
              {downloadOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <h4 data-aos="fade-up" className="text-lg font-semibold mb-2">Your benefits</h4>
          <ul className="list-disc list-inside">
            <li data-aos="fade-up">Only one mandate can cover the costs for one year!</li>
            <li data-aos="fade-up">Time saving: Up to 60 hours per month</li>
            <li data-aos="fade-up">Wage cost savings: Up to €1,800 per month</li>
            <li data-aos="fade-up">Additional inclusive mandate acceptances (with annual payment)</li>
          </ul>
        </div>
      </div>

      <div data-aos="fade-up" className="bg-gray-100 dark:bg-[#780D00] p-8 rounded-lg shadow-lg mt-8 lg:w-[25%] lg:h-[310px]">
        <h3 className="text-xl font-semibold mb-4">Recommended tariff</h3>
        <p className="text-2xl font-bold">{product} - {access}</p>
        <p className="text-lg mb-4">{selectedCurrency} {selectedPrice}</p>

         {user ? (<button
        onClick={handlePaymentClick}
        className="mt-8 w-40 py-3.5 text-sm bg-purple-500 text-white rounded-md hover:bg-purple-600 tracking-wide"
      >
        Make Payment
      </button>) : (<button
        onClick={loginPage}
        className="mt-8 w-40 py-3.5 text-sm bg-purple-500 text-white rounded-md hover:bg-purple-600 tracking-wide"
      >
        Get Started
      </button>)}
        
      </div></div>
      

           

       <div className="max-w-xl mx-auto text-center pt-10">
              <h2 data-aos="fade-up" className=" mt-4 mb-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center">
                Tariffs in details{" "}
                <span clas  sName="text-[#A71200]">
                   
                </span>
              </h2>
              <p data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-gray-600">
               Our tariffs scale with the number of users and mandates as well as the required functions. Tip: If you pay annually, you get a higher inclusive quota for free.
              </p>
            </div>
            
             {/* Billing Cycle Dropdown */}
            <div  data-aos="fade-up" className="mt-8 flex justify-center">
              <select
                value={billingCycle}
                onChange={(e) => setBillingCycle(e.target.value)}
                className="px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring focus:border-[#A71200]"
              >
                <option value="week">Per Week</option>
                <option value="month">Per Month</option>
                <option value="year">Per Year</option>
              </select>
            </div>


            {/* Responsive Card View for Mobile and Tablet */}
            <div className="lg:hidden mt-10">
              {filteredPlans.map((plan, index) => (
                <div key={index} className="p-6 mb-6 bg-white dark:bg-[#780D00] border rounded-lg shadow-lg">
                  {plan.Enterprise && (
                    <span data-aos="fade-up" className="px-4 py-2 text-base font-medium text-white bg-gradient-to-r from-orange-500 to-orange-800 rounded-full flex items-center justify-center w-40 mx-auto">
                      Enterprise
                    </span>
                  )}
                  <h3 data-aos="fade-up" className="mt-4 text-2xl font-bold text-gray-900 text-center">{plan.name}</h3>
                  <p data-aos="fade-up" className="mt-2 text-4xl font-bold text-center">{calculatePrice(plan.price, billingCycle)}</p>
                  <p data-aos="fade-up" className="mt-2 text-base text-gray-500 text-center">Per {billingCycle}</p>

                  <ul className="mt-4 space-y-2">
                    <li 
                    data-aos="fade-up" 
                    className='flex items-center justify-between'>
                      <span>Legal Database: </span>
                      <span>{plan.features.database ? (<span>{plan.features.database || "Not included"}</span>) :("-")}</span>
                    </li>
                    <li data-aos="fade-up" className='flex items-center justify-between'><span>Customizable Template: </span><span>{plan.features.websites ? (<span>{plan.features.websites || "Not included"}</span>) :("-")}</span></li>
                    <li data-aos="fade-up" className='flex items-center justify-between'><span>Vault: </span><span>{plan.features.storage}</span></li>
                    <li data-aos="fade-up" className='flex items-center justify-between'><span>Toma AI: </span><span>{plan.features.serverSpeed ? (
                      <svg
                        className="inline w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      "-"
                    ) || "Not included"}</span></li>
                    {/* <li className='flex items-center justify-between'><span>Server Speed: </span><span>{plan.features.serverSpeed ? (
                      <svg
                        className="inline w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      "-"
                    )}</span></li> */}
                  </ul>

                  {/* Get Started Button Row */}
                 {plan.currency === selectedCurrency && (
  user ? (
    <div className="mt-6 text-center">
      <div
        data-aos="fade-up"
        onClick={() => handlePaymentNow(plan, billingCycle)}
        className={`inline-flex items-center cursor-pointer justify-center px-6 py-2 font-semibold text-[#000] bg-white border rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:text-white ${
          plan.Enterprise ? "bg-gradient-to-r from-orange-500 to-orange-800 text-white" : ""
        }`}
      >
        Make Payment
        <svg
          className="w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 10H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  ) : (
    <div className="mt-6 text-center">
      <Link
        data-aos="fade-up"
        to="/sign-up"
        className={`inline-flex items-center justify-center px-6 py-2 font-semibold text-[#000] bg-white border rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:text-white ${
          plan.Enterprise ? "bg-gradient-to-r from-orange-500 to-orange-800 text-white" : ""
        }`}
      >
        Get Started
        <svg
          className="w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 10H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
    </div>
  )
)}


                  
                </div>
              ))}
            </div>

            {/* Comparison Table for Desktop */}
            <div className="hidden lg:block mt-10 overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <table className="min-w-full border border-gray-200 divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="py-3 pr-4 text-base font-medium text-left text-gray-500 dark:text-white border-r border-gray-200 "></th>
                      {filteredPlans.map((plan, index) => (
                        <th  data-aos="fade-up"
                          key={index}
                          className={`px-4 py-3 text-lg font-medium text-center ${
                            plan.Enterprise
                              ? "bg-gradient-to-r from-orange-500 to-orange-800 text-white border-white/20"
                              : "text-gray-900 dark:text-white"
                          }`}
                        >
                          {plan.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-[#780D00]">
                    <tr  data-aos="fade-up">
                      <td  className="py-4 pr-4 font-medium text-left border-b border-r border-gray-200 ">Pricing</td>
                      {filteredPlans.map((plan, index) => (
                        <td
                          key={index}
                          className={`px-4 py-4 text-center font-semibold border-b border-gray-200 ${
                            plan.Enterprise ? "text-white bg-gray-900 border-white/20" : ""
                          }`}
                        >
                          {calculatePrice(plan.price, billingCycle)}
                        </td>
                      ))}
                    </tr>
                    <tr  data-aos="fade-up">
                      <td className="py-4 pr-4 font-medium text-left border-b border-r border-gray-200">
                        Per {billingCycle}
                      </td>
                      {filteredPlans.map((plan, index) => (
                        <td
                          key={index}
                          className={`px-4 py-4 text-center border-b border-gray-200 ${
                            plan.Enterprise ? "text-white bg-gray-900 border-white/20" : ""
                          }`}
                        >
                          {billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1)}
                        </td>
                      ))}
                    </tr>
                    <FeatureRow
                      feature="Legal Database"
                      values={filteredPlans.map((plan) => ({ value: plan.features.database, Enterprise: plan.Enterprise }))}
                    />
                    <FeatureRow
                      feature="Customizable Template"
                      values={filteredPlans.map((plan) => ({ value: plan.features.websites, Enterprise: plan.Enterprise }))}
                    />
                    <FeatureRow
                      feature="Vault"
                      values={filteredPlans.map((plan) => ({ value: plan.features.storage, Enterprise: plan.Enterprise }))}
                    />
                    <FeatureRow
                      feature="Toma AI"
                      values={filteredPlans.map((plan) => ({ value: plan.features.serverSpeed, Enterprise: plan.Enterprise }))}
                    />
                    {/* <FeatureRow
                      feature="Server Speed"
                      values={plans.map((plan) => ({ value: plan.features.serverSpeed, Enterprise: plan.Enterprise }))}
                    /> */}

                    {/* Get Started Button Row */}
                    {user ? (
  <tr>
    <td className="py-4 pr-4 font-medium text-left border-b border-r border-gray-200"></td>
    {filteredPlans
      .filter((plan) => plan.currency === selectedCurrency) // Filter by selectedCurrency
      .map((plan, index) => (
        <td
          key={index}
          className={`px-4 py-4 text-center border-b border-gray-200 ${
            plan.Enterprise ? "text-white bg-gray-900 border-white/20" : ""
          }`}
        >
          <div
            onClick={() => handlePaymentNow(plan, billingCycle)}
            className={`inline-flex items-center cursor-pointer justify-center px-6 py-2 font-semibold text-[#000] bg-white border rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:text-white ${
              plan.Enterprise ? "bg-gradient-to-r from-orange-500 to-orange-800 text-white" : ""
            }`}
          >
            Make Payment
            <svg
              className="w-4 h-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 10H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </td>
      ))}
  </tr>
) : (
  <tr>
    <td className="py-4 pr-4 font-medium text-left border-b border-gray-200"></td>
    {plans
      .filter((plan) => plan.currency === selectedCurrency) // Filter by selectedCurrency
      .map((plan, index) => (
        <td
          key={index}
          className={`px-4 py-4 text-center border-b border-gray-200 ${
            plan.Enterprise ? "text-white bg-gray-900 border-white/20" : ""
          }`}
        >
          <Link
            to="/sign-up"
            className={`inline-flex items-center justify-center px-6 py-2 font-semibold text-[#000] bg-white border rounded-lg hover:bg-gradient-to-r from-orange-500 to-orange-800 hover:text-white ${
              plan.Enterprise ? "bg-gradient-to-r from-orange-500 to-orange-800 text-white" : ""
            }`}
          >
            Get Started
            <svg
              className="w-4 h-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 10H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </td>
      ))}
  </tr>
)}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PricePage;
