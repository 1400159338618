import React from 'react'
import styles from "../../../styles/styles";
import ai from "../../../Assests/Image/ai.png";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const TomaAi = () => {
  const navigate = useNavigate();
  
    const user = useSelector((state) => state.auth.user);


    const loginPage = () => {
  navigate('/sign-up');
  }

   const paymentPage = () => {
     navigate('/price');
  }
  return (
    <div className={`${styles.section}`}>
      <div lassName={`${styles.heading}`}>
        <div className="md:flex md:items-center md:justify-around mt-20 mb-20">
          <div  data-aos="zoom-in" className="md:w-[40%] flex items-center justify-center">
            <img src={ai} alt="ai" />
          </div>
          <div className="md:w-[40%]">
            <h1  data-aos="fade-up" className="lg:text-5xl text-3xl mt-10 md:mt-0 lg:leading-normal font-semibold text-black">
             
            </h1>
            <p  data-aos="fade-up" className="mt-5">
              With TOMA's free version, gain instant access to answers for your
              everyday legal inquiries. Our advanced AI technology provides
              clear and concise responses to your questions, empowering you to
              navigate legal complexities with ease.
            </p>{" "}
            <p  data-aos="fade-up" className="mt-5">
              Upgrade to TOMA's paid version for unparalleled document analysis
              capabilities. Simply upload your documents, and our AI engine will
              meticulously analyze each detail, providing insights, highlighting
              key points, and flagging potential issues. Rest assured, all
              documents uploaded are securely stored in TOMA Vault, ensuring
              confidentiality and compliance.
            </p>

            {user ? (<button  data-aos="fade-up" onClick={paymentPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
              Learn More
            </button> ) : (
<button  data-aos="fade-up" onClick={loginPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
              Learn More
            </button>
            )};
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default TomaAi