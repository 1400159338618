// agreementTemplates.js

// Business Consultant Agreement
export const generateBusinessConsultantAgreement = (data) => {
  return `
    BUSINESS CONSULTANT AGREEMENT
    ----------------------------------

    This agreement dated ${data.todaysDate},  is made By and Between ${data.companyName}, whose address is ${data.companyAddress}, referred to as "Company", AND ${data.consultantName}, whose address is ${data.consultantAddress}, referred to as "Consultant".

    1. Consultation Services
    -------------------------
    The company hereby employs the consultant to perform the following services in accordance with the terms and conditions set forth in this agreement: 
     ${data.servicesProvided}.
     The consultant will consult with the officers and employees of the company concerning matters relating to the management and organization of the company, their financial policies, the terms and conditions of employment, and generally any matter arising out of the business affairs of the company.
     ${data.interaction}.

    2. Terms of Agreement
    -------------------------
    This agreement will begin ${data.startDate} and will end ${data.endDate}. Either party may cancel this agreement on ${data.cancellationNoticePeriod} days notice to the other party in writing, by certified mail or personal delivery

    3. Time Devoted by Consultant
    ------------------------------
     It is anticipated the consultant will spend approximately  ${data.timeDedication} in fulfilling its obligations under this contract. The particular amount of time may vary from day to day or week to week. However, the consultant shall devote a minimum of ${data.workHours} per month to its duties in accordance with this agreement

     4. Place Where Services Will Be Rendered. 
     -----------------------------------------
     The consultant will perform most services in accordance with this contract at ${data.serviceLocation}. In addition the consultant will perform services on the telephone and at such other places as designated by the company to perform these services in accordance with this agreement.
     
     5. Payment to Consultant. 
     -------------------------
     The consultant will be paid at the rate of ${data.paymentRate} for work performed in accordance with this agreement. However, the consultant will be paid at least  ${data.minimumMonthlyPayment} per month regardless of the amount of time spent in accordance with this agreement. The consultant will submit an itemized statement setting forth the time spent and services rendered, and the company will pay the consultant the amounts due as indicated by statements submitted by the consultant within ${data.invoiceFrequency} of receipt.
     
     6. Independent Contractor.
     --------------------------
     Both the company and the consultant agree that the consultant will act as an independent contractor in the performance of its duties under contract. Accordingly, the consultant shall be responsible for payment of all taxes including Federal, State and local taxes arising out of the consultant's activities in accordance with this contract, including by way of illustration but not limitation, Federal and State income tax, Social Security tax, Unemployment Insurance taxes, and any other taxes or business license fee as required.
     
     7. Confidential Information. 
     ----------------------------
     The consultant agrees that any information received by the consultant during any furtherance of the consultant's obligations in accordance with this contract, which concerns the personal, financial or other affairs of the company will be treated by the consultant in full confidence and will not be revealed to any other persons, firms or organizations.

      8. TERMINATION
    -------------------------
    Either party may terminate this Agreement upon providing ${data.terminationNotice} days' written notice. In the event of early termination by either party, there will be no penalty unless otherwise agreed in writing by both parties.
     
     8. Employment of Others. 
     -------------------------
     The company may from time to time request that the consultant arrange for the services of others. ${data.thirdPartyHiring}. All costs to the consultant for those services will be paid by the company but in no event shall the consultant employ others without the prior authorization of the company.

     9. Signatures. 
     ----------------
     Both the company and the consultant agree to the above contract. 
     Signed by:
     
     ---------------------------------
     ${data.companyName} (Company Representative)
     
     
     ---------------------------------
     ${data.consultantName} (Consultant)

     NOTICE
     The information in this document is designed to provide an outline that you can follow when formulating business or personal plans. Due to the variances of many local, city, county and state laws, we recommend that you seek professional legal counseling before entering into any contract or agreement.
  `;
};

// Non-Disclosure Agreement (NDA)
// export const generateNDAAgreement = (data) => {
//   return `
//     NON-DISCLOSURE AGREEMENT (NDA)
//     ----------------------------------

//     THIS AGREEMENT ("Agreement") is made and entered into on ${data.date}, by and between:
    
//     1. ${data.discloserName}, with its principal place of business located at ${data.discloserAddress}, hereinafter referred to as the "Discloser",

//     AND
    
//     2. ${data.recipientName}, with its principal place of business located at ${data.recipientAddress}, hereinafter referred to as the "Recipient".
    
//     WHEREAS, the Discloser possesses certain proprietary and confidential information, which the Recipient agrees to keep confidential under the terms of this Agreement.

//     NOW, THEREFORE, in consideration of the promises and mutual covenants contained herein, the parties hereto agree as follows:

//     1. DEFINITION OF CONFIDENTIAL INFORMATION
//     -----------------------------------------
//     For purposes of this Agreement, "Confidential Information" shall include, but not be limited to:
//     - ${data.confidentialInformation} (e.g., business plans, client data, product details).

//     Exclusions: Confidential Information shall not include information that is:
//     - ${data.excludedInformation} (e.g., publicly known, or information disclosed by a third party without breach of any obligation).

//     2. PURPOSE OF DISCLOSURE
//     -------------------------
//     The Confidential Information shall be used by the Recipient for the following purpose only:
//     - ${data.purposeOfSharing}.

//     3. NON-DISCLOSURE OBLIGATIONS
//     -------------------------
//     The Recipient agrees to protect the confidentiality of the Discloser's Confidential Information and:
//     - Shall not disclose any Confidential Information to any third party without the prior written consent of the Discloser.
//     - Shall use the Confidential Information only for the purpose set forth in this Agreement.
//     - Shall take reasonable steps to prevent any unauthorized access to or disclosure of the Confidential Information.
//     - Upon termination or completion of this Agreement, the Confidential Information shall either be ${data.postUsageInformation} (e.g., destroyed or returned).

//     4. LEGAL OBLIGATIONS & DISCLOSURE
//     ---------------------------------
//     If the Recipient becomes legally compelled to disclose any Confidential Information, the Recipient shall:
//     - Notify the Discloser immediately in writing of such disclosure requirement.
//     - Provide the Discloser with reasonable assistance, at Discloser’s expense, in obtaining an appropriate protective order.
//     - ${data.awarenessResponse} if made aware of any disclosure or threat of disclosure of the Confidential Information.

//     5. DISCLOSURE TO THIRD PARTIES
//     -------------------------------
//     The Recipient may disclose Confidential Information to third parties under the following conditions:
//     - Disclosure is permitted only if ${data.thirdPartyDisclosureConditions} (e.g., written consent is obtained).

//     6. PENALTIES FOR BREACH
//     -----------------------
//     If the Recipient breaches this Agreement, the following penalties will apply:
//     - ${data.penaltyForBreach} (e.g., financial penalties, legal fees).

//     7. AMENDMENTS
//     --------------
//     This Agreement may only be amended or modified by a written instrument executed by both parties.

//     Any additional amendments include:
//     - ${data.additionalAmendments}.

//     8. TERM AND TERMINATION
//     -----------------------
//     This Agreement shall commence on ${data.date} and shall remain in effect until ${data.terminationDate}, unless terminated earlier by either party upon providing ${data.terminationNoticePeriod} days’ notice in writing.

//     9. MISCELLANEOUS
//     ----------------
//     This Agreement constitutes the entire understanding between the parties with respect to the subject matter hereof and supersedes all prior discussions, negotiations, and agreements.

//     IN WITNESS WHEREOF, the parties have executed this Agreement as of the day and year first above written.

//     Signed by:
//     ---------------------------------
//     ${data.discloserName} (Discloser)

//     ---------------------------------
//     ${data.recipientName} (Recipient)
//   `;
// };

export const generateNDAAgreement = (data) => {
  return `

    NON-DISCLOSURE AGREEMENT
    ${data.agreementDate}
    ${data.discloser}, hereinafter referred to as the “Discloser”, and ${data.recipient}, hereinafter referred to as the “Recipient”, on the other hand, jointly referred to as the “Parties”, individually referred to as a “Party”, have concluded this Non-disclosure agreement (hereinafter referred to as the “Agreement”) as follows:

    DEFINITION OF TERMS
    --------------------
    The following terms referred to in this Agreement shall have the following meanings:
    Confidential Information means all written and oral information and materials disclosed or provided by the Discloser to the Recipient under this Agreement regardless of whether such information was provided before or after the date of this Agreement or how it was provided to the Recipient. The Confidential information constitutes of all data and information relating to the product or products of the Discloser as well as all the data and information relating to the Discloser, including but not limited to intellectual property, product information, customer information, business operations, marketing and development information, etc.
    Confidential Information shall not include the following information:
      any information which is or in future comes into the public domain (unless as a result of the breach of this Agreement); 
      any information which is already known to the Recipient and which was not subject to any obligation of confidence before it was disclosed to the Recipient by the Discloser; 
      any information that the Recipient rightfully obtains from a third party that has a right to transfer or disclose it. 

    Subject of the Agreement means negotiations and interaction between the Parties within the framework of analysis and processing of Confidential Information by the Recipient, preparation of any kind of documents based on or using Confidential Information.
    
    NON-DISCLOSURE OBLIGATIONS
    --------------------------  
    In order to ensure the confidentiality of the Confidential Information provided by the Discloser, the Recipient shall: 
    Keep confidential and take all possible actions (no less than those actions that he takes to protect his information) to protect confidentiality and prevent unauthorized use, disclosure, publication or distribution of Confidential Information;
    Not to use the Confidential Information for the purpose of obtaining own benefit or gaining benefit by a third party, or for any other purpose other than the Subject of the Agreement;
    Not to disclose Confidential Information to any third party without prior written agreement with the Discloser;
    Not to copy Confidential Information in any form, unless such copying is necessary to fulfil obligations under the Subject of the Agreement;
    ${data.confidentialDefinition} all the Confidential information in written or any other form, as well as any documents and materials containing Confidential Information upon the request of the Discloser, and not to retain any copies or records of Confidential Information.
    
    The Recipient may disclose Confidential Information in the following cases:
    ${data.confidentialInformationTypes}
    The Recipient is obliged to disclose Confidential Information to the extent required by law or by the request or requirement of any judicial, legislative, administrative or other governmental body, in case such disclosure is mandatory for the Recipient in accordance with the laws of the state under whose jurisdiction it is at the time of receipt of the disclosure requirement. In this case, the Recipient shall give to the Discloser prompt written notice of such request and take all measures to limit the disclosure of Confidential Information. ${data.additionalCategories}
    The Recipient agrees to retain all Confidential Information at its usual place of business and to store all Confidential Information separate from other information and documents held in the same location. Further, the Confidential Information may not be used, reproduced, transformed, or stored on a computer or device that is accessible to persons to whom disclosure may not be made, as set out in this Agreement.
    The Recipient shall ${data.additionalCategories} once it became aware of the disclosure or threat of disclosure, the unlawful obtaining or unlawful use of Confidential Information by third parties.
    ${data.thirdPartyInformation}

    RESPONSIBILITY OF THE PARTIES
    -----------------------------
    The Recipient is liable for all costs, expenses and expenditures including and without limitation, the complete legal costs incurred by the Discloser in enforcing the present Agreement as a result of any default or breach of this Agreement by the Recipient.
    The Recipient shall 7 (AUTOFILL) the Discloser of the unauthorized disclosure of Confidential Information or other violation of this Agreement by the Recipient. If this condition is not met, the Recipient shall pay the fine of 9 in addition to the costs specified in clause 3.1. of the present Agreement.

    OTHER TERMS
    -----------
    All Confidential Information is the property of the Discloser. Neither this Agreement nor the supply of any information grants the Recipient any licence interest or right in respect of any intellectual property rights of the Discloser.
    This Agreement is governed by, and is to be construed in accordance with, the law of Ireland. The Parties undertake to make all necessary measures to resolve possible disputes through negotiations. In case the Parties do not come to an agreement as a result of negotiations the disputes shall be submitted to the court as prescribed by the legislation of Ireland.
    The obligations to ensure and protect the confidentiality of the Confidential Information imposed on the Recipient in this Agreement and any obligations to provide notice under this Agreement will survive the expiration and termination, as the case may be, of this Agreement and those obligations will last indefinitely.
    This Agreement is made and signed in two copies in English having identical validity, and shall come into force from the date of its signing and valid indefinitely.
    The terms and obligations of the present Agreement may be changed only by 10. All amendments and additions to this Agreement are valid if they are made in writing and signed by both Parties.
    All notices and other documents of the Parties under the present Agreement shall be made in writing, and will be deemed delivered when hand-delivered, delivered by agent or delivered by registered mail with a description of the attachment to the Parties at the addresses contained in paragraph 5 of the Agreement or as the Parties may later designate in writing.

    ADDITIONAL CLAUSE 11
    
    LEGAL ADDRESSES AND SIGNATURES OF THE PARTIES
    
    ${data.discloser} (Discloser)	

    Address: ${data.discloserAddress}

    Signed: --------------------------------- 

    Date: ${data.agreementDate}


    ${data.recipient} (Recipient)

    Date:  ---------------------------------

    Address:  ---------------------------------

    Signed: ---------------------------------
  `;
};

export const generateTenancyAgreement = (data) => {
  return `
    TENANCY AGREEMENT
    ----------------------------------

    THIS TENANCY AGREEMENT is made this ${data.agreementDate}, between:
    
    1. ${data.landlordPertains}, of ${data.landlordAddress}, hereafter referred to as the "${data.landlordName}" (which expression shall, where the context so admits, include their heirs, legal representatives, and assigns),

    AND

    2. ${data.tenantName}, of ${data.tenantAddress}, hereinafter referred to as the "Tenant" (which expression shall, where the context so admits, include their heirs, legal representatives, and assigns).

    WHEREAS:
    -------------------------
    The ${data.landlordPertains} is the OWNER/CUSTODIAN of the property located at ${data.propertyAddress}, being a ${data.propertyType} with appurtenances thereto.

    The Tenant has approached the ${data.landlordPertains} for the letting of the said ${data.propertyType} for ${data.leaseDuration}, and the ${data.landlordPertains} has agreed to let the property to the Tenant in accordance with the terms and conditions set forth in this agreement.

    NOW THIS AGREEMENT WITNESSES AS FOLLOWS:
    -------------------------
    1. LEASE AND RENT
    -------------------------
    The ${data.landlordPertains} hereby demises unto the Tenant the aforementioned property TO HOLD the same for a term of ${data.leaseDuration}, commencing on ${data.leaseStartDate}, and paying thereof the yearly rent of ${data.rentAmount} (inclusive of service charge), to be paid in advance in ${data.rentPaymentMethod} installments of ${data.amountInWords}.

    Additional Clause: ${data.additionalClause}

    2. TENANT'S COVENANTS
    -------------------------
    The Tenant hereby covenants with the ${data.landlordPertains} as follows:
    i. To bear and discharge all utility bills and charges related to the property during the term of this lease.
    ii. Not to make any structural changes to the property without the prior written consent of the ${data.landlordPertains}.
    iii. To keep the interior of the property, including fixtures and fittings, in good and tenantable condition. Any damage or destruction of fixtures shall be replaced by the Tenant with equivalent value.
    iv. To carry out internal redecoration of the property to a reasonable standard before vacating and surrendering the property to the ${data.landlordPertains}.
    v. Not to assign, sublet, or otherwise part with the possession of the property without the prior written consent of the ${data.landlordPertains}.
    vi. To pay a caution/security deposit of ${data.securityDepositAmount} (${data.depositPercentage} of annual rent).
    vii. To peaceably surrender the property to the ${data.landlordPertains} at the expiration or sooner determination of the term.
    viii. Not to keep any inflammable products in the premises or engage in acts capable of causing fire or explosion. If violated, the Tenant agrees to indemnify the ${data.landlordPertains} for any damages and carry out repairs at the Tenant's expense.

    3. ${data.landlordPertains}'S COVENANTS
    -------------------------
    The ${data.landlordPertains} hereby covenants with the Tenant that, provided the rent is paid and the Tenant observes the terms of this agreement, the Tenant may peaceably hold and enjoy the property without interruption by the ${data.landlordPertains} or any person claiming under the ${data.landlordPertains}.

    4. MUTUAL AGREEMENTS
    -------------------------
    i. If the Tenant wishes to renew this lease, they must notify the ${data.landlordPertains} in writing at least ${data.renewalNoticePeriod} days before the expiration of the current lease term. If there is no breach of any covenant, a new lease may be agreed upon.
    ii. Either party may terminate this lease by giving the other party at least ${data.terminationNoticePeriod} days' written notice.
    iii. If the Tenant terminates the lease before the expiration date, the Tenant shall ${data.penaltyForEarlyTermination}.

    Additional Clause: ${data.additionalClauseTermination}

    IN WITNESS WHEREOF, the parties have executed this agreement as of the day and year first above written.

    SIGNED by the within-named ${data.landlordName} (Landlord)
    ---------------------------------
    Name: ${data.landlordName}
    
    In the presence of:
    Name:  __________________________
    Address:  __________________________
    Occupation:  __________________________
    Signature: __________________________

    SIGNED by the within-named ${data.tenantName} (Tenant)
    ---------------------------------
    Name: ${data.tenantName}
    
    In the presence of:
    Name:  __________________________
    Address:  __________________________
    Occupation:  __________________________
    Signature: __________________________
  `;
};

// export const generateMOUAgreement = (data) => {
//   return `
//     MEMORANDUM OF UNDERSTANDING (MOU)
//     ----------------------------------

//     THIS MEMORANDUM OF UNDERSTANDING was made on ${data.date}, by and between:
    
//     1. ${data.party1Name}, whose address is ${data.party1Address} (hereinafter referred to as "Party 1"),
    
//     AND

//     2. ${data.party2Name}, whose registered office is at ${data.party2OfficeAddress} (hereinafter referred to as "Party 2").

//     WHEREAS:
//     -------------------------
//     Party 1 agrees to perform the following services under this agreement:
//     - ${data.party1Services}.

//     The title of this engagement is: ${data.purposeOfEngagement}.

//     1. PROJECT TIMELINE
//     -------------------------
//     The project shall commence on ${data.projectStartDate} and is estimated to be completed by ${data.estimatedCompletionDate}. This timeline is subject to adjustment based on the following factors: ${data.timelineAdjustmentFactors}.

//     2. MILESTONES
//     -------------------------
//     The project will be divided into distinct milestones: ${data.milestones}.

//     3. CONFIDENTIALITY
//     -------------------------
//     All parties agree to keep the following information confidential: ${data.confidentialInformation}.
//     The following employees or agents are subject to the confidentiality clause: ${data.confidentialEmployees}.
    
//     Additional Clause: ${data.additionalClause}

//     4. DEADLOCK RESOLUTION
//     -------------------------
//     A deadlock shall be considered to have occurred under the following conditions: ${data.deadlockDefinition}.
//     Parties will declare a deadlock after ${data.daysAfterMeeting} days post-meeting. If a Deadlock Resolution Notice is served, the recipient must respond within ${data.deadlockNoticeResponse} days.

//     5. TERMINATION
//     -------------------------
//     This agreement may be terminated if a breach is not rectified within ${data.terminationNoticeDays} days.
//     It shall terminate immediately if a resolution is passed to ${data.liquidatorTerminationClause}.

//     6. NOTICES
//     -------------------------
//     Notices shall be sent to the following contact: ${data.noticeContactDetails}.
//     Notices posted in Nigeria will be deemed served after ${data.noticeInNigeria} hours. For outside Nigeria, it will be served after ${data.noticeOutsideNigeria} days.

//     Signed by both parties on the above date.
//   `;
// };
export const generateMOUAgreement = (data) => {
  return `
    THIS MEMORANDUM OF UNDERSTANDING was made the ${data.agreementDate}
    BETWEEN:
    ${data.party1Name} whose address is at ${data.party1Address} ,
    and
    ${data.party2Name} whose proposed registered office is at ${data.party2Address}  
    WHEREAS
    This agreement shall be in force from the date of its execution by the parties and shall remain valid until the incorporation of the proposed company provided the terms of this agreement are incorporated in the Memorandum and Articles of Association of the proposed company when formed.
    If any term of this agreement is excluded from the Memorandum and Articles of Association of the proposed company when formed, the parties to this agreement agree to pass the necessary resolution ratifying the terms of this agreement once the proposed company is formed.

    IT IS AGREED as follows:
    The ${data.party1Name} and ${data.party2Name} agrees to contribute to the project by performing the following services:
    * ${data.servicesContributed}
    * ________________________________________________________________________
    * ________________________________________________________________________
    * ________________________________________________________________________
    * ________________________________________________________________________
    
    Interpretation
    In this agreement unless the context otherwise requires:
    ‘the Board’ means the board of directors of the proposed Company 
    ‘Financial Year’ means any accounting reference period of the proposed Company
    ‘Subsidiary’ and ‘Holding Company’ have the same respective meanings as in the 
    Companies and Allied Matters Act 2020 Section 381

    References to statutory provisions shall be construed as references to those provisions as respectively amended or re-enacted or as their application is modified by other provisions (whether before or after the date of this agreement) from time to time and shall include any provisions of which they are re-enactments (whether with or without modification)
    The headings are inserted for convenience only and shall not affect the construction of this agreement
    This Agreement shall remain in force from __7_________ until ____________8______________________. This Agreement shall be                      superseded by the governing documents of the Company, including but not limited to the Memorandum and Articles of Association.

    The provisions of this agreement shall be incorporated in the governing documents of the proposed Company when formed, including but not limited to the Memorandum and Articles of Association.

    Timeline Clause:
    -------------------
    ____9_______shall commence on ${data.projectStartDate} and is estimated to be completed by ${data.completionDate}. This timeline is subject to adjustment based on ______12_______. 
    Milestones: The project will be divided into distinct milestones, ${data.keyMilestones}.
    Change Management: Any proposed changes to the project scope or timeline must be communicated in writing and approved by the parties. Changes may impact the project schedule and budget and will be assessed accordingly.
    Communication: The parties agree to maintain open and transparent communication. Regular status updates will be provided to keep all parties informed of progress and any potential deviations from the timeline.

    Confidentiality
    -------------------
    4.1 Each of the parties to this agreement shall at all times use its best endeavours to keep confidential (and to procure that its respective employees and agents shall keep confidential) any confidential information which it or they may acquire in relation to _____14____________________
    The parties to this agreement shall procure that ______15_____________________ , each of them observe a similar obligation of confidence in favour of the parties to this agreement.

    The obligations of each of the parties contained in clause 1.1 shall continue without limit in point of time but shall cease to apply to any information coming into the public domain otherwise than by breach by any such party of its said obligations Provided that nothing contained in this clause 4 shall prevent any party from disclosing any such information to the extent required in or in connection with legal proceedings arising out of this agreement or any matter relating to or in connection with the proposed parties and companies 
    
    For this clause 4 the expression ‘party’ shall include the Subsidiary companies of any party and any other company Controlled by that party and the employees or agents of that party and of such Subsidiary or Controlled companies
    
    ADDITIONAL CLAUSE __16___

    Deadlock resolution
    -----------------------
    For this clause 5 deadlock shall be deemed to have occurred if:
    5.1:1 ${data.deadlockResolution}

    5:1:2 A quorum is not present at two successive Board meetings (or proposed Board meetings) duly convened In the event of deadlock:

    5.2:1 either party (‘the Server’) shall be entitled [at any time thereafter or within ${data.deadlockNoticePeriod} days of the notice 

    5.2:2 Within __18 (AUTOFILL)______ days of service of a Deadlock Resolution Notice the Recipient shall by notice to  the Server (‘Counter Notice’) be entitled to ${data.deadlockConsequences}. If no Counter Notice is served by the Recipient on the Server within the period referred to the Recipient shall be deemed to have accepted the offer contained in the Deadlock Resolution Notice.

    Termination
    --------------
    This Agreement shall continue in full force and effect until it is terminated by the provisions    of this clause
    
    Any of the parties to this agreement shall be entitled to terminate this agreement immediately by notice in writing if any of the events set out below shall occur. Such notice shall be served upon the party in respect of which the event or events relate (‘Such Other Party’) and copies of such notice shall be given to all other parties to this agreement (if any). The effect of such notice shall be to terminate this agreement as between Such Other Party and the remaining party or parties to this agreement but this agreement shall continue in full force and effect as between such remaining parties (if more than one) but not if otherwise. The said events are:

    6.2:1	if Such Other Party shall commit any [material] breach of any of its obligations under this agreement and shall fail to remedy such breach (if capable of remedy) within ${data.terminationNoticePeriod} days after being given notice by the first party so to do or 
    
      This agreement shall terminate in respect of any party to this agreement (but shall continue between the other parties to this agreement (if more than one) but shall continue between the other parties to this agreement (if more than one) but not otherwise)
      This agreement shall terminate immediately if an effective resolution is passed to ${data.terminationEvents} or if a liquidator is otherwise appointed (but without prejudice to any rights any party may have against any other arising before such termination)
      
    Supremacy and general covenants
    ----------------------------------
    This agreement is not intended to override the provisions of the memorandum or articles of association of the proposed Company when formed and the parties hereto shall exercise all voting and other rights and powers available to incorporate all the provisions of this agreement in the memorandum. 

    General
    ---------
    This agreement shall be binding upon the parties to this agreement and their respective successors and permitted assigns Provided that none of the parties to this agreement shall be entitled to ${data.successorsClause}
    
    No exercise or failure to exercise or delay in exercising any right power or remedy vested in any party under or pursuant to this agreement shall constitute a waiver by that party of that or any other right power or remedy
    
    No party to this agreement shall be entitled to make or permit or authorise the making of any press release or other public statement or disclosure concerning this agreement or any of the transactions contemplated in it without the prior written consent of the other party or parties.

    This agreement constitutes the entire agreement between the parties in relation to its subject matter and supersedes all prior agreements and understandings whether oral or written with respect to such subject matter and no variation of this agreement shall be effective unless reduced to writing and signed by or on behalf of a duly authorised representative of each of the parties

    In the event that any term condition or provision of this agreement is held to be a violation of any applicable law statute or regulation the same shall be deemed to be deleted from this agreement and shall be of no force and effect and this agreement shall remain in full force and effect as if such term condition or provision had not originally been contained in this agreement.
    
    Notwithstanding the above in the event of any such deletion the parties shall negotiate in good faith in order to agree the terms of a mutually acceptable and satisfactory alternative provision in place of the provision so deleted

    7.7	Time shall be of the essence for any provision of this agreement
    --------------------------------------------------------------------
    Notices
    Any notice to be given by any party to this agreement shall be in writing and shall be deemed duly served if delivered personally or sent by telex or facsimile transmission or by prepaid registered post (airmail in the case of an address for service outside Nigeria) to the addressee at the address or (as the case may be) the telex or facsimile number of that party set opposite its name below:
       ${data.contactDetails}
    
    Any notice sent by email shall be deemed served when despatched and any notice served by prepaid registered post shall be deemed served ${data.noticeServedHours} hours after posting to an address in Nigeria or ${data.noticeServedDays} days after posting to an address outside Nigeria. In proving the service of any notice it will be sufficient to prove in the case of a letter that such letter was properly stamped addressed and placed in the post or delivered or left at the current address if delivered personally and in the case of a telex or facsimile that such telex or facsimile transmission was duly despatched to the telex or facsimile number of the addressee given above or subsequently notified for the purposes of this agreement 
    
    Law and jurisdiction
    -----------------------
    This agreement shall be governed by and construed in all respects in accordance with Nigerian law

    In relation to any legal action or proceedings to enforce this agreement or arising out of or in connection with this agreement (‘Proceedings’) each of the parties irrevocably submits to the jurisdiction of the Nigerian courts and waives any objection to Proceedings in such courts on the grounds of venue or on the grounds that the Proceedings have been brought in an inconvenient forum

    AS WITNESS etc
    
    
    Name: ______________________
    
    Signed:----------------------------------
  `;
};


// export const generatePreIncorporationAgreement = (data) => {
//   return `
//     PRE-INCORPORATION AGREEMENT
//     ------------------------------

//     THIS AGREEMENT is made on ${data.date}, by and between:

//     1. ${data.party1Name}, hereinafter referred to as "Promoter 1", 

//     AND 

//     2. ${data.party2Name}, hereinafter referred to as "Promoter 2".

//     WHEREAS:
//     -------------------------
//     The Promoters wish to establish a company under the name ${data.companyName}, with an issued share capital of ${data.shareCapital}.

//     The Company will issue ${data.sharesIssued} shares at a value of ${data.valuePerShare} per share, which will be distributed as follows: ${data.sharesDistribution}.

//     NOW, THEREFORE, the parties agree as follows:

//     1. SHARE SUBSCRIPTION
//     -------------------------
//     The shareholding percentages after incorporation shall be as follows:
//     - Promoter 1: ${data.shareholdPercentageParty1}%
//     - Promoter 2: ${data.shareholdPercentageParty2}%

//     2. BUSINESS SCOPE
//     -------------------------
//     The scope of the business of the proposed company shall be ${data.businessScope}.
//     ${data.expansionPlans ? `The promoters plan to expand the company ${data.expansionPlans}.` : ''}
//     Additional clauses: ${data.additionalClauses}

//     3. PROJECT TIMELINE
//     -------------------------
//     The project shall commence on ${data.projectStartDate} and is estimated to be completed by ${data.completionDate}.
//     Key milestones for this project include: ${data.projectMilestones}.

//     4. FINANCIAL RESPONSIBILITIES
//     -------------------------
//     Each promoter shall be responsible for the following financial contributions:
//     - Promoter 1: ${data.financialResponsibilitiesParty1}
//     - Promoter 2: ${data.financialResponsibilitiesParty2}

//     The first Business Plan for the financial year ending on ${data.firstBusinessPlanDate} shall be prepared within ${data.preparationDays} days of the date of this agreement.

//     Business Plans for subsequent financial years shall be submitted for approval no later than ${data.subsequentBusinessPlansDate} days before the commencement of the financial year.

//     5. DEADLOCK RESOLUTION
//     -------------------------
//     If a deadlock occurs, either party may send a resolution notice after ${data.deadlockNotice} days.
//     The recipient of the Deadlock Resolution Notice must respond within ${data.deadlockResponse} days.

//     6. TERMINATION CLAUSE
//     -------------------------
//     Either party may terminate this agreement by providing ${data.terminationNotice} days' notice after a breach.

//     The financier for the business of the proposed Company shall be ${data.financierDetails}.

//     Additional termination clauses: ${data.additionalClausesTermination}

//     IN WITNESS WHEREOF, the parties have executed this agreement as of the date first above written.

//     Signed by:
//     ---------------------------------
//     ${data.party1Name} (Promoter 1)

//     ---------------------------------
//     ${data.party2Name} (Promoter 2)
//   `;
// };

// export const generateEmploymentAgreement = (data) => {
//   return `
//     EMPLOYMENT AGREEMENT
//     -----------------------------

//     THIS EMPLOYMENT AGREEMENT ("Agreement") is made and entered into on ${data.dateOfAgreement}, by and between:

//     1. ${data.employerName}, whose principal place of business is located at ${data.employerAddress} (hereinafter referred to as the "Employer"),

//     AND

//     2. ${data.employeeName}, residing at ${data.employeeAddress} (hereinafter referred to as the "Employee").

//     WHEREAS:
//     -------------------------
//     The Employer is engaged in the business of ${data.employerBusinessNature} and desires to hire the Employee to perform services under the terms and conditions set forth in this Agreement.

//     The parties agree as follows:

//     1. POSITION
//     -------------------------
//     The Employee is employed in the position of ${data.jobTitle}. The primary duties and responsibilities are as follows:
//     - ${data.jobDuties}.
//     ${data.additionalDuties ? `The Employee may also be assigned the following additional duties from time to time: ${data.additionalDuties}.` : ''}

//     2. TERM OF EMPLOYMENT
//     -------------------------
//     The Employee’s employment will commence on ${data.startDate}, and the duration of the employment will be ${data.employmentDuration}, unless terminated earlier under the terms set forth in this Agreement.

//     3. COMPENSATION
//     -------------------------
//     The Employee shall be paid an annual salary of ${data.annualSalary} ${data.currency} (in words: ${data.salaryInWords}).

//     ${data.additionalCompensation ? `Additional compensation terms include: ${data.additionalCompensation}.` : ''}
    
//     The Employee will be reimbursed for the following business-related expenses: ${data.reimbursementDetails}.

//     4. CONDUCT AND CONFIDENTIALITY
//     -------------------------
//     The Employee shall work exclusively for the Employer during the term of the Agreement and shall not engage in any other business activities that may result in a conflict of interest.

//     The Employee agrees to maintain the confidentiality of any and all proprietary information, trade secrets, and client lists during and after the employment.

//     5. TERMINATION
//     -------------------------
//     In the event of the Employee’s inability to perform their duties for ${data.disabilityDaysConsecutive} consecutive days or a total of ${data.disabilityDaysTotal} days during the term, the Employee shall be deemed permanently disabled, and the Employer may terminate this Agreement.

//     Either party may terminate this Agreement by providing ${data.terminationNotice} days' written notice.

//     6. ADDITIONAL CLAUSES
//     -------------------------
//     The Employee ${data.employeeAuthority ? `is authorized` : `is not authorized`} to enter into contracts or make decisions on behalf of the Employer without prior written consent.

//     This Agreement will be governed by the laws of ${data.governingLaw}.

//     IN WITNESS WHEREOF, the parties have executed this Employment Agreement as of the day and year first above written.

//     Signed by:
//     ---------------------------------
//     ${data.employerName} (Employer)

//     ---------------------------------
//     ${data.employeeName} (Employee)
//   `;
// };

export const generatePreIncorporationAgreement = (data) => {
  return `

    THIS PRE-INCORPORATION AGREEMENT is made the  ${data.agreementDate}

    BETWEEN:

    ${data.proposedCompanyName}
    ${data.natureOfBusiness}
    
    WHEREAS
    
    -----3--------- is to be incorporated by ${data.promoters} promoters as a private company limited by shares to be incorporated in Nigeria under the Companies and Allied Matters Act 2020 with the issued share capital of ${data.proposedShareCapital} divided into ${data.ordinaryShares} ordinary shares of N ${data.valuePerShare} each of which the entire issued share capital shall be held in the following proportion: ${data.companyPurpose}

    (2) The parties agree that the proposed Company should be used as their joint venture vehicle to -----8--
    
    (3)The parties have agreed to subscribe for shares in the proposed Company and enter into certain commitments and to regulate their rights about the proposed Company in the manner appearing below

    (4) This agreement shall be in force from the date of its execution by the parties and shall remain valid until the incorporation of the proposed company provided the terms of this agreement are incorporated in the Memorandum and Articles of Association of the proposed company when formed.

    (5) If any term of this agreement is excluded from the Memorandum and Articles of Association of the proposed company when formed, the parties to this agreement agree to pass the necessary resolution ratifying the terms of this agreement once the proposed company is formed.


    IT IS AGREED as follows:

    1 Interpretation
    ---------------------------------
    1.1 In this agreement unless the context otherwise requires: ____9___ 
    ‘the Board’ means the board of directors of the proposed Company 
    ‘Financial Year’ means any accounting reference period of the proposed Company
    ‘Investor in this agreement’ means angel investors, venture capitalists, private equity funds, accelerators, or incubators involved in the tech ecosystem and showing interest in investing in Toma
    ‘Subsidiary’ and ‘Holding Company’ have the same respective meanings as in the Companies and Allied Matters Act 2020 Section 381 refers to the proposed company to be incorporated by the promoters hereto 

    1.2 References to statutory provisions shall be construed as references to those provisions as respectively amended or re-enacted or as their application is modified by other provisions (whether before or after the date of this agreement) from time to time and shall include any provisions of which they are re-enactments (whether with or without modification)

    1.3 Any document referred to as being ‘in the Agreed Form’ shall mean a document in a form agreed by the parties at the date of this agreement and initialled by or on their behalf for identification purposes

    1.4 The headings are inserted for convenience only and shall not affect the construction of this agreement

    1.5	This Agreement shall remain in force until the proposed Company is duly incorporated and registered with the appropriate regulatory authorities. Upon incorporation, this Agreement shall be superseded by the governing documents of the Company, including but not limited to the Memorandum and Articles of Association.

    1.6	The provisions of this agreement shall be incorporated in the governing documents of the proposed Company when formed, including but not limited to the Memorandum and Articles of Association.


    2 Share Subscription
    ---------------------------------
    2.1 Immediately upon the incorporation of the Company:
    2.1:1   ${data.shareSubscription} shall subscribe for new ordinary shares of N 1 each in the proposed Company as follows:
     Name   Percentage of shares

    3 Activities prior to incorporation
    -------------------------------------
    3.1 During the term of this Agreement, the parties may ${data.activitiesPriorToIncorporation}

    4 Scope of the Business of the Proposed Company
    ------------------------------------------------
    4.1 The business of the proposed Company shall unless and until the parties otherwise agree be confined to ${data.businessScope}.

    4.2	The parties agree to jointly pursue _____________12_____________-
    
    4.3	 The parties shall collaborate to identify potential locations for new branches within or outside Nigeria, considering factors such as target demographics, competition, and strategic positioning.

    4.4	 The parties shall share the financial responsibilities associated with the establishment and operation of new within or outside Nigeria, including but not limited to initial setup costs, operational expenses, and staffing.

    ADDITIONAL INFORMATION ______13_______

    5.	 Timeline Clause:
    ---------------------------------
    5.1	The project shall commence on ____14____ and is estimated to be completed by ____15____. This timeline is subject to adjustment based on project scope changes, unforeseen circumstances, and client feedback.

    5.2	Milestones: _____________16__________________
    5.3	Iterative Development: The development process will follow an iterative approach, with regular check-ins and feedback loops to ensure alignment with the parties’ expectations. Iterations will be scheduled at intervals agreed upon by the parties.

    5.4	Change Management: Any proposed changes to the project scope or timeline must be communicated in writing and approved by the parties. Changes may impact the project schedule and budget, and will be assessed accordingly.

    5.5	Communication: The parties agree to maintain open and transparent communication throughout the project. Regular status updates will be provided to keep all parties informed of progress and any potential deviations from the timeline.

    6 Accounting matters and dividend policy
    ------------------------------------------
    6.1 The parties shall procure that:
    6.1:1 The proposed Company when formed shall always maintain accurate and complete accounting and other financial records by the requirements of all applicable laws and generally accepted accounting principles applicable in Nigeria

    6.1:2 Timely  management accounts containing such information as either party shall reasonably require shall be prepared and despatched by the proposed Company to the parties within [30] days of the end of the [month] in question

    6.1:3 each party and its respective authorised representatives shall be allowed access at all reasonable times to examine the books and records of the proposed Company

    6.2 The parties shall procure that the proposed Company shall prepare an annual business plan (‘the Business Plan’) which shall include the following:

    6.2:1 an estimate of the working capital requirements of the proposed Company incorporated within a cashflow statement [together with an indication of the amount (if any) which it is considered prudent to retain out of the previous Financial Year’s distributable profits to meet such working capital requirements]

    6.2:2 A projected profit and loss account
    
    6.2:3 an operating budget (including estimated capital expenditure requirements) and balance sheet forecast

    6.2:4 a review of the projected business

    6.2:5 a summary of business objectives

    6.3	The first Business Plan in respect of the Financial Year ending ${data.managementAccountsResponsibility} shall be prepared within ${data.reportFrequency} days of the date of this agreement]. Business Plans in respect of subsequent Financial Years shall be submitted for approval by the Board not later than ${data.businessPlanDeadline} days before the commencement of the Financial Year in question

    7 Promotion of the Proposed Company’s business
    ------------------------------------------------
    7.1 Each of the parties to this agreement covenants that for as long as it is a party to this agreement and for [24] months thereafter it shall not and shall procure that no company owned by it shall  be engaged concerned or interested either directly or indirectly and whether on its own behalf or on behalf of or in association with others or in any capacity whatever in carrying on in competition with the proposed Company or any Subsidiary of the proposed Company anywhere within the business of (specify business)]

    7.2 Each of the parties to this agreement covenants that for as long as it is a party to this agreement and for a period of [24] months thereafter it shall not and shall procure that no company owned or Controlled by it and no person on its or their behalf shall canvass solicit the custom of or endeavour to entice away from the proposed Company any person firm or company which has at any time during the 12 months before the termination of this agreement been a customer of or in the habit of dealing with the proposed Company or any Subsidiary of the proposed Company]

    8 Confidentiality
    ---------------------
    8.1 Each of the parties to this agreement shall at all times use its best endeavours to keep confidential (and to procure that its respective employees and agents shall keep confidential) any confidential information which it or they may acquire in relation to the proposed Company and its Subsidiaries or in relation to the clients business or affairs of every other party to this agreement or of the proposed Company or of any of the proposed Company’s Subsidiaries and shall not use or disclose such information except with the consent of every other party to this agreement and/or of the proposed Company or its relevant Subsidiary (as appropriate) or in accordance with the order of a court of competent jurisdiction or in the case of information relating to the proposed Company or any of its Subsidiaries for the advancement of the business of the proposed Company or the relevant Subsidiary

    8.2 The parties to this agreement shall procure that the proposed Company shall and shall procure that its Subsidiaries shall use all reasonable endeavours to ensure that the officers employees and agents of each of them observe a similar obligation of confidence in favour of the parties to this agreement

    8.3 The obligations of each of the parties contained in clause 9.1 shall continue without limit in point of time but shall cease to apply to any information coming into the public domain otherwise than by breach by any such party of its said obligations Provided that nothing contained in this clause 10 shall prevent any party from disclosing any such information to the extent required in or in connection with legal proceedings arising out of this agreement or any matter relating to or in connection with the proposed Company

    8.4 For this clause 8 the expression ‘party’ shall include the Subsidiary companies of any party and any other company Controlled by that party and the employees or agents of that party and of such Subsidiary or Controlled companies


    9 Tax matters
    -----------------
    9.1 The central management and Control of the proposed Company shall be exercised in Nigeria and the parties shall use their respective best endeavours to ensure that the proposed Company is treated by all relevant authorities as being resident for taxation purposes in Nigeria


    10 Deadlock resolution
    ---------------------------------
    10.1 For this clause 10 deadlock shall be deemed to have occurred if: 
    
    10.1:1 A matter relating to or affecting the proposed Company has been raised at and/or considered by a meeting of the Board and no resolution has been passed by such meeting because of an equality of votes for and against any resolution proposed relating to such matter and either party has subsequently notified the other [within ${data.deadlockResolution} days after such Board meeting] that the matter has not been resolved to its satisfaction or 

    10.1:2 A quorum is not present at two successive Board meetings (or proposed Board meetings) duly convened 

    10.2 In the event of deadlock: 
    10.2:1   either party (‘the Server’) shall be entitled [at any time thereafter or within ${data.deadlockNoticePeriod} days of the Board meetings referred to in clause 10.1:1 or the second of the proposed Board meeting referred to in clause 10.1:2] to serve a notice (‘Deadlock Resolution Notice’) on the other party (‘the Recipient’) offering either to sell all (but not some only) of its shares in the Company at a price per share specified by it (‘the Specified Price’) or to purchase all (but not some only) of the Recipient’s shares in the Company at a price per share equal to the Specified Price. 

    10.2:2   Within ${data.counterNoticeTimeframe} days of service of a Deadlock Resolution Notice the Recipient shall by notice to the Server (‘Counter Notice’) be entitled either to accept the offer contained in the Deadlock Resolution Notice or (if the offer contained was to purchase the Recipient’s shares) to elect to purchase all (but not some only) of the Server’s shares in the Company at a price per share equal to the Specified Price or (if the offer contained in the Deadlock Resolution Notice was to sell the Server’s shares) to elect to sell all (but not some only) of the Recipient’s shares in the Company to the Server at a price per share equal to the Specified Price. If no Counter Notice is served by the Recipient on the Server within the period referred to the Recipient shall be deemed to have accepted the offer contained in the Deadlock Resolution Notice.
    
    
    11 Termination
    --------------------
    11.1 This Agreement shall continue in full force and effect until it is terminated by the provisions of this clause
    
    11.2 Any of the parties to this agreement shall be entitled to terminate this agreement immediately by notice in writing [(but not after ___22___ days of the event in question first coming to the attention of the party entitled to give the notice)] if any of the events set out below shall occur. Such notice shall be served upon the party in respect of which the event or events relate (‘Such Other Party’) and copies of such notice shall be given to all other parties to this agreement (if any). The effect of such notice shall be to terminate this agreement as between Such Other Party and the remaining party or parties to this agreement but this agreement shall continue in full force and effect as between such remaining parties (if more than one) but not if otherwise. The said events are:
    
    11.2:1 if Such Other Party shall commit any [material] breach of any of its obligations under this agreement and shall fail to remedy such breach (if capable of remedy) within 30 days after being given notice by the first party so to do or

    11.3 This agreement shall terminate in respect of any party to this agreement (but shall continue between the other parties to this agreement (if more than one) but shall continue between the other parties to this agreement (if more than one) but not otherwise)

    11.4 This agreement shall terminate immediately if an effective resolution is passed to wind up the proposed Company or if a liquidator is otherwise appointed (but without prejudice to any rights any party may have against any other arising before such termination)


    12 Supremacy and general covenants
    ---------------------------------
    12.1         This agreement is not intended to override the provisions of the memorandum or articles of association of the proposed Company when formed and the parties hereto shall exercise all voting and other rights and powers available to incorporate all the provisions of this agreement in the memorandum or articles of association of the proposed Company when formed. If at any time there is a conflict between the memorandum or articles of association of the proposed Company and any of the provisions of this agreement the provisions of the memorandum or articles of association of the proposed Company shall prevail however the parties shall whenever necessary procure the amendment of the memorandum and articles of association to the extent necessary to permit the proposed Company and its affairs to be carried out as provided in this agreement.

    13 General
    ---------------------------------
    13.1 This agreement shall be binding upon the parties to this agreement and their respective successors and permitted assigns Provided that none of the parties to this agreement shall be entitled to assign this agreement or any of its rights and obligations under this agreement except by a transfer of that party’s shares in the proposed Company which is permitted under the express terms of this agreement [and/or which is made under the articles of association or which is otherwise approved in writing by the other party or parties to this agreement and (in either case) on terms that the transferee shall covenant with the other party or parties to this agreement to perform all the obligations of the transferor under this agreement

    13.2 No exercise or failure to exercise or delay in exercising any right power or remedy vested in any party under or pursuant to this agreement shall constitute a waiver by that party of that or any other right power or remedy

    13.3 No party to this agreement shall be entitled to make or permit or authorise the making of any press release or other public statement or disclosure concerning this agreement or any of the transactions contemplated in it without the prior written consent of the other party or parties .
    
    13.4  This agreement constitutes the entire agreement between the parties in relation to its subject matter and supersedes all prior agreements and understandings whether oral or written with respect to such subject matter and no variation of this agreement shall be effective unless reduced to writing and signed by or on behalf of a duly authorised representative of each of the parties

    13.5         In the event that any term condition or provision of this agreement is held to be a violation of any applicable law statute or regulation the same shall be deemed to be deleted from this agreement and shall be of no force and effect and this agreement shall remain in full force and effect as if such term condition or provision had not originally been contained in this agreement. Notwithstanding the above in the event of any such deletion the parties shall negotiate in good faith in order to agree the terms of a mutually acceptable and satisfactory alternative provision in place of the provision so deleted

    13.6         Time shall be of the essence for any provision of this agreement

    14 Finance for the proposed Company
    -------------------------------------
    Finance for the business of the proposed Company shall be -------------------------------


    15  Notices
    ---------------------------------
    15.1         Any notice to be given by any party to this agreement shall be in writing and shall be deemed duly served if delivered personally or sent by email, telex or facsimile transmission or by prepaid registered post (airmail in the case of an address for service outside Nigeria) to the addressee at the address or (as the case may be) the email, telex or facsimile details of that party set opposite its name below: -----------------

    15.2         Any notice sent by email shall be deemed served when despatched and any notice served by prepaid registered post shall be deemed served ------ hours after posting to an address in Nigeria or ----days after posting to an address outside Nigeria. In proving the service of any notice it will be sufficient to prove in the case of a letter that such letter was properly stamped addressed and placed in the post or delivered or left at the current address if delivered personally and in the case of a telex or facsimile that such telex or facsimile transmission was duly despatched to the telex or facsimile number of the addressee given above or subsequently notified for the purposes of this agreement 

    16 Law and jurisdiction
    ---------------------------------
    16.1 This agreement shall be governed by and construed in all respects in accordance with Nigerian law

    16.2         In relation to any legal action or proceedings to enforce this agreement or arising out of or in connection with this agreement (‘Proceedings’) each of the parties irrevocably submits to the jurisdiction of the Nigerian courts and waives any objection to Proceedings in such courts on the grounds of venue or on the grounds that the Proceedings have been brought in an inconvenient forum
    
    AS WITNESS etc
    
    (signatures on behalf of parties)
  `;
};

export const generateEmploymentAgreement = (data) => {
  return `

    EMPLOYMENT AGREEMENT
    This Agreement made and entered into this 	${data.agreementDate}	 day of	(2)	, 	(3)	, by and between 	${data.employerName}, of 	(5)	, hereinafter referred to as "employer", and 	${data.employeeName}	, of 	(7)	, hereinafter referred to as "employee".

    The parties recite that:
    Employer is engaged in 		(8)	 and maintains business premises at 	(9)	.
    
    Employee is willing to be employed by employer, and employer is willing to employ employee, on the terms and conditions hereinafter set forth.
    
    For the reasons set forth above, and in consideration of the mutual covenants and promises of the parties hereto, employer and employee covenant and agree as follows:
    
    AGREEMENT TO EMPLOY AND BE EMPLOYED
    Employer hereby employs employee as 	(10)	 at the above-mentioned premises, and employee hereby accepts and agrees to such employment.
    
    DESCRIPTION OF EMPLOYEE'S DUTIES
    Subject to the supervision and pursuant to the orders, advice, and direction of employer, employee shall perform such duties as are customarily performed by one holding such position in other businesses or enterprises of the same or similar nature as that engaged in by employer. Employee shall additionally render such other and unrelated services and duties as may be assigned to him from time to time by employer.

    MANNER OF PERFORMANCE OF EMPLOYEE'S DUTIES
    Employee shall at all times faithfully, industriously, and to the best of his ability, experience, and talent, perform all duties that may be required of and from him pursuant to the express and implicit terms hereof, to the reasonable satisfaction of employer. Such duties shall be rendered at the above mentioned premises and at such other place or places as employer shall in good faith require or as the interests, needs, business, and opportunities of employer shall require or make advisable.

    DURATION OF EMPLOYMENT
    The term of employment shall be 			(11)			 years, commencing on 		(12)		, 		(13)		, and terminating 	(14)		, 	(15)			, subject, however, to prior termination as provided in Sections 8 and 9 hereof.

    COMPENSATION; REIMBURSEMENT
    Employer shall pay employee and employee agrees to accept from employer, in full payment for employee's services hereunder, compensation at the rate of 		(16)	 Dollars ($	) per annum, payable 	(17)	. In addition to the foregoing, employer will reimburse employee for any and all necessary, customary, and usual expenses incurred by him while traveling for and on behalf of the employer pursuant to employer's directions.
    
    EMPLOYEE'S LOYALTY TO EMPLOYER'S INTERESTS
    Employee shall devote all of his time, attention, knowledge, and skill solely and exclusively to the business and interests of employer, and employer shall be entitled to all benefits, emoluments, profits, or other issues arising from or incident to any and all work, services, and advice of employee. Employee expressly agrees that during the term hereof he will not be interested, directly or indirectly, in any form, fashion, or manner, as partner, officer, director, stockholder, advisor, employee, or in any other form or capacity, in any other business similar to employer's business or any allied trade, except that nothing herein contained shall be deemed to prevent or limit the right of employee to invest any of his surplus funds in the capital stock or other securities of any corporation whose stock or securities are publicly owned or are regularly traded on any public exchange, nor shall anything herein contained by deemed to prevent employee from investing or limit employee's right to invest his surplus funds in real estate.

    NONDISCLOSURE OF INFORMATION CONCERNING BUSINESS
    Employee will not at any time, in any fashion, form, or manner, either directly or indirectly divulge, disclose, or communicate to any person, firm, or corporation in any manner whatsoever any information of any kind, nature, or description concerning any matters affecting or relating to the business of employer, including, without limitation, the names of any its customers, the prices it obtains or has obtained, or at which it sells or has sold its products, or any other information concerning the business of employer, its manner of operation, or its plans, processes, or other date of any kind, nature, or description without regard to whether any or all of the foregoing matters would be deemed confidential, material, or important.

    The parties hereby stipulate that, as between them, the foregoing matters are important, material, and confidential, and gravely affect the effective and successful conduct of the business of employer, and its good will, and that any breach of the terms of this section is a material breach of this agreement.

    OPTION TO TERMINATE ON PERMANENT DISABILITY OF EMPLOYEE
    Notwithstanding anything in this agreement to the contrary, employer is hereby given the option to terminate this agreement in the event that during the term hereof employee shall become permanently disabled, as the term "permanently disabled" is hereinafter fixed and defined. Such option shall be exercised by employer giving notice to employee by registered mail, addressed to him in care of employer at the above stated address, or at such other address as employee shall designate in writing, of its intention to terminate this agreement on the last day of the month during which such notice is mailed. On the giving of such notice this agreement and the term hereof shall cease and come to an end on the last day of the month in which the notice is mailed, with the same force and effect as if such last day of the month were the date originally set forth as the termination date. For purposes of this agreement, employee shall be deemed to have become permanently disabled if, during any year of the term hereof, because of ill health, physical or mental disability, or for other causes beyond his control, he shall have been continuously unable or unwilling or have failed to perform his duties hereunder for thirty (30) consecutive days, or if, during any year of the term hereof, he shall have been unable or unwilling or have failed to perform his duties for a total period of thirty (30) days, whether consecutive or not. For the purposes hereof, the term "any year of the term hereof" is defined to mean any period of 12 calendar months commencing on the first day of 		(18)	 and terminating on the last day of 	(19)	 of the following year during the term hereof.

    DISCONTINUANCE OF BUSINESS AS TERMINATION OF EMPLOYMENT
    Anything herein contained to the contrary notwithstanding, in the event that employer shall discontinue operations at the premises mentioned above, then this agreement shall cease and terminate as of the last day of the month in which operations cease with the same force and effect as if such last day of the month were originally set forth as the termination date hereof.

    EMPLOYEE'S COMMITMENTS BINDING ON EMPLOYER ONLY ON WRITTEN CONSENT
    Employee shall not have the right to make any contracts or other commitments for or on behalf of employer within the written consent of employer.

    CONTRACT TERMS TO BE EXCLUSIVE
    This written agreement contains the sole and entire agreement between the parties, and supersedes any and all other agreements between them. The parties acknowledge and agree that neither of them has made any representation with respect to the subject matter of this agreement or any representations inducing the execution and delivery hereof except such representations as are specifically set forth herein, and each party acknowledges that he or it has relied on his or its own judgment in entering into the agreement. The parties further acknowledge that any statements or representations that may have heretofore been made by either of them to the other are void and of no effect and that neither of them has relied thereon in connection with his or its dealings with the other.

    WAIVER OR MODIFICATION INEFFECTIVE UNLESS IN WRITING
    No waiver or modification of this agreement or of any covenant, condition, or limitation herein contained shall be valid unless in writing and duly executed by the party to be charged therewith. Furthermore, no evidence of any waiver or modification shall be offered or received in evidence in any proceeding, arbitration, or litigation between the parties arising out of or affecting this agreement, or the rights or obligations of any party hereunder, unless such waiver or modification is in writing, duly executed as aforesaid. The provisions of this paragraph may not be waived except as herein set forth.

    CONTRACT GOVERNED BY LAW
    This agreement and performance hereunder and all suits and special proceedings hereunder shall be construed in accordance with the laws of the State of 	(20)	.

    BINDING EFFECT OF AGREEMENT
    This agreement shall be binding on and inure to the benefit of the respective parties and their respective heirs, legal representatives, successors, and assigns.
    
    Executed on the date first above written. 
    
    (21)"Employer"

	   Signed: -----------------------------	


    (22)"Employee"

    Signed: -----------------------------

    NOTICE
    The information in this document is designed to provide an outline that you can follow when formulating business or personal plans. Due to the variances of many local, city, county and state laws, we recommend that you seek professional legal counseling before entering into  any contract or agreement.
  `;
};

// export const generateNDAAgreement = (data) => {
//   return `
//     NON-DISCLOSURE AGREEMENT (NDA)
//     ----------------------------------

//     THIS NON-DISCLOSURE AGREEMENT ("Agreement") is made and entered into on ${data.date}, by and between:
    
//     1. ${data.discloserName}, located at ${data.discloserAddress}, hereinafter referred to as the "Discloser",

//     AND
    
//     2. ${data.recipientName}, located at ${data.recipientAddress}, hereinafter referred to as the "Recipient".
    
//     WHEREAS, the Discloser possesses certain proprietary and confidential information, which the Recipient agrees to keep confidential under the terms of this Agreement.

//     NOW, THEREFORE, in consideration of the promises and mutual covenants contained herein, the parties hereto agree as follows:

//     1. DEFINITION OF CONFIDENTIAL INFORMATION
//     -----------------------------------------
//     "Confidential Information" under this Agreement includes all written and oral information provided by the Discloser, such as:
//     - ${data.confidentialInformation} (e.g., intellectual property, business operations, product details).

//     Confidential Information does not include information that is:
//     - ${data.excludedInformation} (e.g., public knowledge, information obtained independently by the Recipient).

//     2. SUBJECT OF THE AGREEMENT
//     ----------------------------
//     The Confidential Information disclosed under this Agreement is solely for the purpose of:
//     - ${data.purposeOfSharing}.

//     3. NON-DISCLOSURE OBLIGATIONS
//     ------------------------------
//     The Recipient agrees to:
//     - Take reasonable steps to protect Confidential Information, comparable to those taken for their information.
//     - Refrain from disclosing Confidential Information to any third party without Discloser's written consent.
//     - Not use Confidential Information for personal or third-party benefit.

//     Post-use, the Confidential Information shall be ${data.postUsageHandling}.

//     4. LEGAL OBLIGATIONS & DISCLOSURE
//     ---------------------------------
//     If legally compelled, the Recipient shall:
//     - Promptly inform the Discloser of any required disclosure by law.
//     - Seek to limit such disclosure as required.
//     - ${data.awarenessResponse} if they become aware of any unauthorized disclosure or threat of disclosure.

//     5. PENALTIES FOR BREACH
//     ------------------------
//     The penalty for unlawful disclosure or breach of confidentiality is:
//     - ${data.breachPenalty}.

//     6. AMENDMENTS
//     --------------
//     Amendments to this Agreement are only valid if made in writing and signed by both Parties.
//     ${data.additionalClause && `Additional Clauses: ${data.additionalClause}`}

//     7. TERM AND TERMINATION
//     -----------------------
//     This Agreement is effective from ${data.date} and remains in force indefinitely unless terminated by mutual written consent.

//     IN WITNESS WHEREOF, the Parties have executed this Agreement.

//     Signed by:
//     ---------------------------------
//     ${data.discloserName} (Discloser)

//     ---------------------------------
//     ${data.recipientName} (Recipient)
//   `;
// };

export const generateMediumTermLoanFacilityAgreement = (data) => {
  return `
    AGREEMENT GRANTING MEDIUM TERM LOAN FACILITY
    To ${data.companyName} 
     ${data.companyAddress} 
    ${data.agreementDate} .

    Dear ${data.salutation},     
    We offer to place at your disposal a Naira loan facility in the aggregate principal amount of ${data.loanAmount} [for the purpose of ${data.loanPurpose} on the following terms and conditions: ${data.termsConditions}
    
    1 Definitions
    In this letter, the expressions following shall, except where the context otherwise requires, bear the meanings attributed to them respectively: 
    
    1.1 'Bank' means ${data.bankName}  acting through its branch at ${data.bankAddress} and shall include its successors and assigns; ${data.successorsAssigns}.

    1.2 'Borrower' means ${data.companyName} registered in Nigeria with Registered No ${data.registeredNumber} and having its registered office at ${data.registeredOffice}.

    1.3 'Business Day' means a day on which banks are open for business in Nigeria

    1.4 'Commitment Expiry Date' means the earlier of the date falling ${data.commitmentExpiryDate} months after the date of this letter and the date upon which the full amount of the Facility is first outstanding under this letter;

    1.5 'Drawing' means a drawing under the Facility or (as the context may require) the principal amount comprised therein and for the time being outstanding;

    1.6 'Event of Default' means any one of the events specified in clause 8;

    1.7 'Facility' means the Naira loan facility in the maximum aggregate principal amount of ... the terms and conditions of which are set out in this letter;

    1.8 'Loan' means the aggregate principal amount of Drawings;

    1.9 'Repayment Dates' means the dates falling on ${data.repaymentDates} and every ${data.repaymentFrequency} months after that date until ${data.finalRepaymentDate} .
    [131]

    2 Drawings
    Subject to:
    2.1 the Bank having received and found satisfactory not later than ${data.firstDrawingTime} on the ... Business Day before the first Drawing:

    2.1.1 a copy of the memorandum and articles of association of the Borrower certified by a director or the secretary of the Borrower to be complete and up-to-date; and

    2.1.2 a copy of a resolution of the directors of the Borrower, certified by a director or the secretary of the Borrower to be in full force and effect at the date of receipt by the Bank, approving this letter and authorizing a person or persons to sign and deliver on behalf of the Borrower this letter and any other communications or documents to be delivered by the Borrower under this letter;

    2.2 no Event of Default having occurred and no event having occurred which, with the giving of notice and/or the lapse of time and/or upon the Bank making the relevant determination, would constitute an Event of Default; and

    2.3 the Bank having received not later than ${data.drawingNoticeTime} on the ... Business Day before each Drawing a notice of drawing by tested telex or letter; the Borrower may (subject to the provisions of this Letter). ${data.drawingConditions}  on ${data.drawingDetails} make Drawings which together aggregate the maximum amount of the Facility or less provided that:

    2.4 the amount of each Drawing shall be not less than ${data.minimumDrawingAmount} and an integral multiple of ${data.integralMultiple} ; and

    2.5 no more than one Drawing may be made in any period of ... Business Days.
    [132]

    3 Interest
    3.1 The Borrower shall pay interest on the Loan at the rate of ${data.interestRate} per annum above the Base Rate of ${data.bankName} for the time being and from time to time.

    3.2 In the event of default by the Borrower in the payment of any sum due pursuant to this letter on the due date for payment, the Borrower shall pay interest on that sum for the period from the due date for such payment until actual payment (as well after as before judgment) at the rate of ${data.defaultInterestRate} per annum above the Base Rate of ${data.bankName} for the time being and from time to time.

    3.3 All interest payable pursuant to this letter shall accrue from day to day and shall be calculated on the basis of ${data.interestCalculationBasis} and shall be paid, in the case of interest under clause 3.1, on the dates falling at [3-monthly] intervals after the date of this letter and, in the case of interest under clause 3.2, on demand.

    3.4 The statement of the Bank as to the rate or amount of interest payable pursuant to this clause shall, in the absence of manifest error, be conclusive.
    [133]

    4 Repayment
    The Loan will be repaid by the Borrower by ... equal (or substantially equal) instalments on the Repayment Dates, the amount comprised in each such repayment instalment being equal to ${data.repaymentInstalmentPercentage}% of the Loan at the close of business on the Commitment Expiry Date.

    5 Prepayment and cancellation
    5.1 The Borrower may prepay the whole or any part of the Loan (such part being not less than ... and an integral multiple of ...) together with interest on that amount accrued to the date of prepayment provided that the Bank shall have received from the Borrower not less than ${data.prepaymentNoticePeriod} [ days'] prior irrevocable written notice, specifying the amount to be prepaid and the date of the prepayment.

    5.2 Any part of the Loan prepaid pursuant to clause 5.1 shall [details] 
    
    5.3 The Borrower may, by giving the Bank not less than ${data.cancellationNoticePeriod} [ days'] prior irrevocable written notice, at any time before the Commitment Expiry Date cancel the whole or any part (such part being not less than ${data.minimumCancellationPeriod} [ days'] and an integral multiple of ${data.cancellationIntegralMultiple} [ days']  of the undrawn Facility.
    [134]

    6 Representations and warranties

    The Borrower represents and warrants to the Bank as follows:
    6.1 the execution on behalf of the Borrower of the form of acceptance endorsed on this letter has been validly authorised and the obligations expressed as being assumed by the Borrower under this letter constitute valid, legal and binding [and enforceable] obligations of the Borrower [enforceable against the Borrower in accordance with their terms];

    6.2 neither the execution and delivery of the form of acceptance endorsed on this letter by the Borrower nor the performance or observance of any of its obligations under this letter will:

    6.2.1 conflict with, or result in any breach of, any law, statute, regulation, indenture, mortgage, trust deed, agreement or other instrument, arrangement, obligation or duty by which the Borrower is bound; 
    
    6.2.2 cause any limitation on any of the powers whatsoever of the Borrower, howsoever imposed, or on the right or ability of the Directors of the Borrower to exercise such powers, to be exceeded;

    6.3 the Borrower is not in default under any law, statute, regulation, indenture, mortgage, trust deed, agreement or other instrument, arrangement, obligation or duty by which it is bound;

    6.4 the audited Profit and Loss Accounts of the Borrower for the year ended  ${data.auditedFinancialsDate} and the audited Balance Sheet of the Borrower as at that date give a true and fair view of the results of the operations of the Borrower for that period and the financial position of the Borrower as at that date and there has been no material adverse change in the business, assets, condition or operations of the Borrower since that date;

    6.5 there exists no mortgage, charge, pledge, lien, encumbrance or other security interest whatsoever over the whole or any part of the undertaking or assets, present or future,(including uncalled capital) of the Borrower except (list exceptions); and

    6.6 no litigation or administrative or arbitration proceeding before or of any court, governmental authority or arbitrator is presently taking place, pending or (to the best of the knowledge, information and belief of the Borrower) threatened against, or against any of the assets of, the Borrower [which might have a material adverse effect on its business, assets, condition or operations or might adversely affect its ability to perform its obligations under this letter].
    [135]

    7 Undertakings
    The Borrower will:
    7.1 not create or permit to subsist any mortgage, charge, pledge, lien, encumbrance or security interest whatsoever over the whole or any part of its undertaking or assets, present or future,(including uncalled capital) except (list exceptions); ${data.securityInterestsExceptions}.

    7.2 give the Bank notice in writing immediately upon becoming aware of the occurrence of any Event of Default or other event which, with the giving of notice and/or lapse of time and/or upon the Bank making the relevant determination, would constitute an Event of Default;

    7.3 within ${data.annualReportDueDate} [days] after the end of each financial year provide the Bank with ${data.annualReportCopies} copies of the annual audited Profit and Loss Account and Balance Sheet and Annual Report of the Borrower prepared on a basis consistent with prior years;

    7.4 provide the Bank promptly with such other financial information relating to the Borrower as the Bank may from time to time reasonably require;

    7.5 not, without the prior written consent of the Bank, and whether by a single transaction or by a series of transactions (related or not), sell, transfer, lend or otherwise dispose of (in any such case otherwise than in the ordinary course of trading) the whole or any substantial part of its business or assets, or make any change in the nature of its business which would materially alter the nature of the business of the Borrower for the time being and from time to time.
    [136]

    8 Events of Default
    8.1 An Event of Default occurs where:

    8.1.1 the Borrower fails to perform and observe any of its obligations under this letter;

    8.1.2 any representation or warranty made by the Borrower pursuant to this letter or in any statement delivered or made pursuant to it is incorrect when made;

    8.1.3 a material adverse change occurs, in the opinion of the Bank, in the financial condition, results of operations or business of the Borrower;

    8.1.4 any other indebtedness [for borrowed money] of the Borrower is not paid when due or becomes due or capable of being declared due prior to its stated date of payment;

    8.1.5 any action is taken for or with a view to the winding-up of the Borrower or a petition is presented for the making of an administration order5 in respect of the Borrower or such an order is made or the Borrower becomes insolvent or is unable to pay its debts within the meaning of the Companies and Allied Matters Act 1990 or makes any proposal to, or enters into dealings with, any of its creditors with a view to avoiding, or in expectation of, insolvency or stops or threatens to stop payments generally or an incumbrancer takes possession or a receiver or administrative receiver is appointed6 of the whole or any material part of the assets of the Borrower;

    8.1.6 it becomes unlawful or impossible for the Bank to make, maintain or fund the Loan as contemplated by this letter or any of the obligations expressed as being assumed by the Borrower under this letter ceases to be valid, legal and binding and enforceable against the Borrower in accordance with its terms;

    8.1.7 [the Borrower ceases to be directly or indirectly a [wholly owned] subsidiary of ${data.ownershipCondition}  or] in the opinion of the Bank, control of the Borrower's [voting share capital or Board of Directors] significantly changes as a result of ${data.changeOfControlCondition}  [state condition] by the Borrower.

    8.2 No further Drawing may be made after the occurrence of an Event of Default and the Bank shall be entitled at any time after the occurrence of an Event of Default by notice in writing to the Borrower to declare that the Loan and all interest and commitment commission accrued and all other sums payable pursuant to this letter have become immediately due and payable whereupon the same shall become immediately due and payable and the Borrower shall immediately pay the same to the Bank.

    8.3 In the event that the Loan shall be declared immediately due and payable as stated above the Borrower will reimburse the Bank for all losses and expenses (including loss of profit) incurred by the Bank in consequence of the Event of Default and/or of the acceleration of the Loan. The certificate of the Bank as to the amount of such losses and expenses shall, in the absence of manifest error, be conclusive.
    [137]

    9 Payments
    9.1 All payments to be made under this letter shall be made in ${data.paymentCurrency} in immediately available funds during normal banking hours. If any sum falls due for payment under this letter on a day which is not a Business Day, it shall be made on the next succeeding Business Day.

    9.2 All payments to be made by the Borrower under this letter shall be made to the Bank at ${data.paymentLocation}  (or at such other office as the Bank may notify to the Borrower from time to time). All payments to be made to the Borrower under this letter shall be made to the account of the Borrower with the Bank at (branch) (or at such other office as the Bank and the Borrower may from time to time agree).

    9.3 All sums payable by the Borrower under this letter shall be paid in ${data.deductionDetails}. If the Borrower is at any time required by law to make any deduction or withholding from any payment to the Bank, then the Borrower will ${data.deductionDetails}.

    10 Commitment commission fees and expenses
    10.1 The Borrower will pay to the Bank commitment commission calculated from the date of this letter to the Commitment Expiry Date on the basis of actual days elapsed and a 365 day year, at the rate of ${data.commitmentCommissionRate}  [details % per annum] upon the daily amount of the Facility for the time being undrawn and uncancelled. Such commitment commission shall, subject as otherwise provided in this letter, be payable ${data.commitmentPaymentFrequency}.

    Commitment Expiry Date.
    10.2 The Borrower will ${data.expensesCoverage} and all VAT thereon incurred by the Bank in connection with the negotiation, preparation and signature of this letter, and will ${data.expenseDetails} the Bank on demand for all expenses (including legal expenses) and all VAT thereon incurred by the Bank in suing for or recovering any sum due to the Bank under this letter or otherwise in protecting or enforcing its rights under this letter.

    10.3 The Borrower will pay all stamp and other duties and taxes (if any) to which this letter may be subject or give rise and will indemnify the Bank against any and all liabilities with respect to or resulting from any delay or omission on the part of the Borrower in the payment of such duties or taxes.
    [139]

    11 Notices
    11.1 Any notice or other communication required to be given:

    11.1.1 to the Bank under this letter shall be addressed and delivered to the Bank at ${data.noticeToBankAddress}

    11.1.2 to the Borrower under this letter shall be addressed and delivered to the Borrower at ${data.noticeToBorrowerAddress} (marked for the attention of (financial director or as appropriate)or in either such case at such other address and marked for such other attention as may be notified by the relevant party to the other party from time to time for this purpose.

    11.2 Any notice or other communication to be given under this letter may be given ${data.communicationDetails} and shall be effective on receipt. Any notice given by _____50_____  [details] shall be confirmed by a letter sent by first class prepaid post dispatched within ${data.confirmationPeriod} after the telephone conversation or after the time the communication was sent, but failure to dispatch or receive such confirmatory letter shall not prejudice the effectiveness of the notice given by telephone or email etc. The Bank shall be entitled to ${data.authorizationConfirmation} any notice or other communication believed by the Bank to be given or made by the person or persons duly authorised to give or make the same.
    [140]

    12 Assignment
    The Borrower may not assign or transfer any of its rights or obligations under this letter without the prior written consent of the Bank.

    13 Waivers: remedies cumulative
    No failure or delay by the Bank in exercising any right, power or privilege under this letter shall impair the same or operate as a waiver of the same nor shall any single or partial exercise of any right, power or privilege preclude any further exercise of the same or the exercise of any other right, power or privilege. The rights and remedies provided in this letter are cumulative and not exclusive of any rights and remedies provided by law.

    14 Law
    This letter, and the contract arising out of the Borrower's acceptance of the Facility on the terms and conditions set out in this letter, shall be governed by and construed in all respects in accordance with the laws of Nigeria.
    [141],

`;
};

export const generateDemandGuarantorPayment = (data) => {
  return `
    DEMAND TO GUARANTOR FOR PAYMENT
    Date _____1________

    Dear (2)
    As you are aware, we hold your guarantee dated , wherein you guaranteed the debt owed to us by (3) [DETAILS]
    You are advised that this debt is now in default. Accordingly, demand is made upon you as guarantor for full payment on the outstanding debt due us in the amount of __(3)____

    In the event payment is not made within __(4)____ days, we shall be compelled to enforce our rights against you under the guarantee by referring this matter to our solicitors.

    Yours sincerely

  `;
};



export const generateIndemnityAgreement = (data) => {
  return `
    INDEMNITY AGREEMENT
    
    
    This is an Indemnity Agreement is made and effective ${data.effectiveDate},
    BETWEEN:  ${data.indemnitorName}
    (the "Indemnitor"), a company organised and existing
    under the laws of ${data.indemnitorJurisdiction}, with its head office located at:
    AND: ${data.indemniteeName}
    (the "Indemnitee"), a company organised and existing
    under the laws of ${data.indemniteeJurisdiction} with its head office located at:
    In consideration of the sum of ${data.considerationAmount}, and other good and valuable consideration, receipt of which is acknowledged, on behalf of themselves, their heirs, assigns and successors, jointly and severally enter into this Indemnity Agreement to hold each other harmless form any suit, liability, claim, action or loss arising out of the below described incident, the parties agree as follows:

    1. LIABILITY, LOSS OR DAMAGE
    Indemnitor undertakes to indemnify Indemnitee from any and all liability, loss, or damage Indemnitee may suffer as a result of claims, demands, costs, or judgments against Indemnitee arising from ${data.indemnityActs}.

    2. DURATION
    Indemnity under this agreement shall commence on ${data.indemnityStartDate} and shall continue in full force until ${data.indemnityTerminationDate} OR ${data.terminationCondition}.

    3. REQUIREMENT OF NOTICE TO INDEMNITOR
    Indemnitee agrees to notify Indemnitor in writing, within ${data.notificationPeriod} days, by registered or certified mail, at Indemnitor's address as stated in this agreement, of any claim made against Indemnitee on the obligations indemnified against. IN WITNESS WHEREOF, each party to this agreement has caused it to be executed at ${data.executionPlace} on the date indicated below.


    INDEMNITOR
    ${data.indemnitorName}

    Authorized Signature--------------------



    INDEMNITEE
    ${data.indemniteeName}

    Authorized Signature--------------------


  `;
};


export const generateLetterOfCharge = (data) => {
  return `
   LETTER OF CHARGE
   
   Arrangement of Clauses   
   (1) Statement of Charge and Consideration therefor
   (2) Consequence of Default
   (3) Option of Chargee to Appropriate Other moneys
   (4) Additional Security
   (5) Chargee to Maintain Adequate Cash Balance
   (6) Charge to be a Continuing Security
   (7) Chargee not Bound to Enforce the Charge
   (8) Chargee May Grant Indulgence without Prejudice to
   ${data.bankName}
   ${data.bankAddress}
   
   (1) IN CONSIDERATION of any time or credit or other banking facilities which you may from time to time grant to us, we agree that all moneys to an amount of ${data.chargeAmount} from time to time held by you or to your order or under your control on our behalf, whether on deposit or current account or otherwise, shall stand charged with the due payment of all indebtedness or liabilities, contingent or otherwise, whether matured or payable at some future date, at any time owing by us to you, together with interest on fees charged in your customary manner, and at the rate for the time being charged by you.
   
   (2) WE FURTHER AGREE that failing repayment by us on demand of any such indebtedness or liabilities or any interest thereon, you are to be at liberty to ${data.repaymentFailureDetails}.
   
   (3) WE FURTHER AGREE that in addition to any other rights or liens hereinbefore referred to, you may, at your option, at any time or times, without notice to us, ${data.rightsCircumstances}
   
   (4) WE FURTHER AGREE that if the total of all indebtedness or liabilities or any interest thereon at any time owing by us to you shall exceed the current value of the moneys then held by you hereunder after deduction of such margin as you may from time to time require, we will at any time with notice from you provide such additional security as may be satisfactory to restore and maintain the margin, and in the event of our failing so to do, you are at liberty to realise the moneys as hereinbefore provided.
   
   (5) WE FURTHER AGREE AND UNDERTAKE to keep and authorise you to retain at all times in any current account of ours with you a cash balance equal to the total of indebtedness or liabilities or any interest thereon at any time owing by us to you hereunder after the deduction of such margin as you may from time to time require.

   (6) AND WE AUTHORISE you to return any of our cheques or bills domiciled with you for payment which if paid on presentation for payment would reduce our balance below the total amount of the said indebtedness, liabilities and interest thereon. WE FURTHER AGREE that this is to be a continuing security and shall cover any ultimate balance at any time owing by us to you.

   (7) IT IS ALSO UNDERSTOOD AND AGREED that you shall not be bound to assert or enforce any rights or liens hereunder or to take any action in reference thereto, and that you may in your discretion at any time or times relinquish your rights as to any particular moneys hereunder without thereby affecting or invalidating your rights hereunder as to all or any other moneys referred to.

   (8) NO TIME OR INDULGENCE granted by you to us in respect of our indebtedness or liabilities or any interest thereon at any time owing shall in any way affect or prejudice any of your rights hereunder.

   DATED the day of ${data.agreementDate}

   SIGNATURE……………



  `;
};


export const generateLetterOfCredit = (data) => {
  return `
    LETTER OF CREDIT

    To whom it may concern:

    ${data.companyName} will pay checks signed or endorsed ${data.endorsementAuthority} by the bearer, ${data.designatedBearer}, of this letter, ${data.maximumCheckAmount} provided that any such check is not payable in an amount in excess of ${data.totalSumPayable}, provided that notation of any check and of the amount of it paid pursuant to this letter is, at the time of payment, endorsed on the back of this letter, and provided that any check paid under authority of this letter does not increase the total sum paid on checks, as shown by such notation of checks paid endorsed here, in excess of of ${data.sumNotation}

    The full name and signature of the bearer, ${data.beneficiaryName}, is below, designated as “Beneficiary.”

    Writer

    ${data.beneficiaryName} 
    (Beneficiary)
    
    ${data.beneficiarySignature}
    (Beneficiary Signature)

  `;
};

export const generateLetterOfDemand = (data) => {
  return `
    Letter of Demand

    Date: ${data.demandDate}
    To: ${data.recipientName}
    ${data.recipientBusinessAddress}
    
    Dear ${data.recipientName},
    We are writing to formally demand payment of the outstanding sum owed to us by ${data.thirdPartyName} in the amount of ${data.amountDue}, which remains unpaid despite prior requests. This debt arose from ${data.transactionDescription}, and payment was initially due on ${data.originalDueDate}.

    Despite the good-faith provision of ${data.goodsServicesProvided} and our adherence to all agreed terms, the payment remains overdue by ${data.overduePeriod} as of today. This letter serves as a final notice for the immediate settlement of the amount due to avoid further action.

    According to Section 17 of the Nigerian Companies and Allied Matters Act (CAMA) and Sections 284 and 300 of the Nigerian Contract Act, parties in a contractual agreement are expected to fulfill all obligations in a timely manner. Failure to meet these contractual obligations grants the creditor the right to seek legal recourse.

    Furthermore, under Order 3 Rule 9 of the High Court of Lagos State Civil Procedure Rules, we are entitled to claim this debt and associated legal costs if this amount is not settled within the stipulated time.

    Immediate Action Required:
    Please be advised that if payment of the outstanding amount is not received within ${data.timeForSettlement} [Number of Days, e.g., 14 days] from the date of this letter, we will have no choice but to pursue all available legal remedies to recover the debt, including, but not limited to, filing a lawsuit for the outstanding amount, legal fees, and any additional costs incurred due to non-payment.

    Payment should be made directly to the account details provided below or via a certified bank draft:

    ${data.bankAccountDetails} [Bank Account Details]

     If there are any questions or concerns regarding this matter, please contact us directly at ${data.contactInformation} [Your Contact Information].

     We trust that you will treat this matter with the urgency it requires and avoid any escalation to legal proceedings.

     Yours sincerely,

     ${data.senderName}

     ${data.senderPosition}

     ${data.senderCompany}

     ${data.senderContactDetails}


  `;
};

export const generateLoanAgreement = (data) => {
  return `
     LOAN AGREEMENT
     
     THIS AGREEMENT IS MADE the day of year
     BETWEEN:
     ${data.borrowerName};
     ${data.borrowerAddress}
     ${data.borrowerOccupation} and

     ${data.lenderName}
     ${data.lenderAddress}
     ${data.additionalLenderAddress}.
     
     NOW IT IS HEREBY AGREED as follows:
     1. Loan: Subject to and in accordance with this agreement, its terms, conditions and covenants the Lender agrees to lend to the Borrower on ${data.loanDate} the principal sum of ${data.loanAmount}.

     2. Note: The Loan shall be evidenced by a Note in the form attached hereto as Exhibit A (the "Note") executed by the Borrower and delivered to the Lender on the Loan Date.

     3. Interest: The Loan shall bear interest on the unpaid principal at an annual rate of ${data.interestRate}(%). In the event of a default in payment the aforesaid interest rate shall apply to the total of principal and interest due at the time of default.

     4. Payment: Payment shall be in accordance with the terms contained in the Note. The Note may, at any time and from time to time, be paid or prepaid in whole or in part without premium or penalty, except that any partial prepayment shall be ${data.partialPaymentMultiple} , ${data.minimumPaymentAmount} a minimum of (CURRENCY & AMOUNT); applied to any instalments due under the Note in the inverse order of their maturity. Upon the payment of the outstanding principal in full or all of the instalments, if any, the interest on the Loan shall be computed and a final adjustment and payment of interest shall be made within ${data.interestAdjustmentPeriod}  of the receipt of notice. Interest shall be calculated on the basis of a year of days and the actual number of days elapsed.

     5. Security: The Borrower agrees to secure the repayment of the Loan by executing those security documents attached hereto as Exhibit B (the "Security Documents") and shall deliver the Security Documents on the Loan Date. From time to time the Lender may demand, and the Borrower shall execute, additional loan documents which are reasonably necessary to perfect the Lender's security interests.

     6. Representations and Warranties: The Borrower represents and warrants: (i) that the execution, delivery and performance of this agreement, and the Note and Security Documents have been duly authorised and are proper; (ii) that the financial statement submitted to the Lender fairly presents the financial condition of the Borrower as of the date of this agreement knowing that the Lender has relied thereon in granting the Loan; (iii) that the Borrower has no contingent obligations not disclosed or reserved against in said financial statement, and at the present time there are no material, unrealised or anticipated losses from any present commitment of the Borrower; (iv) that there will be no material adverse changes in the financial condition of the Borrower at the time of the Loan Date; (v) that the Borrower will advise the Lender of material adverse changes which occur at any time prior to the Loan Date and thereafter to the date of final payment; and (vi) that the Borrower has good and valid title to all of the property given as security hereunder. The Borrower represents and warrants that such representations and warranties shall be deemed to be continuing representations and warranties during the entire life of this agreement.

     7. Default: The Borrower shall be in default: (i) if any payment due hereunder is not made within ${data.interestAdjustmentPeriod} of the date due; (ii) in the event of assignment by the Borrower for the benefit of creditors; (iii) upon the filing of any voluntary or involuntary petition for bankruptcy by or against the Borrower; or (iv) if the Borrower has breached any representation or warranty specified in this agreement.

     8. Governing Law: This agreement, the Note(s) and the Security Documents shall be governed by, construed and enforced in accordance with the law of Nigeria to the jurisdiction of which the parties hereto submit.

     IN WITNESS OF WHICH the parties have signed this agreement the day and year first above written


     ____________________________________
     in the presence of (witness)
     
     Name	_____9____________________
     
     Address	______10___________________
     
     Occupation	_____11____________________




     ____________________________________
     in the presence of (witness)
     
     Name	_______12__________________     
     
     Address	_______13__________________
     
     Occupation_____14___________________

     `;
};


export const generateMultimediaPublicityPrivacyReleaseAgreement = (data) => {
  return `
    MULTIMEDIA PUBLICITY/PRIVACY RELEASE

    This Multimedia Publicity/Privacy Release (the ‘Agreement’) is made and effective ${data.effectiveDate},
    BETWEEN:
    ${data.releasorName} the "Releasor"), a company organized and existing under the laws of ${data.releasorLaws}
    with its head office located at: ${data.releasorJurisdiction}
    AND:
    ${data.creatorName}, a company organized and existing under the laws of, with its head office located at 5: ${data.creatorAddress}

    RECITALS
    In consideration of ${data.creatorName} agreement to incorporate some or all of the Materials identified below (the "Materials") in one or more of its multimedia works (the "Works"), and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, Developer/ Creator  hereby grants Releasor  permission to use, adapt, modify, reproduce, distribute, publicly perform and display, in any form now known or later developed, the Materials specified in this release throughout the ${data.territories}, by incorporating them into one or more Works and/or advertising and promotional materials relating thereto.

    MATERIALS TO BE RELEASED
    This release is for the following Materials:
    ${data.materials}
    [Voice]
    [Visual likeness (on photographs, video, film, etc.)] 
    [Photographs, graphics or other artwork as specified in Schedule A]
    [Film, videotape or other audiovisual materials as specified in Schedule A]
    [Music or sound recordings as specified in Schedule A]
    [Other, as specified in Schedule A]

    WARRANTIES
    Releasor warrants and represents that the Materials identified above are either owned by and/or are original to the Creator/ Developer and/or that
    Releasor has full authority from the owner of the Materials to grant this release.
    Developer/ Creator releases Releasor , its agents, employees, licensees and assigns from any and all claims Releasor may have now or in the future for invasion of privacy, right of publicity, copyright infringement, defamation or any other cause of action arising out of the use, reproduction, adaptation, distribution, broadcast, performance or display of the Works.


    NO WAIVER
    Releasor waive any right to inspect or approve any Works that may be created containing the Materials.

    ACKNOWLEDGMENT
    Releasor understands and agrees that Developer/ Creator ${data.exclusiveOwner} is / is not  and ${data.exclusiveRights} shall/ shall not be the exclusive owner of all right, title and interest, including copyright, in the Works, and any advertising or promotional materials containing the Materials, except as to preexisting rights in any of the Materials released hereunder.


    Releasor has read this release and is fully familiar with its contents.
    
    IN WITNESS WHEREOF, the parties have executed this Agreement on the dates set forth first above, with full knowledge of its content and significance and intending to be legally bound by the terms hereof.

    RELEASOR
    ${data.releasorSignature}

    Signed:________________________

    DEVELOPER/ CREATOR 
    ${data.creatorSignature}

    Signed:________________________

    SCHEDULE A ${data.scheduleMaterials} 
    
  `;
};

export const generateSoftwareAppPrivacyPolicy = (data) => {
  return `
    SOFTWARE OR APP PRIVACY POLICY

    Introduction
    At ${data.companyName}, we value your privacy and are committed to protecting your personal information. This policy outlines how we handle data collection, storage, and sharing when you use our website or software application. Our goal is to ensure a secure, reliable experience while safeguarding your personal details.

    Data Collection and Use
    We collect personal information only when necessary, such as when you create an account, access certain features, or make a purchase. This information may include your name, email address, and payment details. We use this data to:
        Provide and improve our services
        Facilitate user account management
        Send updates about product features, offers, and news
        Personalize your experience and suggest relevant content or features

    Your Consent
    By creating an account or providing personal information on our platform, you consent to the collection and use of your data as outlined in this policy. You can adjust your communication preferences in your account settings at any time.

    Data Security
    We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, or loss. All sensitive transactions, including payment details, are encrypted and protected by ${data.securityMeasures}. Only authorized personnel have access to user data, and we enforce strict internal security practices to safeguard your information.

    Third-Party Services
    We may use third-party providers to assist with services such as payment processing, email distribution, or customer support. These providers have limited access to your information solely for completing these tasks on our behalf and are prohibited from using your data for any other purpose.

    Data Sharing
    ${data.dataSharingReference} does not sell or share your personal information with third parties except as required by law, such as to:
          Comply with legal obligations or processes
          Enforce our rights and agreements
          Protect the safety of our users or the public

    Accessing and Updating Your Information
    You have the right to access and update your personal information at any time through your account settings. If you would like further assistance, please contact us.

    Cookies and User Identification
    To provide a seamless experience, we use cookies and unique user identifiers. Cookies help us remember your preferences and keep your session secure. You may disable cookies in your browser settings, although some features may be limited.

    Children’s Privacy
    We are committed to protecting the privacy of children. Our platform is not intended for children under ${data.minimumAge}, and we do not knowingly collect data from this age group without parental consent.

    Changes to This Privacy Policy
    We may update this privacy policy as necessary to reflect changes in our practices or regulatory requirements. We encourage you to review this policy periodically to stay informed about how we protect your information.

    Contact Us
    If you have any questions or concerns about this privacy policy, please contact us at ${data.contactEmail}.

    Effective Date: ${data.effectiveDate}



  `;
};

export const generateTransferOfABillOfSale = (data) => {
  return `
   
    TRANSFER OF A BILL OF SALE

    DATE AND PARTIES

    THIS TRANSFER made on ${data.transferDate} between ${data.partyAName} of ${data.partyAAddress} (‘(Party-A)’),${data.partyBName} of ${data.partyBAddress} (‘(Party-B)’) and ${data.partyCName} of ${data.partyCAddress} (‘(Party-C)’). 
    
    DEFINITIONS
    1 In this deed the following terms shall have the following meanings.
    ‘Bill of Sale’
    means an indenture dated ${data.originalDate} and made (Party-C) and (Party-A) being a bill of sale to secure the repayment to the (Party-A) of the sum of ${data.billOfSaleAmount} with interest as mentioned in it.

    ‘Chattels’
    means the goods and chattels which are now vested in the (Party-A) subject to redemption by the Bill of Sale.

    ‘Indebtedness’
    means all money from time to time owing on the security of the Bill of Sale[, which is now 10 (CURRENCY & AMOUNT) plus interest from 11 (date) to the date of this deed]. 
    
    ACKNOWLEDGMENT OF DEBT
    2 The (Party-A) acknowledge that [he/she] is indebted to the (Party-B) for 12 (CURRENCY & AMOUNT)  (or) the Loan. 

    RECEIPT
    3 The (Party-C) acknowledges the receipt of 10 (CURRENCY & AMOUNT) paid to [him/her] by the (Party-B) as consideration for the transaction entered into by this document.
    
    TRANSFER OF MORTGAGE2
    4 The (Party-A) with 13 [full (or) limited] title guarantee transfers the benefit of the Bill of Sale [and Further Bill of Sale] to the (Party-B).
    
    ACKNOWLEDGMENT OF TRANSFER
    5 The (Party-C) acknowledges the amount of the Indebtedness and the transfer of the benefit of the Bill of Sale [and Further Bill of Sale] made by this deed

    PAYMENT OF PRINCIPAL AND INTEREST3
    6.1  [For as long as the powers of appointing a receiver under this deed has not become exercisable the (Party-C) shall accept and] the (Party-C) shall pay the [Loan (or) Debt (or) 14 (CURRENCY & AMOUNT) of the Indebtedness] to the (Party-A) by [the Installments (or) 15 (number) instalments of 16 (CURRENCY & AMOUNT) on each of the next 17 (number) Payment Dates and one instalment of 18 (CURRENCY & AMOUNT) on the following Payment Date the first payment to be made on the first of the Payment Dates after today or on 19 (date).
    (or)
    6.1 The Loan shall be paid with interest at the Rate of Interest by the Installments to be applied as follows until the Loan and interest on it have been paid in full:
    
    —      to the Loan or the balance of it at the start of each period of 20 [details] months starting today or on 21 (date) shall be added interest on it for that period;
    
    —      from the sum so obtained shall be deducted the total of the Installments paid during that period; and
    
    —      the result shall be the balance of the Loan at the start of the next period of 22 [details] months.

    6.2 The (Party-C) shall pay to the (Party-A) in arrears on every 23 [Payment Date (or) 24 day on which any payment of Indebtedness is due] interest on the Indebtedness calculated on a day-to-day basis at the Rate of Interest the first payment to be made on the first of the Payment Dates after today.

    (or)

    The (Party-C) shall pay to the (Party-A) in arrears on the last day of every month interest on the Indebtedness calculated on a day-to-day basis at the Rate of Interest the first payment to be made on 25 [details , either the last day of the month in which this (document) is made] or  26 (date).

    6.3 The whole of the Indebtedness shall become due and payable with interest to the date of actual payment immediately any payment of the Loan or interest is not made in full on the due date. 

    ASSIGNMENT
    7 The (Party-A) as mortgagee [at the request of the (Party-B)]4 assigns the Chattels to the (Party-C) absolutely subject to such right or equity of redemption affecting them as is now subsisting under or by virtue of the Bill of Sale. 

  `;
};

export const generateTenantsNoticeOfIntentToMoveOut = (data) => {
  return `

      Tenant's Notice of Intent to Move Out

      Date: ${data.noticeDate}
      Landlord/Manager: ${data.landlordName}
      Street address: ${data.landlordAddress}
      City and state: ${data.propertyCityState}
      Regarding rental unit address:	${data.rentalPropertyAddress}

      Dear ${data.tenantName}:

      This is to notify you that the undersigned tenant(s) will be moving from the above-noted rental unit on ${data.moveOutDate}, or ${data.flexibleNoticePeriod} days from today. This notice provides you with at least ${data.flexibleNoticePeriod} days' written notice, as required in our rental agreement.

      ${data.forwardingAddressInstructions}

      ${data.landlordName}
  

    `;
};


export const generateNoticeOfRevocation = (data) => {
  return `

    NOTICE OF REVOCATION
    
    TAKE NOTICE that I, 1 (name of guarantor) of 2 (address of guarantor), the undersigned now revoke and determine from and after the date of this notice the 3 [agreement (or) guarantee (or) bond] dated 4 (date of agreement, guarantee or bond) under which I became guarantor for 5 (name of principal debtor) of 6 (address of principal debtor)

    AND I DECLARE that all liability whatever on my part under that 7 [agreement (or) guarantee (or) bond] shall now wholly cease and determine 8 [(include for the protection of the person guaranteed) except as to any liability existing at the time of the receipt by you of this notice].

    Dated: 9

    Name of creditor(s): 10


      `;
};


export const generateLetterOfIntent = (data) => {
  return `

    LETTER OF INTENT

    Date: ${data.agreementDate}
    Subject: Letter of Intent for ${data.purposeOfLOI}

    1. Parties
    This Letter of Intent ("LOI") is entered into on this day, [Date of Agreement] by and between:

      ${data.partyAName}, a company/individual registered under the laws of Nigeria with company registration number ${data.partyARegistrationNumber}, and whose registered office is located at  ${data.partyAAddress} ("[Party A]"); and
      ${data.partyBName}, a company/individual registered under the laws of Nigeria with company registration number ${data.partyBRegistrationNumber}, and whose registered office is located at ${data.partyBAddress} ("[Party B]").
    
    2. Purpose
    The purpose of this LOI is to set forth the preliminary understanding and intent of the parties regarding a potential [describe the transaction, e.g., "partnership," "joint venture," "purchase of assets"] between the parties, subject to the terms and conditions specified below

    9
    3. Proposed Terms and Conditions
    3.1 Scope of Transaction
    ${data.transactionDescription} 
    ${data.scopeOfTransaction}

    3.2 Consideration
    The anticipated consideration for this transaction shall be ${data.consideration}, subject to negotiation and execution of a formal agreement.

    3.3 Due Diligence
    Each party shall conduct a due diligence review, which may include financial, operational, and legal aspects of the other party. The due diligence process is expected to be completed within ${data.dueDiligenceTimeframe}.

    3.4 Confidentiality
    All information shared between the parties during this process shall remain confidential and shall not be disclosed to any third party, except as required by law or with the prior written consent of the other party. Each party agrees to execute a separate Non-Disclosure Agreement if requested by the other.

    3.5 Exclusivity
    This LOI grants [Party A] and [Party B] exclusive rights to negotiate the terms of this transaction for a period of ${data.exclusivityPeriod}, during which neither party shall engage with third parties in relation to a similar transaction.

    3.6 Governing Law
    This LOI, as well as any formal agreements arising from it, shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria. Disputes arising out of this LOI shall be settled amicably or, if necessary, through the courts of ${data.governingJurisdiction}.

    4. Non-Binding Nature
    This LOI is intended to serve as a non-binding indication of interest by both parties, with the understanding that a binding commitment will only arise upon the execution of a final, definitive agreement.

    5. Termination
    This LOI shall automatically terminate upon the earlier of (i) the signing of a formal agreement between the parties, (ii) mutual written agreement of the parties to terminate, or (iii) ${data.terminationTimeframe} from the date of this LOI.

    6. Miscellaneous
    Amendments: This LOI may be amended only by mutual written consent of both parties.
    Expenses: Each party shall bear its own expenses incurred in connection with the negotiation and preparation of this LOI and any related agreements.
    Severability: If any provision of this LOI is held to be invalid, the remaining provisions shall remain in full force and effect.

    7. Signatures
    By signing below, the parties acknowledge their understanding of the terms of this LOI and agree to proceed with good-faith negotiations.

    For Party A
    Signature: ___________________________________
    Printed Name: ${data.partyAPrintedName}
    Title:  ${data.partyATitle}
    Date: ${data.agreementDate}

    For Party B
    Signature: ___________________________________
    Printed Name: ${data.partyBPrintedName}
    Title: ${data.partyBTitle}
    Date: ${data.agreementDate}

    This Letter of Intent is a preliminary document and is intended to outline the initial understanding and intent of both parties. It is recommended that each party seeks independent legal advice before entering into any formal agreements based on this LOI.
      `;
};


export const generateNoticeOfDefault = (data) => {
  return `

    NOTICE OF DEFAULT

    TAKE NOTICE THAT ${data.principalDebtorName} of ${data.principalDebtorAddress}, for whom you became and are guarantor to ${data.creditorName} [me (or) us] (name(s) of creditor(s)) under an agreement of guarantee 4 (‘the Agreement’) in writing dated ${data.agreementDate}, has made default in payment of the sum of ${data.amountDue} due to 7 [me (or) us] in respect of 8 [goods supplied (or) advances made] to him/her and secured by the Agreement.

    AND TAKE NOTICE THAT 9 [I (or) WE] REQUIRE YOU 10 [immediately (or) as provided by the Agreement] to pay or cause to be paid to us that sum together with interest thereon [as provided for by the Agreement]  [(payment details) at 11 ……… between the hours of 12 …… and ……] otherwise legal proceedings to enforce and recover payment will be taken against you at the expiration of 13 [insert the period] from the date of this notice.


    Dated: 14

    Name(s) of creditor(s): 15
      `;
};
