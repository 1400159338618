import React from 'react';

const BillingInformation = ({ billingInfo }) => {
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:!bg-[#a711007a] dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="p-6 px-4 pb-0 mb-0 border-b-0 rounded-t-2xl">
        <h6 className="mb-0 dark:text-white">Billing Information</h6>
      </div>
      <div className="flex-auto p-4 pt-6">
        <ul className="flex flex-col pl-0 mb-0 rounded-lg">
          {billingInfo.map((info, index) => (
            <li
              key={index}
              className={`relative flex p-6 mb-2 border-0 ${
                index === 0 ? 'rounded-t-inherit' : ''
              } ${index === billingInfo.length - 1 ? 'rounded-b-inherit' : ''} rounded-xl bg-gray-50 dark:!bg-[#a711007a]`}
            >
              <div className="flex flex-col">
                <h6 className="mb-4 text-sm leading-normal dark:text-white">{info.name}</h6>
                <span className="mb-2 text-xs leading-tight dark:text-white/80">
                  Company Name: <span className="font-semibold text-slate-700 dark:text-white sm:ml-2">{info.companyName}</span>
                </span>
                <span className="mb-2 text-xs leading-tight dark:text-white/80">
                  Email Address: <span className="font-semibold text-slate-700 dark:text-white sm:ml-2">{info.email}</span>
                </span>
                <span className="text-xs leading-tight dark:text-white/80">
                  VAT Number: <span className="font-semibold text-slate-700 dark:text-white sm:ml-2">{info.vatNumber}</span>
                </span>
              </div>
              <div className="ml-auto text-right">
                <a
                  className="relative z-10 inline-block px-4 py-2.5 mb-0 font-bold text-center text-transparent align-middle transition-all border-0 rounded-lg shadow-none cursor-pointer leading-normal text-sm ease-in bg-150 bg-gradient-to-tl from-red-600 to-orange-600 hover:-translate-y-px active:opacity-85 bg-x-25 bg-clip-text"
                  href="javascript:;"
                >
                  <i className="mr-2 far fa-trash-alt bg-150 bg-gradient-to-tl from-red-600 to-orange-600 bg-x-25 bg-clip-text"></i>Delete
                </a>
                <a
                  className="inline-block dark:text-white px-4 py-2.5 mb-0 font-bold text-center align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-normal text-sm ease-in bg-150 hover:-translate-y-px active:opacity-85 bg-x-25 text-slate-700"
                  href="javascript:;"
                >
                  <i className="mr-2 fas fa-pencil-alt text-slate-700" aria-hidden="true"></i>Edit
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BillingInformation;
