import React from "react";
import Footer from "../components/Layout/Footer.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Mission from "../components/Route/Mission/Mission.jsx";
import Approach from "../components/Route/Approach/Approach.jsx";
import Teams from "../components/Route/Teams/Teams.jsx";
import Navbar from "../components/Layout/Navbar.jsx";
// import Testimonials from "../components/Route/Testimonials/Testimonials.jsx";
import Testimonials from "../components/Route/Testimoney/Testimonials.jsx";
import Testimoney from "../components/Route/Testimoney/Testimoney.jsx";

const AboutPage = () => {
  return (
    <div className="text-center md:text-left dark:bg-[#490800] dark:text-white ">
      <Navbar active={2} />
      <div className="max-w-7xl mx-auto px-6">
        <Mission />
        <Approach />
        {/* <Teams /> */}
        <Testimoney />
        <Testimonials />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
