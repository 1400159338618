import React from "react";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import Dashboard from "../Dashboard/Dasboard";
import Favourites from "../Favourites/Favourites";
import Plans from "../Plans/Plans.jsx";
import Settings from "../Settings/Settings.jsx";
import Accounts from "../Accounts/Accounts.jsx";
import Help from "../Help/Help.jsx";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/dashboard",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "My Docs List",
    layout: "/dashboard",
    path: "favourite",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Favourites />,
    secondary: true,
  },
  {
    name: "My Saved Files",
    layout: "/dashboard",
    path: "files",
    icon: <MdLock className="h-6 w-6" />,
    component: <Help />,
  },
  {
    name: "My Plan",
    layout: "/dashboard",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "plans",
    component: <Plans />,
  },
  {
    name: "Settings",
    layout: "/dashboard",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "settings",
    component: <Settings />,
  },
  {
    name: "Accounts",
    layout: "/dashboard",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Accounts />,
  },
  
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
