import React from 'react';
import logoSrc from '../../../../Assests/img/logos/mastercard.png'

const Card = ({ backgroundImage, cardNumber, cardHolder, expiryDate }) => (
  <div className="w-full max-w-full px-3 xl:mb-0 xl:flex-none">
    <div className="relative flex flex-col min-w-0 break-words bg-transparent border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
      <div
        className="relative overflow-hidden rounded-2xl"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* Dark Overlay */}
        <div className="absolute top-0 left-0 z-10 w-full h-full bg-[#000] opacity-50 rounded-2xl"/>

        {/* Card Content */}
        <div className="relative z-10 flex-auto p-4">
          <i className="p-2 text-white fas fa-wifi"></i>
          <h5 className="pb-2 mt-6 mb-12 text-white">{cardNumber}</h5>
          <div className="flex">
            <div className="mr-6">
              <p className="mb-0 text-sm leading-normal text-white opacity-80">Card Holder</p>
              <h6 className="mb-0 text-white">{cardHolder}</h6>
            </div>
            <div>
              <p className="mb-0 text-sm leading-normal text-white opacity-80">Expires</p>
              <h6 className="mb-0 text-white">{expiryDate}</h6>
            </div>
            <div className="flex items-end justify-end w-1/5 ml-auto">
              <img className="w-3/5 mt-2" src={logoSrc} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Card;
