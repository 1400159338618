import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import classNames from "classnames";
import axios from "axios";
import logo from "../../../Assests/Svg/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import smallSphere from "../../../Assests/benefits/card-1.svg";
import legal from "../../../Assests/Image/ai.png";
import { GradientLight } from "../../common/design/Benefits";
import ClipPath from "../../../Assests/Svg/ClipPath";
import { server } from "../../../server";
import { toast } from "react-toastify";

const Signup = () => {
  const [formData, setFormData] = useState({
    salutation: "",
    firstName: "",
    lastName: "",
    username: "",
    eMail: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    gender: "",
    profession: "",
    industry: "",
  });
  const navigate = useNavigate();
  const [visibleOne, setVisibleOne] = useState(false);
  const [visibleTwo, setVisibleTwo] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

    const [industries, setIndustries] = useState([]);
  
  // Fetch industries from the API
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await axios.get("https://api-gw.tomatech.online/v1/ecommerce/industries");
        setIndustries(response.data.data); // Assuming the data structure is {data: [{name: "IndustryName"}, ...]}
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };
    
    fetchIndustries();
  }, []);

  const login = () => {
    navigate("/login");
  };
  const registernow = () => {
    navigate("/register");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.salutation) errors["salutation"] = "Salutation is required";
    if (!formData.firstName) errors["firstName"] = "First name is required";
    if (!formData.lastName) errors["lastName"] = "Last name is required";
    if (!formData.username) errors["username"] = "Username is required";
    if (!formData.eMail) errors["eMail"] = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.eMail))
      errors["eMail"] = "Email format is invalid";
    if (!formData.phoneNumber)
      errors["phoneNumber"] = "Phone number is required";
    if (!formData.password) errors["password"] = "Password is required";
    if (formData.password.length < 8)
      errors["password"] = "Password must be at least 8 characters";
    if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}/.test(
        formData.password
      )
    ) {
      errors["password"] =
        "Password must include at least 3 of the following: uppercase letters, lowercase letters, numbers, special characters";
    }
    if (formData.password !== formData.confirmPassword)
      errors["confirmPassword"] = "Passwords do not match";
    if (!formData.gender) errors["gender"] = "You must pick a gender";
    if (!formData.profession) errors["profession"] = "Profession is required";
    if (!formData.industry) errors["industry"] = "You must select an industry";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      try {
        const response = await axios.post(`${server}/users/create`, {
          salutation: formData.salutation,
          username: formData.username,
          email: formData.eMail,
          firstName: formData.firstName,
          lastName: formData.lastName,
          password: formData.password,
          profession: formData.profession,
          gender: formData.gender,
          industry: formData.industry,
          phoneNumber: formData.phoneNumber,
        });
        console.log("Form data submitted:", response.data);
         toast.success("Sign up Successful check your mail to activate your account");
        navigate("/");
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <div className="flex w-full md:h-screen">
      <div className="hidden relative w-1/2 lg:flex items-center justify-center bg-gray-200 dark:bg-[#490800] dark:text-white p-10">
        <Link to="/register">
          <div
            className="relative  bg-no-repeat bg-[length:100%_100%] w-full p-6 text-black text-md font-extralight h-full"
            style={{
              backgroundImage: `url(${smallSphere})`,
            }}
          >
            <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center my-2 lg:my-10">
              Create a TOMA Account
              <span className="bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text">
                {" "}
                in 3 Easy Steps
              </span>{" "}
            </h2>
            <p className="mt-5 text-lg text-center text-neutral-500">
              Fill in your basic details to get started: your name, email,
              information . about your business operations
            </p>{" "}
            <p className="mt-5 text-lg text-center text-neutral-500">
              Verify Your Email by checking your inbox for a verification email
              from TOMA. Click the verification link to confirm your email
              address.
            </p>
            <p className="mt-5 text-lg text-center text-neutral-500">
              Log in to your new account and complete your profile
            </p>
            <div>
              <h2 className="text-2xl font-bold text-center my-2 lg:my-10">
                To Register your Business
                <div className="bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text">
                  {" "}
                  Click Here
                </div>
              </h2>
            </div>
           
            <GradientLight />
            <div
              className="absolute inset-0.5 bg-n-8"
              style={{ clipPath: "url(#benefits)" }}
            >
              <div className="absolute inset-0 opacity-0 transition-opacity hover:opacity-10">
                <img
                  src={legal}
                  width={380}
                  height={362}
                  alt="small"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <ClipPath />
          </div>
        </Link>
      </div>
      {/* <div className="hidden relative w-1/2 h-full lg:flex items-center justify-center bg-gray-200">
        <div className="w-60 h-60 rounded-full bg-gradient-to-r from-orange-500 to-red-800 animate-spin" />
        <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg" />
      </div> */}
      <div className="w-full flex items-center justify-center lg:w-1/2 p-8 mt-10 mb-10 md:mb-0 md:mt-0">
        <div className=" p-10 rounded-3xl bg-white dark:bg-[#490800] dark:text-white border-2 border-gray-100">
          <Link to="/">
            <img className="h-20 w-20 mx-auto" src={logo} alt="Logo" />
          </Link>

          <p className="font-medium text-center text-lg text-gray-500 mb-1">
            Register your Account
          </p>
          <div className=" md:hidden w-full mb-1">
            <h4>
              NOTE: To Register your Business User
            <Link to="/register" className="font-bold ">
                {" "}
                Click Here
              </Link>{" "}
            </h4>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
             <div>
  <select
    className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
      "border-red-500": errors["salutation"],
      "border-gray-300": !errors["salutation"],
    })}
    id="salutation"
    name="salutation"
    value={formData.salutation}
    onChange={handleChange}
    required
  >
    <option value="" disabled>Select Salutation</option>
    <option value="Dr">Dr</option>
    <option value="Madam">Madam</option>
    <option value="Master">Master</option>
    <option value="Mis">Miss</option>
    <option value="Mr">Mr</option>
    <option value="Mrs">Mrs</option>
    <option value="Ms">Ms</option>
    <option value="Mx">Mx</option>
    <option value="Prof">Prof</option>
  </select>
  {errors["salutation"] && (
    <p className="text-red-500 text-sm">{errors["salutation"]}</p>
  )}
</div>

              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["username"],
                    "border-gray-300": !errors["username"],
                  })}
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  placeholder="Enter your username*"
                />
                {errors["username"] && (
                  <p className="text-red-500 text-sm">{errors["username"]}</p>
                )}
              </div>
              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["firstName"],
                    "border-gray-300": !errors["firstName"],
                  })}
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="First Name*"
                />
                {errors["firstName"] && (
                  <p className="text-red-500 text-sm">{errors["firstName"]}</p>
                )}
              </div>
              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["lastName"],
                    "border-gray-300": !errors["lastName"],
                  })}
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Last Name*"
                />
                {errors["lastName"] && (
                  <p className="text-red-500 text-sm">{errors["lastName"]}</p>
                )}
              </div>

              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["email"],
                    "border-gray-300": !errors["email"],
                  })}
                  type="eMail"
                  id="eMail"
                  name="eMail"
                  value={formData.eMail}
                  onChange={handleChange}
                  required
                  placeholder="Email*"
                />
                {errors["eMail"] && (
                  <p className="text-red-500 text-sm">{errors["eMail"]}</p>
                )}
              </div>
              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["phoneNumber"],
                    "border-gray-300": !errors["phoneNumber"],
                  })}
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  placeholder="Phone Number*"
                />
                {errors["phoneNumber"] && (
                  <p className="text-red-500 text-sm">
                    {errors["phoneNumber"]}
                  </p>
                )}
              </div>
              <div>
                <div className="relative">
                  <input
                    className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                      "border-red-500": errors["password"],
                      "border-gray-300": !errors["password"],
                    })}
                    type={visibleOne ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    placeholder="Password*"
                  />
                  {visibleOne ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisibleOne(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisibleOne(true)}
                    />
                  )}
                </div>

                {errors["password"] && (
                  <p className="text-red-500 text-sm">{errors["password"]}</p>
                )}
              </div>
              <div>
                <div className="relative">
                  <input
                    className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                      "border-red-500": errors["confirmPassword"],
                      "border-gray-300": !errors["confirmPassword"],
                    })}
                    type={visibleTwo ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder="Confirm Password*"
                  />
                  {visibleTwo ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisibleTwo(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisibleTwo(true)}
                    />
                  )}
                </div>
                {errors["confirmPassword"] && (
                  <p className="text-red-500 text-sm">
                    {errors["confirmPassword"]}
                  </p>
                )}
              </div>
              <div>
                <select
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["gender"],
                    "border-gray-300": !errors["gender"],
                  })}
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Gender*</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors["gender"] && (
                  <p className="text-red-500 text-sm">{errors["gender"]}</p>
                )}
              </div>
              {/* <div>
                <select
                  className={classNames("w-full p-3 border rounded", {
                    "border-red-500": errors["userType"],
                    "border-gray-300": !errors["userType"],
                  })}
                  id="userType"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select user type</option>
                  <option value="Single User">Single User</option>
                  <option value="Law Student">Law Student</option>
                  <option value="SME">SME</option>
                  <option value="EnterPrise">EnterPrise</option>
                </select>
                {errors["userType"] && (
                  <p className="text-red-500 text-sm">{errors["userType"]}</p>
                )}
              </div> */}
              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["profession"],
                    "border-gray-300": !errors["profession"],
                  })}
                  type="text"
                  id="profession"
                  name="profession"
                  value={formData.profession}
                  onChange={handleChange}
                  required
                  placeholder="Profession*"
                />
                {errors["profession"] && (
                  <p className="text-red-500 text-sm">{errors["profession"]}</p>
                )}
              </div>
               <div>
      <select
        name="industry"
        value={formData.industry}
        onChange={handleChange}
        className="w-full mt-2 p-2 border border-gray-300 rounded dark:bg-[#490800] dark:text-white"
      >
        <option value="">Select Industry</option>
        {industries.map((industry, index) => (
          <option key={index} value={industry.name}>
            {industry.name}
          </option>
        ))}
      </select>
      {errors["industry"] && (
        <p className="text-red-500 text-sm">{errors["industry"]}</p>
      )}
    </div>
              <div>
                <input
                  className={classNames("w-full p-3 border rounded dark:bg-[#490800] dark:text-white", {
                    "border-red-500": errors["companyName"],
                    "border-gray-300": !errors["companyName"],
                  })}
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Institution Name"
                />
                {errors["companyName"] && (
                  <p className="text-red-500 text-sm">
                    {errors["companyName"]}
                  </p>
                )}
              </div>
              
            </div>
            <button
              type="submit"
              className="mt-8 group relative w-full flex justify-center py-4 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <svg
                  className="animate-spin h-5 w-5 mr-3 border-t-2 border-white border-solid rounded-full"
                  viewBox="0 0 24 24"
                ></svg>
              )}
              Register
            </button>
            <div
              className=" mt-2 w-full flex items-center justify-center"
            >
              <h4>
                Already have an account? Click{" "}
                <span onClick={login} className="font-bold cursor-pointer">
                  {" "}
                  Login
                </span>{" "}
              </h4>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
