import React from "react";
import { missions } from "../../../static/data";

const Mission = () => {
  return (
    <div className="flex flex-col items-center mt-6 lg:mt-10">
      <h1 data-aos="zoom-up" className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        A customer-centric
        <span className="text-[#A71200]">
          {" "}
          approach...
        </span>
      </h1>
      <p data-aos="fade-up" className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
        A Our journey began with a simple yet powerful idea: to create a
        platform that demystifies legal complexities, empowers individuals and
        businesses, and ensures regulatory compliance with ease.
      </p>

      <div className="flex flex-wrap mt-10 lg:mt-20">
        {missions.map((missions, index) => (
          <div key={index} className="w-full sm:w-1/2 lg:w-1/2">
            <div className="flex">
              <div data-aos="fade-up" className="flex mx-6 h-10 w-10 p-2 text-white bg-orange-700 justify-center items-center rounded-full">
                {missions.icon}
              </div>
              <div>
                <h5 data-aos="fade-up" className="mt-1 mb-6 text-xl">{missions.text}</h5>
                <p data-aos="fade-up" className="text-md p-2 mb-20 text-neutral-500">
                  {missions.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mission;
