// AgreementThumbnails Component (User selects the agreement)
import React from 'react';

const AgreementThumbnails = ({ onSelectAgreement }) => {
  const agreements = [
    'BUSINESS CONSULTANT AGREEMENT',
    'EMPLOYMENT AGREEMENT',
    'MEMORANDUM OF UNDERSTANDING',
    'NON-DISCLOSURE AGREEMENT',
    'PRE-INCORPORATION AGREEMENT',
    'TENANCY AGREEMENT',
    'AGREEMENT GRANTING MEDIUM TERM LOAN FACILITY',
    // 'DEMAND TO GUARANTOR FOR PAYMENT',
    'INDEMNITY AGREEMENT',
    'LETTER OF CHARGE',
    'LETTER OF CREDIT',
    'LETTER OF DEMAND',
    'LOAN AGREEMENT',
    'MULTIMEDIA PUBLICITY/PRIVACY RELEASE',
    'TRANSFER OF A BILL OF SALE',
    'Tenant\'s Notice of Intent to Move Out',
    // 'NOTICE OF REVOCATION',
    'LETTER OF INTENT',
    'NOTICE OF DEFAULT',

  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {agreements.map((agreement, index) => (
       
        <div
            key={index}
            className="bg-white dark:bg-[#780D00] shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105"
          >
            <div className="flex flex-col items-center justify-center">
              <div className="w-16 h-16 bg-gradient-to-r from-orange-500 to-red-800 text-white rounded-full flex items-center justify-center mb-4">
                 {agreement.split(' ')[0][0]}
              </div>
              <h3 className="text-lg font-semibold text-center mb-2">{agreement}</h3>
              <button
                 onClick={() => onSelectAgreement(agreement)}
                className="mt-4 bg-gradient-to-r from-orange-500 to-red-800 text-white py-2 px-4 rounded-lg hover:opacity-50"
              >
                View Details
              </button>
            </div>
          </div>
      ))}
    </div>
  );
};

export default AgreementThumbnails;


