// src/redux/reducers/geoipReducer.js
import { FETCH_GEOIP_REQUEST, FETCH_GEOIP_SUCCESS, FETCH_GEOIP_FAILURE } from '../actions/geoipActionType';

const initialState = {
  isoCode: '', // Store the short code for the country
  availableCountries: [], // List of all country ISO codes (you can provide this list or get from API)
  loading: false,
  error: null,
};

// Reducer function to handle GeoIP states
export const geoipReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GEOIP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_GEOIP_SUCCESS:
      return {
        ...state,
        loading: false,
        isoCode: action.payload.isoCode, // Set the ISO code from API response
        availableCountries: action.payload.availableCountries || state.availableCountries, // Set available countries for dropdown
      };
    case FETCH_GEOIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};