export const loginSuccess = (user) => {
  // Save user to localStorage
  localStorage.setItem("user", JSON.stringify(user));
  console.log(user.email);
  return {
    type: "LOGIN_SUCCESS",
    payload: user,
  };
};

export const logout = () => {
  // Remove user from localStorage
  localStorage.removeItem("user");
  return {
    type: "LOGOUT",
  };
};

export const checkAuthState = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return {
      type: "LOGIN_SUCCESS",
      payload: JSON.parse(user),
    };
  } else {
    return {
      type: "LOGOUT",
    };
  }
};
