import React from 'react';

const YourTransactions = ({ transactions }) => {
  return (
    <div className="relative flex flex-col h-full min-w-0 mb-6 break-words bg-white border-0 shadow-xl dark:!bg-[#a711007a] dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div className="p-6 px-4 pb-0 mb-0 border-b-0 rounded-t-2xl">
        <div className="flex flex-wrap -mx-3">
          <div className="max-w-full px-3 md:w-1/2 md:flex-none">
            <h6 className="mb-0 dark:text-white">Your Transactions</h6>
          </div>
          <div className="flex items-center justify-end max-w-full px-3 dark:text-white/80 md:w-1/2 md:flex-none">
            <i className="mr-2 far fa-calendar-alt"></i>
            <small>{transactions.dateRange}</small>
          </div>
        </div>
      </div>
      <div className="flex-auto p-4 pt-6">
        <h6 className="mb-4 text-xs font-bold leading-tight uppercase dark:text-white text-slate-500">Newest</h6>
        <ul className="flex flex-col pl-0 mb-0 rounded-lg">
          {transactions.newest.map((transaction) => (
            <li
              key={transaction.id}
              className="relative flex justify-between px-4 py-2 pl-0 mb-2 border-0 rounded-t-inherit text-inherit rounded-xl"
            >
              <div className="flex items-center">
                <button
                  className={`leading-pro ease-in text-xs bg-150 w-6.5 h-6.5 p-1.2 rounded-3.5xl tracking-tight-rem bg-x-25 mr-4 mb-0 flex cursor-pointer items-center justify-center border border-solid ${
                    transaction.type === 'debit' ? 'border-red-600 text-red-600' : 'border-emerald-500 text-emerald-500'
                  } transition-all hover:opacity-75`}
                >
                  <i className={`fas fa-arrow-${transaction.type === 'debit' ? 'down' : 'up'} text-3xs`}></i>
                </button>
                <div className="flex flex-col">
                  <h6 className="mb-1 text-sm leading-normal dark:text-white text-slate-700">{transaction.name}</h6>
                  <span className="text-xs leading-tight dark:text-white/80">{transaction.date}</span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p
                  className={`relative z-10 inline-block m-0 text-sm font-semibold leading-normal text-transparent bg-gradient-to-tl ${
                    transaction.type === 'debit' ? 'from-red-600 to-orange-600' : 'from-emerald-500 to-teal-400'
                  } bg-clip-text`}
                >
                  {transaction.amount}
                </p>
              </div>
            </li>
          ))}
        </ul>

        <h6 className="mt-6 mb-4 text-xs font-bold leading-tight uppercase dark:text-white text-slate-500">Yesterday</h6>
        <ul className="flex flex-col pl-0 mb-0 rounded-lg">
          {transactions.yesterday.map((transaction) => (
            <li
              key={transaction.id}
              className="relative flex justify-between px-4 py-2 pl-0 mb-2 border-0 rounded-t-inherit text-inherit rounded-xl"
            >
              <div className="flex items-center">
                <button
                  className={`leading-pro ease-in text-xs bg-150 w-6.5 h-6.5 p-1.2 rounded-3.5xl tracking-tight-rem bg-x-25 mr-4 mb-0 flex cursor-pointer items-center justify-center border border-solid ${
                    transaction.type === 'debit' ? 'border-red-600 text-red-600' : 'border-emerald-500 text-emerald-500'
                  } transition-all hover:opacity-75`}
                >
                  <i className={`fas fa-arrow-${transaction.type === 'debit' ? 'down' : 'up'} text-3xs`}></i>
                </button>
                <div className="flex flex-col">
                  <h6 className="mb-1 text-sm leading-normal dark:text-white text-slate-700">{transaction.name}</h6>
                  <span className="text-xs leading-tight dark:text-white/80">{transaction.date}</span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <p
                  className={`relative z-10 inline-block m-0 text-sm font-semibold leading-normal text-transparent bg-gradient-to-tl ${
                    transaction.type === 'debit' ? 'from-red-600 to-orange-600' : 'from-emerald-500 to-teal-400'
                  } bg-clip-text`}
                >
                  {transaction.amount}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default YourTransactions;
