import React from 'react'
import Footer from "../components/Layout/Footer.jsx";
import Payment from "../components/Route/Payment.jsx";
import Navbar from "../components/Layout/Navbar.jsx";

const PaymentPage = () => {
  return (
   
    <div className="dark:bg-[#490800] dark:text-white ">
      <Navbar />

      <div className="max-w-7xl mx-auto px-6">
        <Payment />
       
      </div>

      <Footer />
    </div>
  )
}

export default PaymentPage