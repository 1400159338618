// src/redux/actions.js
import axios from 'axios';
import { FETCH_GEOIP_REQUEST, FETCH_GEOIP_SUCCESS, FETCH_GEOIP_FAILURE } from './geoipActionType';

export const fetchGeoIPRequest = () => ({
  type: FETCH_GEOIP_REQUEST,
});

export const fetchGeoIPSuccess = (data) => ({
  type: FETCH_GEOIP_SUCCESS,
  payload: {
    isoCode: data.iso_code, // Use ISO short code
    availableCountries: Object.keys(data.names), // Assuming API provides available countries in `names`
  },
});

export const fetchGeoIPFailure = (error) => ({
  type: FETCH_GEOIP_FAILURE,
  payload: error,
});

export const fetchGeoIP = () => {
  return async (dispatch) => {
    dispatch(fetchGeoIPRequest());
    try {
      const response = await axios.get('https://api-gw.tomatech.online/v1/geoip');
      const geoData = response.data;
      dispatch(fetchGeoIPSuccess(geoData));
    } catch (error) {
      dispatch(fetchGeoIPFailure(error.message));
    }
  };
};