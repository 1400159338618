import React, { useState } from 'react';

const faqData = {
  "Legal Database FAQs": [
    { question: "What does the TOMA Legal Database offer?", answer: "The TOMA Legal Database provides comprehensive access to all Nigerian laws, cases, and rulings, organized for easy navigation." },
    { question: "How do I search for specific laws or cases?", answer: "Simply use the search bar at the top of the page. Filter options allow for even more refined results. Explore Now" },
    { question: "Is the database updated regularly?", answer: "Yes, our database is continuously updated to include the latest legal developments." },
    { question: "Can I access the database on multiple devices?", answer: "Absolutely! TOMA is accessible from any device, ensuring you can research anywhere, anytime. Start Your Research" }
  ],
  "Customizable Templates FAQs": [
    { question: "What are TOMA's Customizable Templates?", answer: "TOMA offers a library of legal and compliance templates that can be tailored to your specific business needs." },
    { question: "Can I save and reuse my templates?", answer: "Yes, you can save your customized templates and reuse them for future needs." },
    { question: "How secure are the templates?", answer: "All templates are stored in a secure environment, accessible only to you and authorized users. Customize Yours" },
    { question: "Are there templates available for different industries?", answer: "Yes, we offer templates across various industries, ensuring relevance and ease of use." }
  ],
  "The Vault FAQs": [
    { question: "What is TOMA’s Vault?", answer: "The Vault is your secure, encrypted digital space for storing files, enabling e-signatures, and tracking document views." },
    { question: "How does the digital signature work?", answer: "The Vault allows you to digitally sign documents, with the assurance that all signatures are legally binding and secure. Get Started." },
    { question: "Can I track who views my documents?", answer: "Yes, the Vault tracks document views and interactions, providing a full audit trail." },
    { question: "How secure is the Vault?", answer: "The Vault uses advanced encryption to ensure your documents are protected and confidential." }
  ],
  "Pricing FAQs": [
    { question: "What are the pricing options for TOMA?", answer: "TOMA offers flexible pricing based on the number of users (seats) or sessions. We have individual plans and packages tailored to different business sizes. View Pricing Plans" },
    { question: "Is there a free trial available?", answer: "Yes, we offer a free trial period for you to explore TOMA’s features before committing." },
    { question: "Can I upgrade or downgrade my plan?", answer: "Absolutely, you can upgrade, downgrade, or cancel your subscription at any time." }
  ],
  "Upcoming Products FAQs": [
    { question: "What’s new at TOMA?", answer: "We’re excited to announce upcoming products including the TOMA KYC Portal, TOMA Marketplace, and TOMA Library. Stay tuned for more details!" },
    { question: "When will these new products be available?", answer: "These exciting features are launching soon! Keep an eye out for updates. Join Our Waitlist" },
    { question: "How can I stay informed about these new products?", answer: "Sign up for our newsletter or follow us on social media to be the first to know about launches." }
  ]
};

const Faq = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h1>
      
      {Object.keys(faqData).map((category, categoryIndex) => (
        <div key={categoryIndex} className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-indigo-600">{category}</h2>
          {faqData[category].map((item, index) => (
            <div key={index} className="mb-4">
              <button
                className="w-full flex justify-between items-center py-3 px-4 bg-gray-100 hover:bg-gray-200 rounded-lg transition"
                onClick={() => toggleQuestion(`${categoryIndex}-${index}`)}
              >
                <span className="text-lg font-medium text-gray-800">{item.question}</span>
                <span className="text-2xl">{openQuestion === `${categoryIndex}-${index}` ? '-' : '+'}</span>
              </button>
              {openQuestion === `${categoryIndex}-${index}` && (
                <div className="px-4 py-2 bg-white border border-gray-200 rounded-b-lg text-gray-700">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Faq;
