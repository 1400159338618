import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./utils/keycloak";
import { Provider } from "react-redux";
import store from "./redux/store";
// import { PersistGate } from "redux-persist/integration/react";

const eventLogger = (event, error) => {
  console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens) => {
  console.log("onKeycloakTokens", tokens);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <ReactKeycloakProvider
  //   authClient={keycloak}
  //   onEvent={eventLogger}
  //   onTokens={tokenLogger}
  //   initOptions={{
  //     onLoad: "check-sso",
  //     pkceMethod: "S256",
  //   }}
  // >
  <Provider store={store}>
    <App />
  </Provider>
  // </ReactKeycloakProvider>
);

reportWebVitals();
