import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import image1 from "../../../../Assests/img/profile/image1.png";
import Card from "../../../common/card";

const FavouriteList = () => {
  const [contracts, setContracts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const savedContracts = JSON.parse(localStorage.getItem("contracts") || "[]");
    setContracts(savedContracts);
  }, []);

  const handleContractClick = () => {
    navigate('/dashboard/favourite');
  };

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          My Docs List
        </h4>
      </div>
      {/* Display only the first 5 contracts */}
      {contracts.slice(0, 4).map(contract => (
        <div
          key={contract.id}
          onClick={handleContractClick}
          className="flex w-full mt-3 items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-[#a711007a] dark:shadow-none cursor-pointer"
        >
          <div className="flex items-center">
            <div>
              <img className="h-[20px] w-[20px] rounded-full" src={image1} alt="" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-navy-700 dark:text-white">
                {contract.title}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

export default FavouriteList;
