import React from 'react'
import contact  from "../../Assests/Image/contact-us.jpg"


const Office = () => {
  

  return (
    <div className="mt-10">
      <h2 data-aos="zoom-in" className="text-3xl sm:text-5xl lg:text-6xl text-center mt-6 tracking-wide">
        Our{" "}
        <span className="text-[#A71200]">
          Office Address
        </span>
      </h2>
      <p data-aos="fade-up" className="mt-10 text-lg text-center text-neutral-500 mx-auto">
        TOMA stands out as the ultimate legal compliance solution designed to
        meet the unique needs of every user.With TOMA, navigate legal
        complexities effortlessly, stay ahead of regulations, and ensure
        compliance with confidence.
      </p>

      <div className="flex flex-wrap items-center justify-center mt-8">
        <div className="p-2 w-full lg:w-1/2">
        <div data-aos="zoom-in"><img src={contact} alt="contact" className="rounded-lg" /></div>
          
        </div>
        <div className="pt-12 w-full lg:w-1/2 mx-auto">
          <div className="flex items-center justify-center mb-12 text-center w-full">
            <div>
              <h2 data-aos="fade-up" className="text-xl font-bold mb-5">Lagos Nigeria Address</h2>
              <p data-aos="fade-up" className="mt-10 text-lg text-center text-neutral-500 mx-auto">
                Ikoyi, Lagos, Nigeria
              </p>
              {/* <p data-aos="fade-up" className=" text-lg text-center text-neutral-500 mx-auto">
                
              </p> */}
              <p data-aos="fade-up" className=" text-lg text-center text-neutral-500 mx-auto">
                info@tomalegal.online
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Office

