import React, { useState } from "react";
import video1 from "../../Assests/video/video1.mp4";
import video2 from "../../Assests/video/video2.mp4";

const HeroSection = () => {
   const [country, setCountry] = useState("");
  return (
    <div className="flex flex-col items-center mt-6 lg:mt-10">
      <h1 data-aos="zoom-in" className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        Your All-In-One Legal
        <span className="text-[#A71200]">
          {" "}
          Compliance Solution
        </span>
      </h1>
      <p data-aos="fade-up" className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
        TOMA stands out as the ultimate legal compliance solution designed to
        meet the unique needs of every user.With TOMA, navigate legal
        complexities effortlessly, stay ahead of regulations, and ensure
        compliance with confidence.
      </p>
      {/* <div className="flex justify-center my-10">
        <a
          href="#"
          className="bg-gradient-to-r from-orange-500 to-orange-800 py-3 px-4 mx-3 rounded-md"
        >
          Get Started
        </a>
        <a href="#" className="py-3 px-4 mx-3 rounded-md border">
          Documentation
        </a>
      </div> */}
      {/* search box */}
      {/* <div data-aos="fade-up" className="flex items-center justify-between gap-5 my-10">
        <input
          type="text"
          placeholder="Enter your country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="h-[50px] w-[100%] px-5 border-[#B2BEB5] border-[2px] rounded-md "
        />
        <button className=" right-2 cursor-pointer rounded-md bg-gradient-to-r from-orange-500 to-orange-800 w-[200px] h-[50px] text-[#fff]">
          Get Started
        </button>
      </div> */}
      <div data-aos="fade-up" className="flex mt-10 justify-center">
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          loop
          muted
          className="rounded-lg w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default HeroSection;
