import React, { useState } from "react";
import styles from "../../../styles/styles";
import vault from "../../../Assests/Image/vault.png";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Vault = () => {
   const navigate = useNavigate();
  
    const user = useSelector((state) => state.auth.user);
    const [isOpen, setIsOpen] = useState(false);

       const toggleModal = () => {
    setIsOpen(!isOpen);
  };

    const loginPage = () => {
  navigate('/sign-up');
  }

   const paymentPage = () => {
     navigate('/price');
  }
  return (
    <div className={`${styles.section}`}>
      <div lassName={`${styles.heading}`}>
        <div className="md:flex md:items-start md:justify-around mt-20 mb-20">
          <div className="md:w-[40%] mb-10 md:mb-0 ">
            {/* <h1  data-aos="fade-up" className="lg:text-5xl text-3xl lg:leading-normal font-semibold text-black">
              KYC Portal and ID
            </h1> */}
            <p  data-aos="fade-up" className="mt-5">
              TOMA’s KYC Portal automates identity verification, streamlining Know Your Customer (KYC) processes for businesses with real-time checks and a secure digital ID system, ensuring compliance with anti-money laundering (AML) regulations.
            </p>{" "}
            <p  data-aos="fade-up" className="mt-5">
             Click on the button below to book a section.
            </p>

             <button
              onClick={toggleModal}
             className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
              Request a Demo
            </button>
             
          </div>
          <div  data-aos="zoom-in" className="md:w-[40%] flex items-center justify-center">
            <img src={vault} alt="vault" />
          </div>
        </div>
      </div>

       {isOpen && (

         <>
          {/* Dark overlay */}
          <div
            className="fixed inset-0 z-40 bg-[#000] opacity-50"
            onClick={toggleModal} // Close modal when clicking on overlay
          ></div>
        <div
          id="authentication-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-red-900">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-800">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Fill the Form
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 1"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4" action="#">
                <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      autocomplete="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Phone Number
                    </label>
                    <input
                      type="number"
                      name="number"
                       autoComplete="number"
                      placeholder="Phone Number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                       autoComplete="text"
                      placeholder="Subject"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Write Comment
                    </label>
                    <textarea                     
                     placeholder="Write comments"
                      name="story"
                      rows="5"
                      cols="33"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>                 
                       
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
                  >
                    SUBMIT NOW
                  </button>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}

export default Vault