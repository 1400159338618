import { benefits } from "../../../static/data";
import Heading from "../../common/Heading";
import Section from "../../common/Section";
import Arrow from "../../../Assests/Svg/Arrow";
import { GradientLight } from "../../common/design/Benefits";
import ClipPath from "../../../Assests/Svg/ClipPath";
import { Link } from "react-router-dom";

const Benefits = () => {
  return (
    <Section id="features">
      <div data-aos="fade-up" className="container relative z-2 ">
        <Heading
          className="mb-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center"
          title="Our Products and Services"
          title2=""
        />

        <div className="flex flex-wrap items-center justify-center gap-10 mb-10 mx-auto">
          {benefits.map((item) => (
            <Link data-aos="fade-up" to={item.linkUrl}>
              {" "}
              <div
                className="block relative p-0.5 bg-no-repeat bg-[length:100%_100%] md:max-w-[24rem]"
                style={{
                  backgroundImage: `url(${item.backgroundUrl})`,
                }}
                key={item.id}
              >
                <div className="relative z-2 flex flex-col min-h-[22rem] p-[2.4rem] pointer-events-none">
                  <h5 className="h5 font-bold mb-5">{item.title}</h5>
                  <p className="body-2 mb-6 italic text-n-3 text-[#808080]">{item.text}</p>
                  <div className="flex items-center mt-auto">
                    <img
                      src={item.iconUrl}
                      width={48}
                      height={48}
                      alt={item.title}
                    />
                    <p className="ml-auto font-code text-xs font-bold text-n-1 uppercase tracking-wider">
                      Explore more
                    </p>
                    <Arrow />
                  </div>
                </div>

                {item.light && <GradientLight />}

                <div
                  className="absolute inset-0.5 bg-n-8"
                  style={{ clipPath: "url(#benefits)" }}
                >
                  <div className="absolute inset-0 opacity-0 transition-opacity hover:opacity-10">
                    {item.imageUrl && (
                      <img
                        src={item.imageUrl}
                        width={380}
                        height={362}
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                </div>

                <ClipPath />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Benefits;
