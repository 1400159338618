import React from 'react';
import Banner from "./components/Banner.jsx";
import RecentFiles from './components/RecentFiles.jsx';
import FavouriteList from "./components/FavouriteList.jsx";
// import Notification from "./components/Notification";

// import Storage from "./components/Storage";
// import Upload from "./components/Upload"

const Dashboard = () => {
  

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        
      <div className="z-0 col-span-5 lg:!mb-0">
           <RecentFiles />
        </div>
        <div className="col-span-3 lg:!mb-0">
          <FavouriteList />
        </div>

        
        <div className="col-span-4 lg:!mb-0">
          <Banner />
        </div>
      </div>
      {/* all project & ... */}

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
         {/* <Upload /> */}
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          {/* <General /> */}
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          {/* <Notification /> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
