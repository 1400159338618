import React from "react";
import { Menu, X } from "lucide-react";
import { useState, useEffect } from "react";
import logo from "../../Assests/Svg/logo.svg";
import { navItems } from "../../static/data";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import { fetchGeoIP } from "../../redux/actions/geoipActions";
import { setCurrency } from '../../redux/actions/currencyActions';
import Dropdown from "../common/dropdown";
import avatar from "../../Assests/Image/avatar.png";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { FiAlignJustify } from "react-icons/fi";
import { BsArrowBarUp } from "react-icons/bs";
import { HiX } from "react-icons/hi";
import { FaCartShopping } from "react-icons/fa6";
import { IoMdLogIn  } from "react-icons/io";
 import axios from 'axios';

const Navbar = ({ active, props, handleOrderPopup }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  //  const { onOpenSidenav } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [countryCode, setCountryCode] = useState(''); 
  const [countryClass, setCountryClass] = useState('text-black'); 
    const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);


//  const [searchTerm, setSearchTerm] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

    // Get the current path
  const location = useLocation();

  // Check if the current path is /library or any subpath of /library
  const isLibraryPath = location.pathname.startsWith('/products/library');

  

  // Function to fetch search results
  const searchCases = async (query) => {
    try {
      const response = await axios.get('https://api-gw.tomatech.online/v1/legaldatabase/cases', {
        params: { search: query }
      });
      return response.data.collection;
    } catch (error) {
      console.error('Error fetching cases:', error);
      return [];
    }
  };

  // Handle input change with debouncing
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Clear the debounce timeout if it's set
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new debounce timeout
    const timeout = setTimeout(async () => {
      if (value.length > 0) {
        setIsLoading(true);
        const results = await searchCases(value);
        setSearchResults(results);
        setIsLoading(false);
      } else {
        // Clear the results when input is cleared
        setSearchResults([]);
      }
    }, 300); // Delay the API call by 300ms

    setDebounceTimeout(timeout);
  };

  const handleSelect = (caseItem) => {
    setSelectedCase(caseItem);
  };
  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const handleLogout = () => {
      dispatch(logout());
    };

  const { isoCode, loading, error } = useSelector((state) => state.geoip);
  
  // Local state for handling selected country
  const [selectedCountry, setSelectedCountry] = useState(isoCode);

  useEffect(() => {
    // Fetch GeoIP data and set the initial selected country
    dispatch(fetchGeoIP());
  }, [dispatch]);

  useEffect(() => {
    if (isoCode) {
      setSelectedCountry(isoCode); // Update when GeoIP is fetched
    }
  }, [isoCode]);

  // const handleCountryChange = (e) => {
  //   setSelectedCountry(e.target.value); // Change the selected country
  // };

  // Function to map country codes to specific Tailwind text classes
  const getCountryTextClass = (countryCode) => {
    switch (countryCode) {
      case 'NG':
        return 'text-green-500'; // Green for Nigeria
      case 'KE':
        return 'text-red-500'; // Red for Kenya
      case 'US':
        return 'text-blue-500'; // Blue for USA
      case 'IN':
        return 'text-orange-500'; // Orange for India
      case 'GB':
        return 'text-indigo-600'; // Indigo for the UK
      default:
        return 'text-black'; // Default black for other countries
    }
  }; 

  useEffect(() => {
    const fetchGeoIPData = async () => {
      try {
        const response = await axios.get('https://api-gw.tomatech.online/v1/geoip'); // Use Axios to get geoIP data
        const data = response.data;

        if (data && data.iso_code) {
          const detectedCountryCode = data.iso_code; // Get the country code from the response
          setCountryCode(detectedCountryCode); // Set the country code in state

          const currency = detectedCountryCode === 'NG' ? '₦' : '$'; // Set currency to NGN if Nigeria, otherwise USD

          // Set the Tailwind text class based on the country code
          setCountryClass(getCountryTextClass(detectedCountryCode));

          // Dispatch the action to set the currency and country code in Redux
          dispatch(setCurrency(currency, detectedCountryCode));
        } else {
          console.error('Unable to get the country code from IP data.');
        }
      } catch (error) {
        console.error('Error fetching the IP and country data:', error);
      }
    };

    fetchGeoIPData(); // Call the function to fetch GeoIP data
  }, [dispatch]);


const CaseDetailsModal = ({ caseData, onClose }) => {
  return (
    <div className="fixed inset-0 !z-[800] flex items-center justify-center ">
      <div className="w-full max-w-4xl mt-[600px] max-h-[500px] overflow-y-auto rounded-lg bg-white p-6 dark:text-white dark:bg-[#780D00]">
        <button onClick={onClose} className="float-right text-gray-500 dark:text-white bg-gradient-to-b from-orange-500 to-red-800 px-4 py-2 rounded">
          Close
        </button>
        <h2 className="text-xl font-bold">{caseData.title}</h2>
        
        <div className="mt-4">
          <p><strong>Suit No:</strong> {caseData.suitNo}</p>
          <p><strong>Court Division:</strong> {caseData.courtDivision}</p>
          <p><strong>Court Type:</strong> {caseData.courtType}</p>
          <p><strong>Date:</strong> {caseData.dateTitle}</p>
          <p><strong>Appellants:</strong> {caseData.appellants.join(', ')}</p>
          <p><strong>Respondents:</strong> {caseData.respondents.join(', ')}</p>
          
          <h3 className="font-semibold mt-4">Headnotes</h3>
          <ul className="list-disc list-inside">
            {caseData.headnoteList.map((headnote, index) => (
              <li key={index} className="mb-2">
                <strong>{headnote.name}</strong>: {headnote.description}
              </li>
            ))}
          </ul>
          <p><strong>CONTENT:</strong> {caseData.content}</p>
        </div>
      </div>
    </div>
  );
};


  return (
    <nav className="sticky top-0 z-50 py-1 backdrop-blur-lg border-b border-neutral-700/80">
      <div className="px-5 lg:px-20 mx-auto relative lg:text-">
        <div className="flex justify-between items-center">
          <div className="flex items-center justify-start gap-2 ">
            <div className="flex items-center flex-shrink-0">
              <Link to="/">
                <img className="h-20 w-20 mr-2" src={logo} alt="Logo" />
              </Link>
            </div>
            <ul className="hidden lg:flex ml-10 space-x-4">
              {navItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={`${
                      active === index + 1
                        ? "text-[#721F29]"
                        : "text-black 800px:text-[#505F98]"
                    } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer}`}
                  >
                    {item.title}
                  </Link>
                </li>                
              ))}
             
            </ul>
          </div>

         
          <div className="flex justify-end items-center gap-1 md:gap-2">
          <div className="hidden xl:block max-w-sm mx-auto bg-transparent">
          <div className='flex items-center justify-start'>
           {isLibraryPath && (
             <button
                onClick={() => handleOrderPopup()}
                className="bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 dark:text-white py-1 px-4 rounded-full flex items-center gap-3"
              >
               
                <FaCartShopping className="text-xl dark:text-white drop-shadow-sm cursor-pointer" />
              </button>
           )}

        
              <h1 className={`bg-gray-50 border font-bold border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#780D00] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${countryClass}`}>
        {countryCode}
      </h1>
              </div>
        
      </div>
          { user ? (
              <div className="relative mt-[3px] flex h-[61px] w-[280px] flex-grow items-center justify-around gap-1 rounded-full bg-gray-300 px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
       <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-[#490800] dark:text-white xl:w-[225px]">
        <p className="pl-2 pr-1 md:pl-3 md:pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search..."
          className="block h-full w-[100px] md:w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-[#490800] dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>

      {/* Display loading spinner */}
      {isLoading &&  (<svg
                    className="animate-spin h-5 w-5 mr-3 border-t-2 bg-gray border-solid rounded-full"
                    viewBox="0 0 24 24"
                  ></svg>)}

      {/* Display dropdown results */}
      {searchResults.length > 0 && (
        <div className="absolute z-10 mt-80 w-full rounded-md bg-white shadow-lg dark:text-white dark:bg-[#490800]">
          <ul className="max-h-60 overflow-y-auto">
            {searchResults.map((result) => (
              <li
                key={result.id}
                onClick={() => handleSelect(result)}
                className="cursor-pointer px-4 py-2 text-sm text-navy-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800"
              >
                {result.title}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedCase && <CaseDetailsModal caseData={selectedCase} onClose={() => setSelectedCase(null)} />}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={toggleNavbar}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-orange-500 to-red-800 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Tema Legal Plan
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-orange-500 to-red-800 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Tema Legal Plan
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        {/* start Horizon PRO */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:text-white dark:shadow-none">
             
              <Link
               
                to="/price"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-red-800 py-[11px] font-bold text-white transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-gradient-to-r from-orange-500 to-red-800  dark:hover:opacity-70 dark:active:opacity-40"
              >
                Buy TomaLegal Plan
              </Link>
              <a
                target="blank"
                href=""
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                See Documentation
              </a>
              <a
                target="blank"
                href=""
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
              >
                Try TomaLegal Free
              </a>
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="!h-10 !w-10 rounded-full"
              src={avatar}
              alt="avatar"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-gray-300 bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {user.family_name}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
              <Link
                  to="/dashboard"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Dashboard
                </Link>
                <Link
                  to="/dashboard/settings"
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <Link
                  to="/dashboard/settings"
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </Link>
                <button
                  onClick={handleLogout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
          ) : (         
            
            <>
             {/* <Link to="/login" className="hidden lg:block py-2 font-bold bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text ">
              Sign in
            </Link>
              <Link to="/register" className="hidden lg:block font-bold bg-gradient-to-r from-orange-500 to-orange-800 text-white py-2 px-3 rounded-md ">
              Sign up
            </Link> */}

            <div className=" relative mt-[3px] flex h-[61px] w-[220px] lg:!w-[300px] flex-grow items-center justify-around gap-1 rounded-full bg-gray-300 px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-[#490800] dark:text-white xl:w-[225px]">
          <p className="pl-2 pr-1 md:pl-3 md:pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            class="block h-full w-[100px] md:w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-[#490800] dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={toggleNavbar}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
       
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={           
            <IoMdLogIn className="h-8 w-8 text-gray-600 dark:text-white"/>
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-gray-300 bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-[#780D00] dark:text-white dark:shadow-none">
             
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
              <Link
                  to="/login"
                  className="text-sm text-red-500 hover:text-red-500 font-bold dark:text-white hover:opacity-[50%]"
                >
                  Login
                </Link>
                <Link
                  to="/sign-up"
                  className="mt-3 text-sm text-gray-800 font-bold dark:text-white hover:opacity-[50%]"
                >
                  Signup as Single User
                </Link>
                <Link
                  to="/register"
                  className="mt-3 text-sm text-gray-800 font-bold dark:text-white hover:opacity-[50%]"
                >
                  Signup as Business
                </Link>
               
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
            </>
            
         
          )}

                       
           
          </div>
        </div>
        {mobileDrawerOpen && (
          <div className={`lg:hidden duration-175 linear left-0 w-full fixed z-20 flex !min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-[#780D00] dark:text-white ${
        mobileDrawerOpen ? "translate-x-0" : "-translate-x-96"
      }`}>
        <div className="xl:hidden absolute top-2 left-4 block cursor-pointer bg-transparent">
       <div className="flex items-center justify-start gap-2"> <h1 className={`bg-gray-50 border font-bold border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#780D00] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${countryClass}`}>
        {countryCode}
      </h1>

       <button
                onClick={() => handleOrderPopup()}
                className="bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 dark:text-white py-1 px-4 rounded-full flex items-center gap-3"
              >
                Order
                <FaCartShopping className="text-xl dark:text-white drop-shadow-sm cursor-pointer" />
              </button></div>
      </div>
         <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={toggleNavbar}
      >
        <HiX />
      </span>

       <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
            <ul className="mb-auto pt-1 flex flex-col items-center justify-center">
              {navItems.map((item, index) => (
                <li key={index} className="py-4 font-bold">
                  <Link to={item.url} className={`${
                      active === index + 1
                        ? "text-[#721F29] dark:text-[#000]"
                        : "text-black 800px:text-[#505F98]"
                    } pb-[30px] 800px:pb-0 font-bold px-6 cursor-pointer}`}>{item.title}</Link>
             
                </li>
                  
              ))}
              
            </ul>
             <div className="flex justify-center">
              
             </div>
             <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
           
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;


