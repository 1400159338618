import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://auth.tomatech.online",
  realm: "development",
  clientId: "react-dev",
});


// keycloak.init({
//   onLoad: 'check-sso',
//   pkceMethod: 'S256',
// }).then(authenticated => {
//   if (!authenticated) {
//     console.log('User is not authenticated');
//   }
// }).catch(error => {
//   console.error('Keycloak initialization failed', error);
// });

export default keycloak;
