import React, { useState, useEffect } from 'react';
import image1 from "../../../../Assests/img/profile/image1.png";
import image2 from "../../../../Assests/img/profile/image2.png";
import image3 from "../../../../Assests/img/profile/image3.png";
import Card from "../../../common/card";
import { CiShare2 } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

const RecentFiles = () => {
   const [savedCases, setSavedCases] = useState([]);
    const [selectedCase, setSelectedCase] = useState(null);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState({});
   const navigate = useNavigate();


    useEffect(() => {
    const casesFromStorage = JSON.parse(localStorage.getItem('savedCases') || '[]');
    setSavedCases(casesFromStorage);
    const savedComments = JSON.parse(localStorage.getItem('caseComments') || '{}');
    setComments(savedComments);

  }, []);


  const handleContractClick = () => {
    navigate('/dashboard/files');
  };

  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
         My Recent Files List
        </h4>       
      </div>
      {/* RecentFiles 1 */}
       {savedCases.slice(0, 4).map((caseData) => (
      <div  key={caseData.id}  onClick={handleContractClick}  className="flex w-full mt-3 items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-[#a711007a] dark:shadow-none cursor-pointer">
        <div className="flex items-center">
          <div className="">
            <img className="h-[20px] w-[20px] rounded-full" src={image1} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-navy-700 dark:text-white">
              {caseData.title}
            </p>
           
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
          <CiShare2 />
        </div>
      </div>

       ))}
       
    
    </Card>
  )
}

export default RecentFiles