
import React, { useRef, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import Carousel from './test';
import { teamMembers } from "../static/data";

const TestPage = () => {
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

 

  const scrollLeft = () => {
    carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  // useEffect(() => {
  //   intervalRef.current = setInterval(() => {
  //     carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  //   }, 3000);

  //   return () => clearInterval(intervalRef.current);
  // }, []);

  return (
    // <Carousel />
    <div className="relative">
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
      >
        <FaChevronLeft className="h-6 w-6 text-gray-800" />
      </button>
      <div
        ref={carouselRef}
        className="flex overflow-hidden scroll-smooth space-x-4 p-4 scrollbar-hide"
      >
       {teamMembers.map((member, index) => (
          <div key={index} className="min-w-[300px] rounded-lg shadow-md overflow-hidden">
            <div className="bg-gray-100 text-center p-8 rounded-lg transition duration-300 ease-in-out hover:bg-gradient-to-r from-orange-500 to-orange-800  hover:text-white h-full">
              <img
                src={member.imageUrl}
                className="mx-auto rounded-full mb-4 w-36 h-36 object-cover"
                alt="pic"
              />
              <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
              <div className="text-gray-500 italic mb-4">{member.position}</div>
              {/* <p className="text-gray-700">{member.description}</p> */}
              <ul className="flex justify-center mt-4 mb-4">
                {member.socialLinks.map((social, idx) => (
                  <li key={idx} className="mx-2">
                    <a
                      href={social.link}
                      className={`p-2 rounded-full ${social.color}`}
                    >
                      <i className={social.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
               <p className="text-gray-700">{member.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
      >
        <FaChevronRight className="h-6 w-6 text-gray-800" />
      </button>
    </div>
  );
};

export default TestPage;




