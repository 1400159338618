import React from 'react'
import legal from "../../../Assests/Image/legal.png"
import { CheckCircle2 } from "lucide-react";
import { checklistItems } from "../../../static/data";


const Custom = () => {
  return (
    <div className="">
      <h2 data-aos="fade-up" className="mb-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj text-center ">
        Customize, Generate Contracts{" "}
        <span className="">
          and Agreements on-the-go!
        </span>
      </h2>

       <p
              data-aos="fade-up"
              className="text-sm text-gray-500 tracking-wide leading-5 text-center mb-4"
            >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque
              reiciendis inventore iste ratione ex alias quis magni at optio
            </p>

      <div className="flex flex-wrap items-center justify-center">
        <div className="p-2 w-full lg:w-1/2 mx-auto mt-6 md:mt-0 flex items-center justify-center md:justify-start">
        <div data-aos="zoom-in"> <img src={legal} alt="legal" className=" rounded-2xl"/></div>
         
        </div>
        <div className="pt-12 w-full lg:w-1/2">
          {checklistItems.map((item, index) => (
            <div key={index} className="flex mb-12">
              <div className="bg-green-400 mx-6 text-white h-10 w-10 p-2 justify-center items-center rounded-full">
                <CheckCircle2 />
              </div>
              <div>
                <h5 data-aos="fade-up" className="mt-1 mb-2 text-xl">{item.title}</h5>
                <p data-aos="fade-up" className="text-md text-neutral-500">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Custom;


