import React from 'react'
import { Link } from "react-router-dom";
import { useSelector,  } from "react-redux";

const CustomTemplate = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="flex flex-col items-center mt-6 lg:mt-10">
      <h1 className="text-4xl sm:text-6xl lg:text-7xl text-center tracking-wide">
        Customizable
        <span className="text-[#A71200]">
          {" "}
         Templates
        </span>
      </h1>
      <p className="mt-10 text-lg text-center text-neutral-500 max-w-4xl">
       Our Customizable Templates simplify legal document creation by offering pre-built, editable contracts and agreements tailored to Nigerian law, integrated with the Vault for secure storage and Digital Signature capabilities for seamless, legally binding transactions.
      </p>
      <div className="flex justify-center my-10">
      { !user ? (
        <Link
          to="/login"
          className="bg-gradient-to-r from-orange-500 to-orange-800 py-3 px-4 mx-3 rounded-md"
        >
          Login to access your templates
        </Link>
      ) : (
        <Link
          to="/products/templates/search"
          className="bg-gradient-to-r from-orange-500 to-orange-800 py-3 px-4 mx-3 rounded-md"
        >
          Get Started
        </Link>
      )}
       
        <Link
          to="/price"
          className="py-3 px-4 mx-3 rounded-md border"
        >
          Choose your plan
        </Link>
      </div>
      <div className="mx-auto md:flex md:justify-center md:gap-5">
        <video
          autoPlay
          loop
          muted
          className="rounded-lg md:w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          {/* <source src={video1} type="video/mp4" /> */}
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          loop
          muted
          className="rounded-lg md:w-1/2 border border-orange-700 shadow-sm shadow-orange-400 mx-2 my-4"
        >
          {/* <source src={video2} type="video/mp4" /> */}
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default CustomTemplate