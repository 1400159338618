import React from "react";
import Footer from "../components/Layout/Footer";
import Legal from "../components/Products/LegalDB/Legal/Legal";
import Resources from "../components/Products/LegalDB/Resources/Resources";
import Navbar from "../components/Layout/Navbar";
import Sponsored from "../components/Route/Sponsored";

const LegalPage = () => {
  return (
    <div className="dark:bg-[#490800] dark:text-white ">
      <Navbar />
      <div className="max-w-7xl mx-auto px-6">
        <Legal />
        <Resources />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
};

export default LegalPage;



