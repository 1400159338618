// import React from 'react'
// import styles from "../../../styles/styles";
// import ai from "../../../Assests/Image/ai.png";
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from "react-redux";

// const Library = () => {
//     const navigate = useNavigate();
  
//     const user = useSelector((state) => state.auth.user);


//     const loginPage = () => {
//   navigate('/sign-up');
//   }

//    const paymentPage = () => {
//      navigate('/price');
//   }
//   return (
//     <div className={`${styles.section}`}>
//       <div lassName={`${styles.heading}`}>
//         <div className="md:flex md:items-start md:justify-around mt-20 mb-20">
//           <div  data-aos="zoom-in" className="md:w-[40%] flex items-center justify-center">
//             <img src={ai} alt="ai" />
//           </div>
          
//           <div className="md:w-[40%]">
//             <h1  data-aos="fade-up" className="lg:text-5xl text-3xl md:mb-0 lg:leading-normal font-semibold text-black">
//               Library
//             </h1>
//             <p  data-aos="fade-up" className="mt-5">
//             A rich library of licensed and exclusive legal research journals, reports and case studies.
//             </p>{" "}
//             <p  data-aos="fade-up" className="mt-5">
//               Our platform is designed to save your business or legal department
//               time and reduce costs by automating your legal documentation
//               processes.
//             </p>
//              {user ? (<button  data-aos="fade-up" onClick={paymentPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
//               Learn More
//             </button> ) : (
// <button  data-aos="fade-up" onClick={loginPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
//               Learn More
//             </button>
//             )};
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Library




import React from "react";
import Book1 from "../../../Assests/books/book1.png";
import Book2 from "../../../Assests/books/book2.jpg";
import Book3 from "../../../Assests/books/book3.jpg";
import Vector from "../../../Assests/website/Background22.png";

const ImageList = [
  {
    id: 1,
    img: Book1,
    title: "Nigerian Law Decoded",
    author: "Mabel Adetoun Adebiyi",
    description:
      "Nigerian Law Decoded",
      author: "Mabel Adetoun Adebiyi",
  },
  {
    id: 2,
    img: Book2,
    title: "Journal of African Law",
    author: "SOAS",
    description:
      "The journal contains a separate section on recent legislation, case-law, law reform proposals and recent international developments affecting Africa. ",
  },
  {
    id: 3,
    img: Book3,
    title: "International & Comparative Law Quarterly",
    author: "Mabel Adetoun Adebiyi",
    description:
      "International & Comparative Law Quarterly",
  },
];

const Hero = ({ handleOrderPopup }) => {
  const [imageId, setImageId] = React.useState(Book1);
  const [title, setTitle] = React.useState("Nigerian Law Decoded");
  const [description, setDescription] = React.useState("Nigerian Law Decoded");
  const [author, setAuthor] = React.useState("Mabel Adetoun Adebiyi");

  const bgImage = {
    backgroundImage: `url(${Vector})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // height: "100%",
    width: "100%",
  };

  return (
    <>
      <div
        className="min-h-[550px] sm:min-h-[650px] bg-gray-100 flex justify-center items-center dark:bg-[#490800] dark:text-white duration-200"
        style={bgImage}
      >
        <div className="container pb-8 sm:pb-0">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            {/* text content section */}
            <div
              data-aos-once="true"
              className="flex flex-col justify-center gap-4 pt-12 sm:pt-0 text-center sm:text-left order-2 sm:order-1"
            >
              <h1
                data-aos="zoom-out"
                data-aos-duration="500"
                data-aos-once="true"
                className="text-5xl sm:text-6xl lg:text-7xl font-bold"
              >
                {title}
                <p class="bg-clip-text text-transparent bg-gradient-to-b from-primary text-right text-sm to-secondary">
                  {author}
                </p>{" "}
              </h1>
              <p
                data-aos="slide-up"
                data-aos-duration="500"
                data-aos-delay="100"
                className="text-sm "
              >
                {description}
              </p>
              <div>
                <button
                  onClick={handleOrderPopup}
                  className="bg-gradient-to-r from-orange-500 to-orange-800 hover:scale-105 duration-200 py-2 px-4 rounded-full"
                >
                  Order Now
                </button>
              </div>
            </div>
            {/* Image section */}
            <div className="min-h-[450px] sm:min-h-[450px] flex justify-center items-center relative order-1 sm:order-2 ">
              <div className="h-[300px] sm:h-[450px] overflow-hidden flex justify-center items-center">
                <img
                  data-aos="zoom-in"
                  data-aos-once="true"
                  src={imageId}
                  alt="biryani img"
                  className="w-[300px] h-[300px] sm:h-[450px] sm:w-[450px] sm:scale-125 object-contain mx-auto"
                />
              </div>
              <div className="flex lg:flex-col lg:top-1/2 lg:-translate-y-1/2 lg:py-2 justify-center gap-4 absolute -bottom-[40px] lg:-right-1 bg-white rounded-full">
                {ImageList.map((item) => ( 
                  <img
                    data-aos="zoom-in"
                    data-aos-once="true"
                    src={item.img}
                    onClick={() => {
                      setImageId(
                        item.id === 1 ? Book1 : item.id === 2 ? Book2 : Book3
                      );
                      setTitle(item.title);
                      setDescription(item.description);
                      setAuthor(item.author);
                    }}
                    alt="biryani img"
                    className="max-w-[100px] h-[100px] object-contain inline-block hover:scale-110 duration-200"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
