// import React from "react";

// const Favourites = () => {
//   return (
//     <div className="p-6">
//       {/* <h1 className="text-3xl font-bold mb-6">My Favourites List</h1> */}
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
//         {["Favourite A", "Favourite B", "Favourite C", "Favourite D"].map(
//           (fav, index) => (
//             <div key={index} className="bg-white p-4 rounded shadow dark:!bg-[#780D00] dark:!text-[#fff]">
//               <h2 className="text-lg font-semibold">{fav}</h2>
//               <p>Some details about {fav}</p>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default Favourites;


import React, { useState, useEffect } from 'react';
import { Editor, EditorState, ContentState, convertToRaw } from 'draft-js';
import html2pdf from 'html2pdf.js';

const Favourites = () => {
  const [contracts, setContracts] = useState([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editingContractId, setEditingContractId] = useState(null);

  useEffect(() => {
    const savedContracts = JSON.parse(localStorage.getItem("contracts") || "[]");
    setContracts(savedContracts);
  }, []);

  const handleEdit = (contract) => {
    setEditingContractId(contract.id);
    const contentState = ContentState.createFromText(contract.content);
    setEditorState(EditorState.createWithContent(contentState));
  };

  const handleSaveEdit = () => {
    const updatedContent = convertToRaw(editorState.getCurrentContent()).blocks.map(block => block.text).join("\n");
    const updatedContracts = contracts.map(contract =>
      contract.id === editingContractId ? { ...contract, content: updatedContent } : contract
    );
    setContracts(updatedContracts);
    localStorage.setItem("contracts", JSON.stringify(updatedContracts));
    setEditingContractId(null);
    alert("Contract updated successfully!");
  };

  const handleDelete = (id) => {
    const updatedContracts = contracts.filter(contract => contract.id !== id);
    setContracts(updatedContracts);
    localStorage.setItem("contracts", JSON.stringify(updatedContracts));
  };

  const handleDownloadPdf = (contract) => {
    const contentHtml = `
      <div style="font-family: Arial, sans-serif; padding: 20px;">
        <h1 style="text-align: center;">${contract.title}</h1>
        <p>${contract.content.replace(/\n/g, "<br />")}</p>
      </div>
    `;

    const options = {
      margin: 1,
      filename: `${contract.title}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, scrollY: 0 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(options).from(contentHtml).toPdf().save();
  };

  return (
    <div className="p-6">
      <div className="grid gap-6">
        {contracts.map(contract => (
          <div key={contract.id} className="p-4 border border-gray-300 rounded">
            <h2 className="text-xl font-semibold">{contract.title}</h2>
            <p className="text-gray-600">{contract.date}</p>
            <div className="flex space-x-4 mt-2">
              <button onClick={() => handleEdit(contract)} className="bg-green-500 text-white px-4 py-2 rounded">
                Edit
              </button>
              <button onClick={() => handleDownloadPdf(contract)} className="bg-blue-500 text-white px-4 py-2 rounded">
                Download as PDF
              </button>
              <button onClick={() => handleDelete(contract.id)} className="bg-red-500 text-white px-4 py-2 rounded">
                Delete
              </button>
            </div>

            {editingContractId === contract.id && (
              <div className="mt-6 p-6 border border-gray-300 rounded">
                <h2 className="text-2xl font-semibold mb-4">Edit Contract - {contract.title}</h2>
                <Editor editorState={editorState} onChange={setEditorState} />
                <button onClick={handleSaveEdit} className="mt-4 bg-green-500 text-white px-4 py-2 rounded">
                  Save Changes
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Favourites;


