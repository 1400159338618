import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  HomePage,
  AboutPage,
  ProductPage,
  ContactPage,
  LegalPage,
  PricePage,
  RegisterPage,
  LegalSearchPage,
  TomaAIPage,
  CustomTemplatePage,
  VaultPage,
  TestPage,
  PaymentPage,
  LegalMarketPlace,
  LibraryPage,
  TemplatePage,
} from "./routes/Routes.js";
import { UserDashboardPage } from "./routes/UserRoutes.js";
import ScrollToTop from "./components/common/ScrollToTop.jsx";
import { useDispatch } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute.js";
import { checkAuthState } from "./redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);


    React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/register" element={<RegisterPage />} />
         <Route path="/test" element={<TestPage />} />
        <Route path="/products/legal" element={<LegalPage />} />
        <Route path="/products/legal/search" element={<LegalSearchPage />} />
        <Route path="/products/ai" element={<TomaAIPage />} />
        <Route path="/products/templates" element={<CustomTemplatePage />} />
        <Route path="/products/templates/search" element={<TemplatePage />} />        
       
        <Route path="/products/library" element={<LibraryPage />} />
        <Route path="/products/marketplace" element={<LegalMarketPlace />} />
        <Route
          path="/products/vault"
          element={<VaultPage />}
        />

        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <UserDashboardPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
       theme="colored"
      />
    </BrowserRouter>
  );
};

export default App;
