import { SET_CURRENCY } from '../actions/currencyActions';

const initialState = {
  currency: '$',
  country: 'US',
};

export const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
        country: action.payload.country,
      };
    default:
      return state;
  }
};
