import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Assests/Svg/logo.svg";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import { loginSuccess } from "../redux/actions/authActions";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
 const dispatch = useDispatch();

    const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://auth.tomatech.online/realms/development/protocol/openid-connect/token",
        new URLSearchParams({
          client_id: "react-dev",
          grant_type: "password",
          username: username,
          password: password,
        }),
        
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
        
      );

      // Handle token response and update Keycloak state
      const Userdata = response.data;
     const token = Userdata.access_token

      const user = jwtDecode(token);
    
       dispatch(loginSuccess(user));
    
       toast.success("Login Success!");
      navigate("/");
    } catch (error) {
      setError(
        "Invalid username or password. Please try again or contact your administrator."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex w-full h-screen dark:bg-[#490800] dark:text-white">
      <div className="w-full flex items-center justify-center lg:w-1/2">
        <div className=" w-11/12 max-w-[700px] px-10 py-10 rounded-3xl bg-white  dark:bg-[#490800] dark:text-white border-2 border-gray-100">
          <Link to="/">
            <img className="h-20 w-20 mb-5 mx-auto" src={logo} alt="Logo" />
          </Link>
          <h1 className="text-3xl font-semibold">Welcome Back</h1>
          <p className="font-medium text-lg text-gray-500 mt-2">
            Welcome back! Please enter you details.
          </p>
          <div className="mt-4">
            <div className="flex flex-col">
              <label className="text-lg font-medium">Username</label>
              <input
                name="text"
                type="text"
                autoComplete="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                placeholder="Enter your username"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-lg font-medium">Password</label>
              <div className="relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder="Enter your password"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-4 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-4 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            {error && <div className="mt-4 text-red-600">{error}</div>}
            <div className="mt-8 flex justify-between items-center">
              <div>
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="w-4 h-4 text-[#721F29]  bg-gray-100 border-gray-300 rounded focus:ring-[#721F29]  dark:focus:ring-[#721F29]  dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 font-medium text-base" for="remember">
                  Remember for 30 days
                </label>
              </div>
              <button className="font-medium text-base bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text">
                Forgot password
              </button>
            </div>
            <div className="mt-8 flex flex-col gap-y-4">
              <button
                onClick={handleLogin}
                className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 border-t-2 bg-gray border-solid rounded-full"
                    viewBox="0 0 24 24"
                  ></svg>
                )}
                Login
              </button>
            </div>
            <div className="mt-8 flex justify-center items-center">
              <p className="font-medium text-base">Don't have an account?</p>

              <Link
                to="/register"
                className="ml-2 font-medium text-base bg-gradient-to-r from-orange-500 to-red-800 text-transparent bg-clip-text"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden relative w-1/2 h-full lg:flex items-center justify-center bg-gray-200 dark:bg-[#490800] dark:text-white dark:border-l-white dark:border-l-2">
        <div className="w-60 h-60 rounded-full bg-gradient-to-r from-orange-500 to-red-800 animate-spin" />
        <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg" />
      </div>
    </div>
  );
};

export default LoginPage;
