import React from 'react'
import styles from "../../styles/styles";
import laptop from "../../Assests/Image/laptop.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Legal = () => {
   const navigate = useNavigate();
  
    const user = useSelector((state) => state.auth.user);


    const loginPage = () => {
  navigate('/sign-up');
  }

   const paymentPage = () => {
     navigate('/price');
  }
  return (
    <div className={`${styles.section}`}>
      <div lassName={`${styles.heading}`}>
        <div className="md:flex md:items-start md:justify-around mt-20 mb-20">
          <div className="md:w-[40%] mb-10 md:mb-0 ">
            <h1 data-aos="fade-up" className="lg:text-5xl text-3xl md:mb-0 lg:leading-normal font-semibold text-black">
              Legal MarketPlace
            </h1>
            <p data-aos="fade-up" className="mt-5">
             A platform connecting clients with legal experts for specialized services.
            </p>{" "}
            <p data-aos="fade-up" className="mt-5">
             Legal Marketplace. A rich library of licensed and exclusive legal research journals, reports and case studies.


            </p>
             {user ? (<button  data-aos="fade-up" onClick={paymentPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
              Learn More
            </button> ) : (
<button  data-aos="fade-up" onClick={loginPage}  className="mt-5 cursor-pointer rounded-md bg-[#031749] w-[180px] h-[45px] text-[#fff]">
              Learn More
            </button>
            )};
          </div>
          <div data-aos="zoom-in" className="md:w-[40%]">
            <img src={laptop} alt="laptop" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal