import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { useSelector } from "react-redux";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";

const Payment = () => {
  const [orderData, setOrderData] = useState(null); // Initialize as null
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
    const [select, setSelect] = useState(1);
    const [open, setOpen] = useState(false);

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("paymentData"));
    setOrderData(orderData);
    if (!orderData) {
      console.error('No state passed, redirecting to home.');
      navigate('/Price');
    }
  }, [navigate]);

  if (!orderData) {
    // Handle the case where orderData is not loaded yet
    return <div>Loading...</div>;
  }

  const { product, access, selectedPrice, selectedCurrency } = orderData;
  const price = selectedPrice;
  const tax = price * 0.1;
  const totalPrice = price + tax;
  const currency = selectedCurrency;

  // Ensure user is not null before accessing its properties
  const email = user?.email || 'default@example.com'; // Provide a fallback email
  const name = user?.name || 'Default Name'; // Provide a fallback name
  const phone = user?.phoneNumber || '000-000-0000'; // Provide a fallback phone number

  const componentProps = {
    email,
    amount: totalPrice * 100, // Amount in kobo
    metadata: {
      name,
      phone,
    },
    publicKey: 'pk_live_55da9a7339b7b6785343d43cc49cdc86cc58d6cc', // Replace with your Paystack public key
    text: `Pay ${currency} ${totalPrice}`,
    onSuccess: (reference) => alert('Payment Successful! Reference: ' + reference.reference),
    onClose: () => alert('Payment closed without completing'),
  };

const createOrder = (data, actions) => {
  return actions.order
    .create({
      purchase_units: [
        {
          description: product,
          amount: {
            currency_code: "USD",
            value: totalPrice.toFixed(2),
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    })
    .then((orderID) => {
      return orderID;
    })
    .catch(error => {
      console.error("PayPal Order Creation Error:", error);
      alert("Error creating PayPal order. Please try again.");
    });
};


  const order = {
    user: user && user,
    totalPrice: totalPrice * 100,
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler(paymentInfo);
      }
    });
  };


   const paypalPaymentHandler = async (paymentInfo) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };



    // await axios
    //   .post(`${server}/order/create-order`, order, config)
    //   .then((res) => {
    //     setOpen(false);
    //     navigate("/order/success");
    //     toast.success("Order successful!");
    //     localStorage.setItem("cartItems", JSON.stringify([]));
    //     localStorage.setItem("latestOrder", JSON.stringify([]));
    //     window.location.reload();
    //   });
  };

   const paymentHandler = async (e) => {
    e.preventDefault();
    // try {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };

    //   const { data } = await axios.post(
    //     `${server}/payment/process`,
    //     paymentData,
    //     config
    //   );

    //   const client_secret = data.client_secret;

    //   if (!stripe || !elements) return;
    //   const result = await stripe.confirmCardPayment(client_secret, {
    //     payment_method: {
    //       card: elements.getElement(CardNumberElement),
    //     },
    //   });

    //   if (result.error) {
    //     toast.error(result.error.message);
    //   } else {
    //     if (result.paymentIntent.status === "succeeded") {
    //       order.paymnentInfo = {
    //         id: result.paymentIntent.id,
    //         status: result.paymentIntent.status,
    //         type: "Credit Card",
    //       };

    //       await axios
    //         .post(`${server}/order/create-order`, order, config)
    //         .then((res) => {
    //           setOpen(false);
    //           navigate("/order/success");
    //           toast.success("Order successful!");
    //           localStorage.setItem("cartItems", JSON.stringify([]));
    //           localStorage.setItem("latestOrder", JSON.stringify([]));
    //           window.location.reload();
    //         });
    //     }
    //   }
    // } catch (error) {
    //   toast.error(error);
    // }
  };

  return (
    <div className="font-[sans-serif]  p-4 py-20">
      <div className="md:max-w-5xl max-w-xl mx-auto">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 max-md:order-1">
            <h2 data-aos="zoom-in" className="text-3xl font-extrabold text-gray-800 dark:text-white">Make a payment</h2>
            <p data-aos="fade-up" className="text-gray-800 dark:text-white text-sm mt-4">Complete your transaction swiftly and securely with our easy-to-use payment process.</p>


             {/* select buttons */}
      <div>
        <div className="mt-5 flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] dark:border-white relative flex items-center justify-center"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] dark:bg-white rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1] dark:text-white">
            Pay with Debit/credit card
          </h4>
        </div>

        {/* pay with card */}
        {select === 1 ? (
          <div className="w-full flex border-b">
              <form className="mt-4 max-w-lg">
              <div className="grid gap-4">
                <div data-aos="fade-up">
                  <input
                    type="text"
                    placeholder="Cardholder's Name"
                    className="px-4 py-3.5 bg-gray-100 text-gray-800 w-full text-sm border rounded-md focus:border-purple-500 focus:bg-transparent outline-none"
                  />
                </div>

                <div data-aos="fade-up" className="flex bg-gray-100 border rounded-md focus-within:border-purple-500 focus-within:bg-transparent overflow-hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 ml-3" viewBox="0 0 32 20">
                    <circle cx="10" cy="10" r="10" fill="#f93232" data-original="#f93232" />
                    <path fill="#fed049"
                      d="M22 0c-2.246 0-4.312.75-5.98 2H16v.014c-.396.298-.76.634-1.107.986h2.214c.308.313.592.648.855 1H14.03a9.932 9.932 0 0 0-.667 1h5.264c.188.324.365.654.518 1h-6.291a9.833 9.833 0 0 0-.377 1h7.044c.104.326.186.661.258 1h-7.563c-.067.328-.123.66-.157 1h7.881c.039.328.06.661.06 1h-8c0 .339.027.67.06 1h7.882c-.038.339-.093.672-.162 1h-7.563c.069.341.158.673.261 1h7.044a9.833 9.833 0 0 1-.377 1h-6.291c.151.344.321.678.509 1h5.264a9.783 9.783 0 0 1-.669 1H14.03c.266.352.553.687.862 1h2.215a10.05 10.05 0 0 1-1.107.986A9.937 9.937 0 0 0 22 20c5.523 0 10-4.478 10-10S27.523 0 22 0z"
                      className="hovered-path" data-original="#fed049" />
                  </svg>
                  <input type="number" placeholder="Card Number"
                    className="px-4 py-3.5 text-gray-800 w-full text-sm outline-none bg-transparent" />
                </div>

                <div data-aos="fade-up" className="grid grid-cols-2 gap-4">
                  {/* Expiry and CVV Fields */}
                  <div>
                    <input type="number" placeholder="EXP."
                      className="px-4 py-3.5 bg-gray-100 text-gray-800 w-full text-sm border rounded-md focus:border-purple-500 focus:bg-transparent outline-none" />
                  </div>
                  <div>
                    <input type="number" placeholder="CVV"
                      className="px-4 py-3.5 bg-gray-100 text-gray-800 w-full text-sm border rounded-md focus:border-purple-500 focus:bg-transparent outline-none" />
                  </div>
                </div>
              </div>

              <PaystackButton 
                data-aos="fade-up"
                className="mt-8 w-40 py-3.5 text-sm bg-purple-500 text-white rounded-md hover:bg-purple-600 tracking-wide"
                {...componentProps}              
              />
            </form>
          </div>
        ) : null}
      </div>

     

      <br />
      {/* paypal payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] dark:border-white relative flex items-center justify-center"
            onClick={() => setSelect(2)}
          >
            {select === 2 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] dark:bg-white rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl -2 font-[600] text-[#000000b1] dark:text-white ml-2">

            {" "}Pay with Paypal
          </h4>
        </div>

        {/* pay with payement */}
        {select === 2 ? (
          <div className="w-full flex border-b">
            <div
              className="mt-1 w-40 py-3.5 text-sm bg-purple-500 text-white rounded-md hover:bg-purple-600 tracking-wide flex items-center justify-center cursor-pointer"
              onClick={() => setOpen(true)}
            >
              {`Pay ${currency} ${totalPrice}`}
            </div>
            {open && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[40%] h-screen 800px:h-[80vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative overflow-y-scroll">
                  <div className="w-full flex justify-end p-3">
                    <RxCross1
                      size={30}
                      className="cursor-pointer absolute top-3 right-3"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AQCthFDtxB-C9VwXBXPPWPVOF1PrmUwbnz1EWsyhlKVKXJObkK5MkUvIOFF_tIaYK03OrYObjSiKqp6z",
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      onApprove={onApprove}
                      createOrder={createOrder}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>


      <br />
      {/* cash on delivery */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] dark:border-white relative flex items-center justify-center"
            onClick={() => setSelect(3)}
          >
            {select === 3 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] dark:bg-white rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1] dark:text-white">
           Pay with PayStack
          </h4>
        </div>

        {/* cash on delivery */}
        {select === 3 ? (
          <div className="w-full flex">
            <PaystackButton
                    data-aos="fade-up"
                    className="mt-1 w-40 py-3.5 text-sm bg-purple-500 text-white rounded-md hover:bg-purple-600 tracking-wide"
                    {...componentProps}
                  />
          </div>
        ) : null}
      </div>

            
          </div>

          <div data-aos="fade-up" className="bg-gray-100 dark:bg-[#780D00] p-6 rounded-md">
            <h2 className="text-3xl font-extrabold text-gray-800 dark:text-white">{currency} {totalPrice}</h2>

            <ul className="text-gray-800 dark:text-white mt-8 space-y-4">
              <li className="flex flex-wrap gap-4 text-lg font-bold">{product}</li>
              <li className="flex flex-wrap gap-4 text-sm">{access} <span className="ml-auto font-bold">{currency} {price}</span></li>
              <li className="flex flex-wrap gap-4 text-sm">Tax <span className="ml-auto font-bold">{currency} {tax}</span></li>
              <li className="flex flex-wrap gap-4 text-sm font-bold border-t-2 pt-4">Total <span className="ml-auto"> {currency} {totalPrice}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}; 

export default Payment;


