import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const LegalSearch = () => {
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const [search, setSearch] = useState({ title: '', dateTitle: '', respondents: '', suitNo: '' });
  const [filters, setFilters] = useState({ appellants: '', counselAppellants: '', counselRespondents: '', courtType: '', courtDivision: '', date: '', justices: '' });
  const [sortBy, setSortBy] = useState('date');
 const [searchQuery, setSearchQuery] = useState({
  title: '',
  dateTitle: '',
  respondents: '',
  suitNo: ''
});
const [searchCategory, setSearchCategory] = useState('');
const [searchName, setSearchName] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCase, setSelectedCase] = useState(null); // Selected case for the modal
  const [showModal, setShowModal] = useState(false); // Modal state
  const user = useSelector((state) => state.auth.user);

  const handleCaseClick = (caseItem) => {
  setSelectedCase(caseItem);  // Store the clicked case in state
  setShowModal(true);  // Open the modal
};

  useEffect(() => {
    // Fetch cases data
    axios
      .get('https://api-gw.tomatech.online/v1/legaldatabase/cases')
      .then((response) => {
        setCases(response.data.collection);
        setFilteredCases(response.data.collection);
      })
      .catch((error) => console.error(error));
  }, []);

 const handleSearch = () => {
  const filtered = cases.filter(caseItem => {
    // Matches the case title
    const matchesTitle = caseItem.title.toLowerCase().includes(searchQuery.title.toLowerCase());

    // Matches the case dateTitle
    const matchesDateTitle = caseItem.dateTitle.toLowerCase().includes(searchQuery.dateTitle.toLowerCase());

    // Matches the case respondents (joined and converted to string)
    const matchesRespondents = caseItem.respondents.join(', ').toLowerCase().includes(searchQuery.respondents.toLowerCase());

    // Matches the case suitNo (handling null or undefined suit numbers)
    const matchesSuitNo = caseItem.suitNo ? caseItem.suitNo.toLowerCase().includes(searchQuery.suitNo.toLowerCase()) : true;

    // Matches headnotes category and name (checking if headnotes exist)
    const matchesCategory = caseItem.headnotes?.some(hn => hn.category.toLowerCase().includes(searchCategory.toLowerCase()));
    const matchesName = caseItem.headnotes?.some(hn => hn.name.toLowerCase().includes(searchName.toLowerCase()));

    // Return true if any of these match
    return (matchesTitle && matchesDateTitle && matchesRespondents && matchesSuitNo) || matchesCategory || matchesName;
  });

  setFilteredCases(filtered);
  setCurrentPage(1);
};


  const handleFilter = () => {
    let result = cases.filter((item) =>
      (filters.appellants ? item.appellants.join(', ').toLowerCase().includes(filters.appellants.toLowerCase()) : true) &&
      (filters.counselAppellants ? item.counsel.appelents.join(', ').toLowerCase().includes(filters.counselAppellants.toLowerCase()) : true) &&
      (filters.counselRespondents ? item.counsel.respondents.join(', ').toLowerCase().includes(filters.counselRespondents.toLowerCase()) : true) &&
      (filters.courtType ? item.courtType.toLowerCase().includes(filters.courtType.toLowerCase()) : true) &&
      (filters.courtDivision ? item.courtDivision.toLowerCase().includes(filters.courtDivision.toLowerCase()) : true) &&
      (filters.date ? item.date.includes(filters.date) : true) &&
      (filters.justices ? item.justices.join(', ').toLowerCase().includes(filters.justices.toLowerCase()) : true)
    );
    setFilteredCases(result);
  };

  const handleSort = (key) => {
    let sortedCases = [...filteredCases].sort((a, b) => {
      if (key === 'date') return new Date(a.date) - new Date(b.date);
      if (key === 'justices') return a.justices.join(', ').localeCompare(b.justices.join(', '));
      if (key === 'courtType') return a.courtType.localeCompare(b.courtType);
      if (key === 'courtDivision') return a.courtDivision.localeCompare(b.courtDivision);
      return 0;
    });
    setFilteredCases(sortedCases);
    setSortBy(key);
  };

  const totalPages = Math.ceil(filteredCases.length / perPage);
  const currentCases = filteredCases.slice((currentPage - 1) * perPage, currentPage * perPage);

  const openModal = (caseData) => {
    setSelectedCase(caseData);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedCase(null);
    setShowModal(false);
  };

  const paginatedCases = filteredCases.slice((currentPage - 1) * perPage, currentPage * perPage);

  // Define handleSaveCase function to save the case in local storage
const handleSaveCase = (caseData) => {
  const savedCases = JSON.parse(localStorage.getItem('savedCases') || '[]');
  savedCases.push(caseData);
  localStorage.setItem('savedCases', JSON.stringify(savedCases));
  alert('Case saved successfully!');
};

  return (
    <div className="container mx-auto p-4">
      <h1 data-aos="fade-up" className="text-2xl font-bold mb-4">Case Search</h1>

      {/* Search Fields */}
      <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
       <input
  type="text"
  placeholder="Search by title"
  value={searchQuery.title}
  onChange={(e) => setSearchQuery({...searchQuery, title: e.target.value})}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>
<input
  type="text"
  placeholder="Search by date title"
  value={searchQuery.dateTitle}
  onChange={(e) => setSearchQuery({...searchQuery, dateTitle: e.target.value})}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>
<input
  type="text"
  placeholder="Search by respondents"
  value={searchQuery.respondents}
  onChange={(e) => setSearchQuery({...searchQuery, respondents: e.target.value})}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>
<input
  type="text"
  placeholder="Search by suit number"
  value={searchQuery.suitNo}
  onChange={(e) => setSearchQuery({...searchQuery, suitNo: e.target.value})}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>

<input
  type="text"
  placeholder="Search by headnote category"
  value={searchCategory}
  onChange={(e) => setSearchCategory(e.target.value)}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>
<input
  type="text"
  placeholder="Search by headnote name"
  value={searchName}
  onChange={(e) => setSearchName(e.target.value)}
  className="border p-2 rounded w-full dark:bg-[#000] dark:bg-opacity-20"
/>

      </div>

      <button data-aos="fade-up" onClick={handleSearch} className="bg-gradient-to-r from-orange-500 to-red-800 text-white px-4 py-2 rounded mb-4">
        Search
      </button>

      {/* Filter Fields */}
      <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
        <input
          type="text"
          placeholder="Filter by Appellants"
          value={filters.appellants}
          onChange={(e) => setFilters({ ...filters, appellants: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="text"
          placeholder="Filter by Counsel Appellants"
          value={filters.counselAppellants}
          onChange={(e) => setFilters({ ...filters, counselAppellants: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="text"
          placeholder="Filter by Counsel Respondents"
          value={filters.counselRespondents}
          onChange={(e) => setFilters({ ...filters, counselRespondents: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="text"
          placeholder="Filter by Court Type"
          value={filters.courtType}
          onChange={(e) => setFilters({ ...filters, courtType: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="text"
          placeholder="Filter by Court Division"
          value={filters.courtDivision}
          onChange={(e) => setFilters({ ...filters, courtDivision: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="date"
          placeholder="Filter by Date"
          value={filters.date}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
        <input
          type="text"
          placeholder="Filter by Justices"
          value={filters.justices}
          onChange={(e) => setFilters({ ...filters, justices: e.target.value })}
          className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20"
        />
      </div>

      <button data-aos="fade-up" onClick={handleFilter} className="bg-gradient-to-r from-orange-500 to-red-800 text-white px-4 py-2 rounded mb-4">
        Apply Filters
      </button>

      {/* Sorting */}
      <div data-aos="fade-up" className="mb-4">
        <label>Sort By: </label>
        <select value={sortBy} onChange={(e) => handleSort(e.target.value)} className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20">
          <option value="date">Date</option>
          <option value="justices">Justices</option>
          <option value="courtType">Court Type</option>
          <option value="courtDivision">Court Division</option>
        </select>
      </div>

      {/* Table */}
      <div data-aos="fade-up" className="overflow-x-auto">
        <table className="min-w-full ">
          <thead>
            <tr className='bg-gray-200 dark:bg-[#000] dark:bg-opacity-20'>
              <th className="py-2 px-4 ">Title</th>
              <th className="py-2 px-4 ">Justices</th>
              <th className="py-2 px-4 ">Date</th>
              <th className="py-2 px-4 ">Court Type</th>
              <th className="py-2 px-4 ">Court Division</th>
            </tr>
          </thead>
          <tbody>
            {paginatedCases.map((item, index) => (
              <tr key={index} className="border-b-4 cursor-pointer" onClick={() => openModal(item)}>
                <td className="py-2 px-4">{item.title}</td>
                <td className="py-2 px-4">{item.justices.join(', ')}</td>
                <td className="py-2 px-4">{item.dateTitle}</td>
                <td className="py-2 px-4">{item.courtType}</td>
                <td className="py-2 px-4">{item.courtDivision}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div data-aos="fade-up" className="flex justify-between items-center mt-4">
        <div>
          <label>Show: </label>
          <select value={perPage} onChange={(e) => setPerPage(Number(e.target.value))} className="border p-2 rounded dark:bg-[#000] dark:bg-opacity-20">
          <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div>
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="bg-gray-300 px-2 py-1 rounded"
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`mx-1 px-2 py-1 rounded ${currentPage === i + 1 ? 'bg-gradient-to-r from-orange-500 to-red-800 text-white' : 'bg-gray-300'}`}
            >
              {i + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="bg-gray-300 px-2 py-1 rounded"
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}


      {showModal && selectedCase && user && (
        <>

        {/* Dark overlay */}
          <div className="fixed inset-0 z-[1050] bg-[#000] opacity-50"></div>

         <div
          className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none flex items-center justify-center"
          id="exampleModalScrollable"
          tabIndex="-1"
          aria-labelledby="exampleModalScrollableLabel"
          aria-hidden="true"
        >
          <div
            className="pointer-events-none relative h-[calc(100%-5rem)] w-auto translate-y-[-50px] opacity-1 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 mt-20 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
          >
            <div className="pointer-events-auto relative flex max-h-[100%] lg:max-w-4xl flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
              <div className="flex flex-shrink-0 items-center bg-gradient-to-r from-orange-500 to-red-800 justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                {/* Modal title */}
                <h5
                  className="text-xl font-medium leading-normal text-white dark:text-white"
                  id="exampleModalScrollableLabel"
                >
                 <h2 className="text-2xl font-bold mb-4">Case Content</h2>
                </h5>
                {/* Close button */}
                <button
                  type="button"
                  className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 focus:text-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span className="[&>svg]:h-6 [&>svg]:w-6 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </button>
              </div>

              {/* Modal body */}
              <div className="relative overflow-y-auto p-4">
                <p className="text-gray-300">
                 
                </p>
                <div className='mt-4'></div>
               <div className="text-center">
                 <h2 className='font-bold text-2xl text-center mb-4'>{selectedCase.title}</h2>
                <p><strong>Date:</strong> {selectedCase.dateTitle}</p>
              <p><strong>Court Type:</strong> {selectedCase.courtType}</p>
              <p><strong>Court Division:</strong> {selectedCase.courtDivision}</p>
              <p><strong>Suit No:</strong> {selectedCase.suitNo}</p>
              <p><strong>Justices:</strong> {selectedCase.justices.join(', ')}</p>
              <p><strong>Appellants:</strong> {selectedCase.appellants.join(', ')}</p>
              <p><strong>Respondents:</strong> {selectedCase.respondents.join(', ')}</p>
              <p><strong>Appellants' Counsel:</strong> {selectedCase.counsel.appellants.join(', ')}</p>
              <p><strong>Respondents' Counsel:</strong> {selectedCase.counsel.respondents.join(', ')}</p>
              <h3 className="mt-4 font-bold">Headnotes:</h3>
             {selectedCase.headnoteList && selectedCase.headnoteList.length > 0 ? (
      selectedCase.headnoteList.map((headnote, index) => (
        <div key={index} className="p-4 mb-4 border rounded-lg text-left">
          {/* <h4 className="font-semibold"> {headnote.category}</h4> */}
          <p><strong>{headnote.name}</strong>:</p>
          <p className="text-gray-700">{headnote.description}</p>
        </div>
      ))
    ) : (
      <p>No headnotes available for this case.</p>
    )}
               </div>
                 {/* {selectedCase.headnotes.map((headnotes, index) => (
                 <div key={index} className='mt-4'>
                   <h2 className='font-bold text-2xl text-center'>Headnote {index + 1}</h2>
                   <p className='mb-4'>{headnotes.category}</p>
                    <p className='mb-4'>{headnotes.name}</p>
                     <p className='mb-4'>{headnotes.description}</p>
                 </div>
                
               ))} */}
                <h3 className="mt-4 font-bold text-center">CONTENT:</h3>
                 <div
        className="case-content prose max-w-full text-left text-gray-700" // Tailwind prose class for good HTML content styling
        dangerouslySetInnerHTML={{ __html: selectedCase.content || 'No content available' }}
      />
              </div>

              {/* Modal footer */}
             
                <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 p-4 bg-gradient-to-r from-orange-500 to-red-800 dark:border-white/10">
                  <button
                    type="button"
                    className="inline-block rounded bg-gradient-to-r from-orange-500 to-red-800 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-200 focus:bg-primary-accent-200 dark:bg-primary-300 dark:hover:bg-primary-400"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="ms-1 inline-block rounded bg-gradient-to-r from-orange-500 to-red-800 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 focus:bg-primary-accent-300"
                    onClick={() => handleSaveCase(selectedCase)}
                  >
                    Save Case
                  </button>
                </div>
            </div>
          </div>
        </div>

        </>
               

      )}
      {/* {showModal && selectedCase && user && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#000] bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-4xl w-full overflow-auto h-[calc(100%-5rem)]">
            <h2 className="text-2xl font-bold mb-4">Case Details</h2>
            <div>
              <p><strong>Title:</strong> {selectedCase.title}</p>
              <p><strong>Date:</strong> {selectedCase.dateTitle}</p>
              <p><strong>Court Type:</strong> {selectedCase.courtType}</p>
              <p><strong>Court Division:</strong> {selectedCase.courtDivision}</p>
              <p><strong>Suit No:</strong> {selectedCase.suitNo}</p>
              <p><strong>Justices:</strong> {selectedCase.justices.join(', ')}</p>
              <p><strong>Appellants:</strong> {selectedCase.appellants.join(', ')}</p>
              <p><strong>Respondents:</strong> {selectedCase.respondents.join(', ')}</p>
              <p><strong>Appellants' Counsel:</strong> {selectedCase.counsel.appellants.join(', ')}</p>
              <p><strong>Respondents' Counsel:</strong> {selectedCase.counsel.respondents.join(', ')}</p>
              <h3 className="mt-4 font-bold">Headnotes:</h3>
             {selectedCase.headnoteList && selectedCase.headnoteList.length > 0 ? (
      selectedCase.headnoteList.map((headnote, index) => (
        <div key={index} className="p-4 mb-4 border rounded-lg">
          <h4 className="font-semibold">Category: {headnote.category}</h4>
          <p>Name: {headnote.name}</p>
          <p className="text-gray-700">Description: {headnote.description}</p>
        </div>
      ))
    ) : (
      <p>No headnotes available for this case.</p>
    )}

              <h3 className="mt-4 font-bold">Content:</h3>
              <div dangerouslySetInnerHTML={{ __html: selectedCase.content }} />
            </div>
            <button onClick={closeModal} className="bg-red-500 text-white px-4 py-2 rounded mt-4">
              Close
            </button>
          </div>
        </div>
      )} */}

       {/* Modal */}
      {showModal && selectedCase && !user && (
        <>
          
            <div className="fixed inset-0 flex items-center justify-center bg-[#000] bg-opacity-50">
          <div className=" p-6 shadow-lg max-w-lg flex flex-col items-center justify-center bg-white rounded-full dark:bg-[#780D00]">
            <h2 className="text-2xl font-bold mb-2 mt-4">Case</h2>
            <Link to="/sign-up">
              <p className='cursor-pointer font-bold mt-5 mb-5'>Sign up to get Started</p>
            </Link>

            <button onClick={closeModal} className="bg-gradient-to-b from-orange-500 to-red-800 text-white px-4 py-2 rounded mt-4 mx-auto">
              Close
            </button>
          </div>
        </div>
        </>

      
      )}
    </div>
  );
};

export default LegalSearch;
