import React from 'react'

const InvoiceItem = ({ date, invoiceNumber, amount }) => (
  <li className="relative flex justify-between px-4 py-2 pl-0 mb-2 border-0 rounded-t-inherit text-inherit rounded-xl">
    <div className="flex flex-col">
      <h6 className="mb-1 text-sm font-semibold leading-normal dark:text-white text-slate-700">{date}</h6>
      <span className="text-xs leading-tight dark:text-white dark:opacity-80">{invoiceNumber}</span>
    </div>
    <div className="flex items-center text-sm leading-normal dark:text-white/80">
      ${amount}
      <button className="dark:text-white inline-block px-0 py-2.5 mb-0 ml-6 font-bold leading-normal text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer ease-in bg-150 text-sm active:opacity-85 hover:-translate-y-px tracking-tight-rem bg-x-25 text-slate-700"><i className="mr-1 text-lg leading-none fas fa-file-pdf"></i> PDF</button>
    </div>
  </li>
);

export default InvoiceItem;