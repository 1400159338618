import React from "react";
import TomaAi from "../components/Route/TomaAi/TomaAi.jsx";
import Navbar from "../components/Layout/Navbar.jsx";
import Sponsored from "../components/Route/Sponsored.jsx";
import Footer from "../components/Layout/Footer.jsx";
import Hero from "../components/Route/Library/Hero.jsx";
import Services from "../components/Route/Library/Services.jsx";
import Banner from "../components/Route/Library/Banner.jsx";
import Books from "../components/Route/Library/Books.jsx";
import OrderPopup from "../components/Route/Library/OrderPopup.jsx";
import Journal from "../components/Route/Library/Journal.jsx";

const LibraryPage = () => {
    const [orderPopup, setOrderPopup] = React.useState(false);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup);
  };
  return (
    <div className="bg-white dark:bg-[#490800] dark:text-white duration-200">
      <Navbar handleOrderPopup={handleOrderPopup} />
       <Hero handleOrderPopup={handleOrderPopup}/> 
       <Services handleOrderPopup={handleOrderPopup} />
       <Journal handleOrderPopup={handleOrderPopup} />
       <Banner />
       <Books handleOrderPopup={handleOrderPopup} />
      {/* <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col items-center mt-6 lg:mt-10 mb-10">
          <h1  data-aos="fade-up" className="text-4xl sm:text-6xl lg:text-6xl text-center tracking-wide">
            Toma
            <span className="text-[#A71200]">
              {" "}
              Library
            </span>
          </h1>
        </div>
              
        <Sponsored />
      </div> */}

      <Footer />
      <OrderPopup orderPopup={orderPopup} setOrderPopup={setOrderPopup} />
    </div>
  );
};

export default LibraryPage;
