
import {
  RxCrop,
  RxDesktop,
  RxPencil2,
  RxReader,
  RxRocket,
  RxAccessibility,
} from "react-icons/rx";

import SpaceCity1 from "../Assests/space/SpaceCity1.jpg";
import SpaceCity5 from "../Assests/space/SpaceCity5.jpeg";
import SpaceCity6 from "../Assests/space/SpaceCity6.jpeg";
import SpaceCity7 from "../Assests/space/SpaceCity7.jpeg";
import SpaceCity8 from "../Assests/space/SpaceCity8.jpeg";
import SpaceCity9 from "../Assests/space/SpaceCity9.jpeg";
import user1 from "../Assests/profile-pictures/user1.jpg";
import user2 from "../Assests/profile-pictures/user2.jpg";
import user3 from "../Assests/profile-pictures/user3.jpg";
import user4 from "../Assests/profile-pictures/user4.jpg";
import user5 from "../Assests/profile-pictures/user5.jpg";
import user6 from "../Assests/profile-pictures/user6.jpg";
import benefitIcon1 from "../Assests/benefits/icon-1.svg";
import benefitIcon2 from "../Assests/benefits/icon-2.svg";
import benefitIcon3 from "../Assests/benefits/icon-3.svg";
import benefitIcon4 from "../Assests/benefits/icon-4.svg";
import benefitImage2 from "../Assests/benefits/image-2.png";
import benefitcard1 from "../Assests/benefits/card-1.svg";
import benefitcard2 from "../Assests/benefits/card-2.svg";
import benefitcard3 from "../Assests/benefits/card-3.svg";
import benefitcard4 from "../Assests/benefits/card-4.svg";
import benefitcard5 from "../Assests/benefits/card-5.svg";
import benefitcard6 from "../Assests/benefits/card-6.svg";
import CPO from "../Assests/Team/CPO.jpg"
import CIO from "../Assests/Team/CIO.png"
import COO from "../Assests/Team/COO.jpg";
import RDM from "../Assests/Team/RDM.jpg";
import founder from "../Assests/Team/founder.png";
import { BotMessageSquare } from "lucide-react";
import { Fingerprint } from "lucide-react";
import { ShieldHalf } from "lucide-react";
// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about-us",
  },
  {
    title: "Products",
    url: "/products",
  },
  //  {
  //   title: "Pricing",
  //   url: "/Price",
  // },
  {
    title: "Contact",
    url: "/contact",
  },
];

export const ServiceData = [
  {
    icon: RxCrop,
    title: "Development",
    content: "Lorem ipsum dolor sit /amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity9,
  },
  {
    icon: RxPencil2,
    title: "Branding",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity1,
  },
  {
    icon: RxDesktop,
    title: "Design",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity6,
  },
  {
    icon: RxReader,
    title: "Seo",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity7,
  },
  {
    icon: RxAccessibility,
    title: "Management",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity5,
  },
  {
    icon: RxRocket,
    title: "Production",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    backgroundImage: SpaceCity8,
  },
];


export const scheduleData = [
  {
    Id: 1,
    Subject: 'Explosion of Betelgeuse Star',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T04:00:00.000Z',
    EndTime: '2021-01-10T05:30:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 2,
    Subject: 'Thule Air Crash Report',
    Location: 'Newyork City',
    StartTime: '2021-01-11T06:30:00.000Z',
    EndTime: '2021-01-11T08:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 3,
    Subject: 'Blue Moon Eclipse',
    Location: 'Space Center USA',
    StartTime: '2021-01-12T04:00:00.000Z',
    EndTime: '2021-01-12T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 4,
    Subject: 'Meteor Showers in 2021',
    Location: 'Space Center USA',
    StartTime: '2021-01-13T07:30:00.000Z',
    EndTime: '2021-01-13T09:00:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 5,
    Subject: 'Milky Way as Melting pot',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T06:30:00.000Z',
    EndTime: '2021-01-14T08:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 6,
    Subject: 'Mysteries of Bermuda Triangle',
    Location: 'Bermuda',
    StartTime: '2021-01-14T04:00:00.000Z',
    EndTime: '2021-01-14T05:30:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 7,
    Subject: 'Glaciers and Snowflakes',
    Location: 'Himalayas',
    StartTime: '2021-01-15T05:30:00.000Z',
    EndTime: '2021-01-15T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 8,
    Subject: 'Life on Mars',
    Location: 'Space Center USA',
    StartTime: '2021-01-16T03:30:00.000Z',
    EndTime: '2021-01-16T04:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 9,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-18T05:30:00.000Z',
    EndTime: '2021-01-18T07:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 10,
    Subject: 'Wildlife Galleries',
    Location: 'Africa',
    StartTime: '2021-01-20T05:30:00.000Z',
    EndTime: '2021-01-20T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 11,
    Subject: 'Best Photography 2021',
    Location: 'London',
    StartTime: '2021-01-21T04:00:00.000Z',
    EndTime: '2021-01-21T05:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 12,
    Subject: 'Smarter Puppies',
    Location: 'Sweden',
    StartTime: '2021-01-08T04:30:00.000Z',
    EndTime: '2021-01-08T06:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 13,
    Subject: 'Myths of Andromeda Galaxy',
    Location: 'Space Center USA',
    StartTime: '2021-01-06T05:00:00.000Z',
    EndTime: '2021-01-06T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 14,
    Subject: 'Aliens vs Humans',
    Location: 'Research Center of USA',
    StartTime: '2021-01-05T04:30:00.000Z',
    EndTime: '2021-01-05T06:00:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 15,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-19T04:00:00.000Z',
    EndTime: '2021-01-19T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 16,
    Subject: 'Sky Gazers',
    Location: 'Alaska',
    StartTime: '2021-01-22T05:30:00.000Z',
    EndTime: '2021-01-22T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 17,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-11T00:00:00.000Z',
    EndTime: '2021-01-11T02:00:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 18,
    Subject: 'Space Galaxies and Planets',
    Location: 'Space Center USA',
    StartTime: '2021-01-11T11:30:00.000Z',
    EndTime: '2021-01-11T13:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 19,
    Subject: 'Lifecycle of Bumblebee',
    Location: 'San Fransisco',
    StartTime: '2021-01-14T00:30:00.000Z',
    EndTime: '2021-01-14T02:00:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 20,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T10:30:00.000Z',
    EndTime: '2021-01-14T12:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 21,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T08:30:00.000Z',
    EndTime: '2021-01-10T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 22,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-12T09:00:00.000Z',
    EndTime: '2021-01-12T10:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 23,
    Subject: 'Sky Gazers',
    Location: 'Greenland',
    StartTime: '2021-01-15T09:00:00.000Z',
    EndTime: '2021-01-15T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 24,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-16T07:00:00.000Z',
    EndTime: '2021-01-16T09:00:00.000Z',
    CategoryColor: '#7fa900',
  },
];

 export const plans = [
   {
     product: "Law Directory",
     description:
       "TOMA's Legal Database is the ultimate resource for agile legal professionals, institutions, and students.",
       linkUrl:"/products/legal/pricing",
     plans: [
       {
         type: "Basic",
         prices: {
           "1 Seat": "₦10,000/month",
           "3 Seats": "₦25,000/month",
           "5 Seats": "₦40,000/month",
           "Week Access (5 days)": "₦12,000/week",
           "Annual Subscription": "₦100,000/year",
         },
       },
       { type: "Session (24h)", prices: { "1 Seat": "₦5,000/session" } },
       { type: "Session (48h)", prices: { "1 Seat": "₦8,000/session" } },
     ],
   },
   {
     product: "Customizable Templates",
     description:
       "TOMA offers a comprehensive suite of customizable templates for contracts, agreement generation, and legal document management.",
        linkUrl:"/products/templates/pricing",
     plans: [
       {
         type: "Basic",
         prices: {
           "1 Seat": "₦12,000/month",
           "3 Seats": "₦30,000/month",
           "5 Seats": "₦50,000/month",
           "Week Access (5 days)": "₦14,000/week",
           "Annual Subscription": "₦120,000/year",
         },
       },
     ],
   },
   {
     product: "Vault",
     description:
       "TOMA Vault is the ultimate solution for businesses and firms seeking to integrate robust compliance and confidentiality into their filing systems and processes.",
     plans: [
       {
         type: "Standard (100 Documents)",
         prices: { "1 Seat": "Free with any TOMA price plan" },
       },
       { type: "Mid (500 Documents)", prices: { "1 Seat": "₦10,000" } },
       { type: "Pro (1000+ Documents)", prices: { "1 Seat": "₦25,000" } },
     ],
   },
   {
     product: "All Products (Combo)",
     description:
       "TOMA Vault is the ultimate solution for businesses and firms seeking to integrate robust compliance and confidentiality into their filing systems and processes.",
     plans: [
       {
         type: "Basic",
         prices: {
           "1 Seat": "₦25,000/month",
           "3 Seats": "₦63,000/month",
           "5 Seats": "₦120,000/month",
           "Week Access (5 days)": "₦30,000/week",
           "Annual Subscription": "₦250,000/year",
         },
       },
     ],
   },
 ];

 export const Orders = [
   {
     productName: "Product 1",
     productNumber: "12345",
     paymentStatus: "Paid",
     status: "Delivered",
   },
   {
     productName: "Product 2",
     productNumber: "23456",
     paymentStatus: "Pending",
     status: "Pending",
   },
   {
     productName: "Product 3",
     productNumber: "34567",
     paymentStatus: "Paid",
     status: "Declined",
   },
   {
     productName: "Product 4",
     productNumber: "45678",
     paymentStatus: "Due",
     status: "Pending",
   },
 ];

 
export const teamMembers = [
  {
    name: "MABEL ADEBIYI",
    position: "Founder & Chairperson",
    imageUrl: founder,
    description:
      "Renowned legal and compliance expert specializing in company law.",
    socialLinks: [
      { link: "#", icon: "fab fa-linkedin", color: "bg-blue-500" },
      { link: "#", icon: "fab fa-instagram", color: "bg-red-500" },
     
    ],
  },

  {
    name: "ANDREAS FRUHEN",
    position: "Co-Founder & CIO",
    imageUrl: CIO,
    description:
      " Brings a wealth of experience in building digital infrastructure across Africa and beyound.",
    socialLinks: [
      { link: "#", icon: "fab fa-linkedin", color: "bg-blue-500" },
      { link: "#", icon: "fab fa-instagram", color: "bg-red-500" },
     
    ],
  },
  {
    name: "AF ADEBIYI",
    position: "Co-Founder & COO",
    imageUrl: COO,
    description:
      "A seasoned professional with background in building and marketing tech products.",
    socialLinks: [
      { link: "#", icon: "fab fa-linkedin", color: "bg-blue-500" },
      { link: "#", icon: "fab fa-instagram", color: "bg-red-500" },
     
    ],
  },
  {
    name: "CHIMANKPA OSUJI",
    position: "Co-Founder & CPO",
    imageUrl: CPO,
    description:
      "Seasoned CPO with 8+ years in software development and product design.",
    socialLinks: [
      { link: "#", icon: "fab fa-linkedin", color: "bg-blue-500" },
      { link: "#", icon: "fab fa-instagram", color: "bg-red-500" },
     
    ],
  },
  {
    name: "Dele Fashina",
    position: "Research and Development",
    imageUrl: RDM,
    description:
      "Seasoned R&D Manager driving innovation in the legal data and tech.",
    socialLinks: [
      { link: "#", icon: "fab fa-linkedin", color: "bg-blue-500" },
      { link: "#", icon: "fab fa-instagram", color: "bg-red-500" },
     
    ],
  },
  
  
];

export const dummyData = [
  {
    title: "Case 1",
    description: "Description of case 1",
    date: "2024-07-01",
    court: "Court A",
    cashNumber: "12345",
    courtType: "Type A",
    year: 2024,
    newReleases: true,
    keyword: "Keyword1",
    citation: "CIT123",
    justices: "Justice A",
    subjectMatter: "Subject A",
    counsel: "Counsel A",
    jurisdiction: "Jurisdiction A",
  },
  {
    title: "Case 2",
    description: "Description of case 2",
    date: "2024-06-15",
    court: "Court B",
    cashNumber: "67890",
    courtType: "Type B",
    year: 2023,
    newReleases: false,
    keyword: "Keyword2",
    citation: "CIT456",
    justices: "Justice B",
    subjectMatter: "Subject B",
    counsel: "Counsel B",
    jurisdiction: "Jurisdiction B",
  },
  {
    title: "Case 3",
    description: "Description of case 3",
    date: "2024-06-10",
    court: "Court C",
    cashNumber: "11223",
    courtType: "Type C",
    year: 2022,
    newReleases: true,
    keyword: "Keyword3",
    citation: "CIT789",
    justices: "Justice C",
    subjectMatter: "Subject C",
    counsel: "Counsel C",
    jurisdiction: "Jurisdiction C",
  },
  {
    title: "Case 4",
    description: "Description of case 4",
    date: "2024-06-05",
    court: "Court D",
    cashNumber: "44556",
    courtType: "Type D",
    year: 2021,
    newReleases: false,
    keyword: "Keyword4",
    citation: "CIT012",
    justices: "Justice D",
    subjectMatter: "Subject D",
    counsel: "Counsel D",
    jurisdiction: "Jurisdiction D",
  },
  {
    title: "Case 5",
    description: "Description of case 5",
    date: "2024-05-25",
    court: "Court E",
    cashNumber: "77889",
    courtType: "Type E",
    year: 2020,
    newReleases: true,
    keyword: "Keyword5",
    citation: "CIT345",
    justices: "Justice E",
    subjectMatter: "Subject E",
    counsel: "Counsel E",
    jurisdiction: "Jurisdiction E",
  },
  {
    title: "Case 6",
    description: "Description of case 6",
    date: "2024-05-20",
    court: "Court F",
    cashNumber: "99001",
    courtType: "Type F",
    year: 2019,
    newReleases: false,
    keyword: "Keyword6",
    citation: "CIT678",
    justices: "Justice F",
    subjectMatter: "Subject F",
    counsel: "Counsel F",
    jurisdiction: "Jurisdiction F",
  },
  {
    title: "Case 7",
    description: "Description of case 7",
    date: "2024-05-15",
    court: "Court G",
    cashNumber: "22334",
    courtType: "Type G",
    year: 2018,
    newReleases: true,
    keyword: "Keyword7",
    citation: "CIT901",
    justices: "Justice G",
    subjectMatter: "Subject G",
    counsel: "Counsel G",
    jurisdiction: "Jurisdiction G",
  },
  {
    title: "Case 8",
    description: "Description of case 8",
    date: "2024-05-10",
    court: "Court H",
    cashNumber: "55667",
    courtType: "Type H",
    year: 2017,
    newReleases: false,
    keyword: "Keyword8",
    citation: "CIT234",
    justices: "Justice H",
    subjectMatter: "Subject H",
    counsel: "Counsel H",
    jurisdiction: "Jurisdiction H",
  },
  {
    title: "Case 9",
    description: "Description of case 9",
    date: "2024-05-05",
    court: "Court I",
    cashNumber: "88990",
    courtType: "Type I",
    year: 2016,
    newReleases: true,
    keyword: "Keyword9",
    citation: "CIT567",
    justices: "Justice I",
    subjectMatter: "Subject I",
    counsel: "Counsel I",
    jurisdiction: "Jurisdiction I",
  },
  {
    title: "Case 10",
    description: "Description of case 10",
    date: "2024-04-25",
    court: "Court J",
    cashNumber: "11212",
    courtType: "Type J",
    year: 2015,
    newReleases: false,
    keyword: "Keyword10",
    citation: "CIT890",
    justices: "Justice J",
    subjectMatter: "Subject J",
    counsel: "Counsel J",
    jurisdiction: "Jurisdiction J",
  },
  {
    title: "Case 11",
    description: "Description of case 11",
    date: "2024-04-20",
    court: "Court K",
    cashNumber: "33434",
    courtType: "Type K",
    year: 2014,
    newReleases: true,
    keyword: "Keyword11",
    citation: "CIT111",
    justices: "Justice K",
    subjectMatter: "Subject K",
    counsel: "Counsel K",
    jurisdiction: "Jurisdiction K",
  },
  {
    title: "Case 12",
    description: "Description of case 12",
    date: "2024-04-15",
    court: "Court L",
    cashNumber: "55656",
    courtType: "Type L",
    year: 2013,
    newReleases: false,
    keyword: "Keyword12",
    citation: "CIT222",
    justices: "Justice L",
    subjectMatter: "Subject L",
    counsel: "Counsel L",
    jurisdiction: "Jurisdiction L",
  },
  {
    title: "Case 13",
    description: "Description of case 13",
    date: "2024-04-10",
    court: "Court M",
    cashNumber: "77878",
    courtType: "Type M",
    year: 2012,
    newReleases: true,
    keyword: "Keyword13",
    citation: "CIT333",
    justices: "Justice M",
    subjectMatter: "Subject M",
    counsel: "Counsel M",
    jurisdiction: "Jurisdiction M",
  },
  {
    title: "Case 14",
    description: "Description of case 14",
    date: "2024-04-05",
    court: "Court N",
    cashNumber: "99090",
    courtType: "Type N",
    year: 2011,
    newReleases: false,
    keyword: "Keyword14",
    citation: "CIT444",
    justices: "Justice N",
    subjectMatter: "Subject N",
    counsel: "Counsel N",
    jurisdiction: "Jurisdiction N",
  },
  {
    title: "Case 15",
    description: "Description of case 15",
    date: "2024-03-25",
    court: "Court O",
    cashNumber: "11221",
    courtType: "Type O",
    year: 2010,
    newReleases: true,
    keyword: "Keyword15",
    citation: "CIT555",
    justices: "Justice O",
    subjectMatter: "Subject O",
    counsel: "Counsel O",
    jurisdiction: "Jurisdiction O",
  },
  {
    title: "Case 16",
    description: "Description of case 16",
    date: "2024-03-20",
    court: "Court P",
    cashNumber: "33433",
    courtType: "Type P",
    year: 2009,
    newReleases: false,
    keyword: "Keyword16",
    citation: "CIT666",
    justices: "Justice P",
    subjectMatter: "Subject P",
    counsel: "Counsel P",
    jurisdiction: "Jurisdiction P",
  },
  {
    title: "Case 17",
    description: "Description of case 17",
    date: "2024-03-15",
    court: "Court Q",
    cashNumber: "55655",
    courtType: "Type Q",
    year: 2008,
    newReleases: true,
    keyword: "Keyword17",
    citation: "CIT777",
    justices: "Justice Q",
    subjectMatter: "Subject Q",
    counsel: "Counsel Q",
    jurisdiction: "Jurisdiction Q",
  },
  {
    title: "Case 18",
    description: "Description of case 18",
    date: "2024-03-10",
    court: "Court R",
    cashNumber: "77877",
    courtType: "Type R",
    year: 2007,
    newReleases: false,
    keyword: "Keyword18",
    citation: "CIT888",
    justices: "Justice R",
    subjectMatter: "Subject R",
    counsel: "Counsel R",
    jurisdiction: "Jurisdiction R",
  },
  {
    title: "Case 19",
    description: "Description of case 19",
    date: "2024-03-05",
    court: "Court S",
    cashNumber: "99009",
    courtType: "Type S",
    year: 2006,
    newReleases: true,
    keyword: "Keyword19",
    citation: "CIT999",
    justices: "Justice S",
    subjectMatter: "Subject S",
    counsel: "Counsel S",
    jurisdiction: "Jurisdiction S",
  },
  {
    title: "Case 20",
    description: "Description of case 20",
    date: "2024-02-25",
    court: "Court T",
    cashNumber: "11210",
    courtType: "Type T",
    year: 2005,
    newReleases: false,
    keyword: "Keyword20",
    citation: "CIT000",
    justices: "Justice T",
    subjectMatter: "Subject T",
    counsel: "Counsel T",
    jurisdiction: "Jurisdiction T",
  },
];




export const pricing = [
  {
    id: "0",
    title: "Basic",
    description: "AI chatbot, personalized recommendations",
    price: "0",
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
  {
    id: "1",
    title: "Premium",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: "9.99",
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
  {
    id: "2",
    title: "Enterprise",
    description: "Custom AI chatbot, advanced analytics, dedicated account",
    price: null,
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
];

export const sessionPricing = [
  {
    id: "0",
    title: "24 hours",
    description: "AI chatbot, personalized recommendations",
    price: "5,000",
    linkUrls: "/login",
   
  },
  {
    id: "1",
    title: "48 hours",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: "8,000",
    linkUrls: "/login",
    
  },
  {
    id: "2",
    title: "72 hours More",
    description: "Custom AI chatbot, advanced analytics, dedicated account",
    price: null,
    linkUrls: null,
   
  },
];

export const seatPricing = [
  {
    id: "0",
    title: "1 Seat",
    description: "AI chatbot, personalized recommendations",
    price: "10,000",
   
  },
  {
    id: "1",
    title: "3 Seats",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: "25,000",
    
  },
  {
    id: "2",
    title: "5 Seat",
    description: "AI chatbot, personalized recommendations",
    price: "40,000",
   
  },
 
];

export const recurringPricing = [
  {
    id: "0",
    title: "Week Access (5 days)",
    description: "AI chatbot, personalized recommendations",
    price: "12,000",
    period: "week",
  },
  {
    id: "1",
    title: "Monthly Subscription/More Seats",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: null,
    period: "month",
  },
  {
    id: "2",
    title: "Annual Subscription",
    description: "AI chatbot, personalized recommendations",
    price: "100,000",
    period: "year",
  },
 
];

export const slide = [
  "https://i.pinimg.com/originals/51/82/ac/5182ac536727d576c78a9320ac62de30.jpg",
  "https://wallpapercave.com/wp/wp3386769.jpg",
  "https://wallpaperaccess.com/full/809523.jpg",
  "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
];

export const priceBenefits = [
  {
    id: "0",
    title: "Legal Database",
    linkUrl: "/products/legal/pricing",
    text: "TOMA's Legal Database is the ultimate resource for agile legal professionals, institutions, and students.",
    backgroundUrl: benefitcard1,
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Customizable Templates",
    linkUrl: "/products/templates/pricing",
    text: "TOMA offers a comprehensive suite of customizable templates for contracts, agreement generation, and legal document management.",
    backgroundUrl: benefitcard2,
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: "The Vault",
    linkUrl: "/products/vault/pricing",
    text: "TOMA Vault is the ultimate solution for businesses and firms seeking to integrate robust compliance and confidentiality into their filing systems and processes.",
    backgroundUrl: benefitcard3,
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: "TOMA AI",
    linkUrl: "/products/ai/pricing",
    text: "With TOMA's free version, gain instant access to answers for your everyday legal inquiries. Our advanced AI technology provides clear and concise responses to your questions, empowering you to navigate legal complexities with ease.",
    backgroundUrl: benefitcard4,
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: "Legal Marketplace",
    linkUrl: "/products/marketplace/pricing",
    text: "A platform connecting clients with legal experts for specialized services.",
    backgroundUrl: benefitcard5,
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  // {
  //   id: "5",
  //   title: "Library",
  //   linkUrl: "/products/library/pricing",
  //   text: " A rich library of licensed and exclusive legal research journals, reports and case studies.",
  //   backgroundUrl: benefitcard6,
  //   iconUrl: benefitIcon2,
  //   imageUrl: benefitImage2,
  // },
  {
    id: "6",
    title: "All Products (Combo)",
    linkUrl: "/products/combo/pricing",
    text: " A rich library of licensed and exclusive legal research journals, reports and case studies.",
    backgroundUrl: benefitcard6,
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },
];
export const benefits = [
  {
    id: "0",
    title: "Legal Database",
    linkUrl: "/products/legal",
    text: "TOMA’s comprehensive legal database organizes laws, cases, and rulings into a user-friendly, easily navigable system, allowing professionals to access critical legal information quickly and efficiently",
    backgroundUrl: benefitcard1,
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Customizable Templates",
    linkUrl: "/products/templates",
    text: " Our Customizable Templates simplify legal document creation by offering pre-built, editable contracts and agreements tailored to Nigerian law, integrated with the Vault for secure storage and Digital Signature capabilities for seamless, legally binding transactions.",
    backgroundUrl: benefitcard2,
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: "KYC Portal and ID",
    linkUrl: "/products/vault",
    text: "TOMA’s KYC Portal automates identity verification, streamlining Know Your Customer (KYC) processes for businesses with real-time checks and a secure digital ID system, ensuring compliance with anti-money laundering (AML) regulations.",
    backgroundUrl: benefitcard3,
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: "Compliancee Dashboard",
    linkUrl: "/products/ai",
    text: "TOMA’s AI-powered tools enhance efficiency by automating legal research, contract review, and compliance checks, providing predictive insights and streamlining repetitive legal processes for faster decision-making",
    backgroundUrl: benefitcard4,
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: "Legal Marketplace",
    linkUrl: "/products/marketplace",
    text: "The Legal Marketplace connects businesses and legal professionals, offering a platform to source legal services, access specialized legal expertise, and collaborate on projects, promoting transparency and ease of finding legal support.",
    backgroundUrl: benefitcard5,
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "5",
    title: "Library",
    linkUrl: "/products/library",
    text: " The Legal Library provides an extensive repository of curated legal articles, publications, and research materials, keeping users updated on regulatory trends and emerging laws, aiding both research and legal practice.",
    backgroundUrl: benefitcard6,
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },
];

export const features = [
  {
    icon: <BotMessageSquare />,
    text: "Single User",
    description:
      "Easily design and arrange your VR environments with a user-friendly drag-and-drop interface.",
  },
  {
    icon: <Fingerprint />,
    text: "SME/ Start Up Businesses",
    description:
      "Build VR applications that run seamlessly across multiple platforms, including mobile, desktop, and VR headsets.",
  },
  {
    icon: <ShieldHalf />,
    text: "Large Businesses",
    description:
      "Jumpstart your VR projects with a variety of built-in templates for different types of applications and environments.",
  },
];
export const LegalData = [
  {
    icon: <BotMessageSquare />,
    text: "Comprehensive Legal Resources",
    description:
      "Helps in thorough case preparation and staying updated with the latest legal precedents and changes in legislation.",
  },
  {
    icon: <Fingerprint />,
    text: "Advanced Search Functionality",
    description:
      "Powerful search tools with filters and keyword search options, saving time by quickly locating relevant cases and legal materials, enhancing efficiency in legal research.",
  },
  {
    icon: <ShieldHalf />,
    text: "User-Friendly Interface",
    description:
      "Intuitive and easy-to-navigate, simplifying the research process, allowing users to focus more on analysis and case strategy rather than just searching for information. Interface can also be customized for institutions.",
  },
  {
    icon: <ShieldHalf />,
    text: "Customer and Technical Support",
    description:
      "TOMA provides dedicated customer and technical support to assist users with any queries or issues, ensuring seamless access and optimal use of the database.",
  },
];
export const missions = [
  {
    icon: <BotMessageSquare />,
    text: "Our Mission",
    description:
      " We are on a mission to empower legal professionals and organizations of any size and sector with world-class technology, enhancing efficiency, accessibility, and innovation in the legal and compliance sector.",
  },
  {
    icon: <Fingerprint />,
    text: "Our Vision",
    description:
      "To be the leading provider of advanced legal tech and compliance solutions, fostering a smarter and more connected legal ecosystem.",
  },
];

export const testimonials = [
  {
     id: 1,
    name: "ARK Insurance",
    role: "ARK Insurance",
    image: user1,
    testimonial: "Using TOMA’s Templates Dashboard and Vault to standardize legal document processes across global offices, has resulted in a 30% reduction in document handling time.",
    backgroundUrl: benefitcard1,
    imageUrl: benefitImage2,
  },
  {
    id: 2,
    name: "Alimi Lukman Olakunle",
    company: "Alimi Lukman Olakunle",
    image: user2,
    testimonial: "As an independent lawyer leveraging the Legal Database as a resource has lead to a 40% increase in client engagement and efficiency.",
    backgroundUrl: benefitcard2,
    imageUrl: benefitImage2,
  },
  {
    id: 3,
    name: "Mohamed Dabana",
    company: "Mohamed Dabana",
    image: user3,
    testimonial: "TOMA Legal Tech’s template dashboard has been a game-changer for creating professional contracts effortlessly, saving me countless hours. It provides peace of mind in managing client relationships.",
    backgroundUrl: benefitcard3,
    imageUrl: benefitImage2,
  },
  {
    id: 4,
    name: "Flance NG",
    company: "Flance NG",
    image: user4,
    testimonial: "TOMA's Vault has transformed our HR operations with its secure, centralized platform for managing sensitive documents. Its advanced security measures give us peace of mind, allowing us to focus on our core business",
    backgroundUrl: benefitcard4,
    imageUrl: benefitImage2,
  },
  {
    id: 5,
    name: "Michael Wilson",
    company: "Visionary Creations",
    image: user5,
    testimonial: "I am amazed by the level of professionalism and dedication shown by the team. They were able to exceed our expectations and deliver outstanding results.",
    backgroundUrl: benefitcard5,
    imageUrl: benefitImage2,
  },
  {
    id: 6,
    name: "Emily Davis",
    company: "Synergy Systems",
    image: user6,
    testimonial: "The team went above and beyond to ensure our project was a success. Their expertise and dedication are unmatched. I look forward to working with them again in the future.",
    backgroundUrl: benefitcard6,
    imageUrl: benefitImage2,
  },
];
export const checklistItems = [
  {
    title: " Vast collection of contract",
    description:
      " Access a vast collection of contract, agreement templates to suit your unique business needs.",
  },
  {
    title: "Converse with the TOMABot",
    description:
      "Simply converse with the TOMABot and input all necessary details.",
  },
  {
    title: "SME / Start Up Package",
    description:
      " Unlock your SME/Start-up Package today for essential templates tailored to your business needs.",
  },
  {
    title: "Templates On- Demand",
    description:
      "Access essential templates, tailored for freelancers and consultants, empowering you to streamline your business needs with ease..",
  },
];

export const ApproachItem = [
  {
    title: " Comprehensive Solutions",
    description: " From legal document management to compliance monitoring",
  },
  {
    title: "Ease of Use",
    description:
      "Our intuitive interface makes it easy for users of all levels to navigate the platform with confidence",
  },
  {
    title: "Expert Support",
    description:
      "Access a team of legal professionals and compliance experts ready to assist you whenever you need",
  },
  {
    title: "Security and Compliance",
    description:
      "With TOMA Vault, your data is protected with the highest levels of security and confidentiality.",
  },
];

export const slides = [
  {
    id: 1,
    Description:
      "As a freelance graphic designer, TOMA Legal Tech’s template dashboard has been a game-changer for creating professional contracts effortlessly. It saved me countless hours and provided peace of mind to manage relationship with my clients",
    logo: "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
    image_Url: "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
    name: "Mohammed Dabbana",
    title: "Graphic Artist & Creative Director , Consultant",
  },
];
// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "From all orders over 100$",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
];





export const footerProductLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Careers",
    link: "/carrers",
  },
  {
    name: "Store Locations",
  },
  {
    name: "Our Blog",
  },
  {
    name: "Reviews",
  },
];

export const footercompanyLinks = [
  {
    name: "Game & Video",
  },
  {
    name: "Phone &Tablets",
  },
  {
    name: "Computers & Laptop",
  },
  {
    name: "Sport Watches",
  },
  {
    name: "Events",
  },
];

export const footerSupportLinks = [
  {
    name: "FAQ",
  },
  {
    name: "Reviews",
  },
  {
    name: "Contact Us",
  },
  {
    name: "Shipping",
  },
  {
    name: "Live chat",
  },
];

export const resourcesLinks = [
  { href: "#", text: "Getting Started" },
  { href: "#", text: "Documentation" },
  { href: "#", text: "Tutorials" },
  { href: "#", text: "API Reference" },
  { href: "#", text: "Community Forums" },
];

export const platformLinks = [
  { href: "#", text: "Features" },
  { href: "#", text: "Supported Devices" },
  { href: "#", text: "System Requirements" },
  { href: "#", text: "Downloads" },
  { href: "#", text: "Release Notes" },
];

export const communityLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];
export const govenLinks = [
  { href: "#", text: "Events" },
  { href: "#", text: "Meetups" },
  { href: "#", text: "Conferences" },
  { href: "#", text: "Hackathons" },
  { href: "#", text: "Jobs" },
];
