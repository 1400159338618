import React from "react";
import styles from "../../styles/styles";
import fcmb from "../../Assests/Brands/fcmb.jpeg";
import harvard from "../../Assests/Brands/harvard.png";
import nigerian from "../../Assests/Brands/nigerian.png";
import lagos from "../../Assests/Brands/lagos.jpeg";
import clinky from "../../Assests/Brands/clinky.jpg";
import smartone from "../../Assests/Brands/smartone.svg";


const Sponsored = () => {
  return (
    <div
      className={`${styles.section} hidden sm:block w-full`}
    >
      <div
        className={`${styles.heading} bg-[#f5f2f2] dark:bg-[#000] dark:bg-opacity-30 py-10 px-5 mt-4 mb-12 cursor-pointer rounded-xl`}
      >
        <div className="flex items-center justify-between">
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={fcmb}
              alt=""
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
            />
           
          </div>
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={harvard}
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={nigerian}
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={lagos}
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={clinky}
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div data-aos="zoom-in" className="flex items-start">
            <img
              src={smartone}
               className="rounded-full bg-white hover:p-2 hover:bg-red-700"
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsored;
