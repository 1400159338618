import React from "react";
import Img1 from "../../../Assests/books/book1.png";
import Img2 from "../../../Assests/books/book2.jpg";
import Img3 from "../../../Assests/books/book4.png";
import { FaStar } from "react-icons/fa";

const ServicesData = [
  {
    id: 1,
    img: Img1,
    title: "Nigerian Law Decoded",
    description:
      "Nigerian Law Decoded",
    buy: "N140,000",
    text: "Buy Now",
  },
  {
    id: 2,
    img: Img2,
    title: "Journal of African Law",
    description:
      "Journal of African Law",
      buy: "N140,000",
    text: "Buy Now",
  },
  {
    id: 3,
    img: Img3,
    title: "Property Law Practice",
    description:
      "Property Law Practice covers Land transactions and documents, Wills and Administration of Estates, Billings and Recovery of Professional Charges and Property Law Taxation. ",
       text: "Rent Now",
    buy: "N15,000/weekly",
  },
   ];

const Services = ({ handleOrderPopup }) => {
  return (
    <>
      <span id="services"></span>
      <div className="py-10">
        <div className="container">
          <div className="text-center mb-20 max-w-[400px] mx-auto">
            <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary ">
              Trending Books
            </p>
            <h1 className="text-3xl font-bold">Best Books</h1>
            <p className="text-xs text-gray-400">
            Browse through our collection of best books
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 md:gap-15 place-items-center">
            {ServicesData.map((service) => (
              <div
                data-aos="zoom-in"
                className="rounded-2xl bg-white dark:bg-[#780D00] hover:bg-primary dark:hover:bg-primary relative shadow-xl duration-high group max-w-[300px]"
              >
                <div className="h-[100px]">
                  <img
                    src={service.img}
                    alt=""
                    className="max-w-[100px] block mx-auto transform -translate-y-14
                  group-hover:scale-105  duration-300 shadow-md"
                  />
                </div>
                <div className="p-4 text-center">
                  <div className="w-full flex items-center justify-center gap-1">
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                    <FaStar className="text-yellow-500" />
                  </div>
                  <h1 className="text-xl font-bold">{service.title}</h1>
                  <p className="text-gray-500 group-hover:opacity-50 duration-high text-sm line-clamp-2">
                    {service.description}
                  </p>
                  {/* <div className="flex items-center justify-between mt-4">
                    <div>
                    <p className="text-sm text-[#780D00] dark:text-white font-bold">{service.rent}</p>
                     <button
                    className="hover:scale-105 bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    onClick={handleOrderPopup}
                  >
                    Rent
                  </button>
                    </div>

                    <div>
                    <p className="text-sm text-[#780D00] dark:text-white font-bold">{service.buy}</p>
                     <button
                    className="hover:scale-105 bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    onClick={handleOrderPopup}
                  >
                    Buy
                  </button>
                    </div>
                  </div> */}
                  <p className="text-sm text-[#780D00] mt-4 dark:text-white font-bold">{service.buy}</p>
                  <button
                    className="bg-gray-100 dark:bg-gradient-to-r from-orange-500 to-orange-800 hover:scale-105 duration-300 py-1 px-4 rounded-full mt-1 group-hover:bg-opacity-50 group-hover:opacity-50"
                    onClick={handleOrderPopup}
                  >
                    {service.text}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
