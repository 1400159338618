import React from 'react'
import { LegalData } from "../../../../static/data";


const Resources = () => {
  return (
    <div className="flex flex-col items-center lg:mt-10">
      <div className="flex flex-wrap mt-8 lg:mt-10">
        {LegalData.map((LegalData, index) => (
          <div key={index} className="w-full sm:w-1/2 lg:w-1/4">
            <div className="flex flex-col items-center justify-center gap-5">
              <div className="flex mx-6 h-20 w-20 bg-neutral-900 text-orange-700 justify-center items-center rounded-full text-5x1">
                {LegalData.icon}
              </div>
              <div className="text-center">
                <h5 className="mt-1 mb-6 text-xl font-semibold">
                  {LegalData.text}
                </h5>
                <p className="text-md p-2 mb-20 text-neutral-500">
                  {LegalData.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Resources