import React from "react";
import Register from "../components/auth/Register/Register.jsx";

const RegisterPage = () => {
  return (<div className="dark:bg-[#490800] dark:text-white"> 
<Register />
  </div>);
};

export default RegisterPage;

// <div className="text-center md:text-left">
//       <Navbar />
//       <div className="max-w-7xl mx-auto mb-[30rem] md:mb-10 px-6">
//         <Register />

//       </div>
//       <Footer />
//     </div>
