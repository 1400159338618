import React, { useState } from "react";
import styles from "../../styles/styles";
import moment from 'moment/moment'
import { resourcesLinks, platformLinks, govenLinks, communityLinks } from "../../static/data";
import { useNavigate } from "react-router-dom";


const Footer = () => {
     const [isOpen, setIsOpen] = useState(false);
     const navigate = useNavigate();

       const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const register =() => {
    navigate("/sign-up")
  };
  const m = moment();
  return (
    <div className="bg-[#fff] text-[#000] dark:bg-[#490800] dark:text-white">
      <div className="bg-[#780D00]">
        <div className={`${styles.section} sm:px-12 px-4 py-10`}>
          <h1 data-aos="fade-up" className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold text-white">
            Are you ready?
            <br />
            Get started for as low as ₦3,000!
          </h1>
          <p data-aos="fade-up" className="text-white mt-5 mb-5">
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit */}
          </p>
          <div data-aos="fade-up" className="flex items-center justify-normal gap-10 mb-5">
            <button 
            onClick={register}
            className="mt-5 cursor-pointer rounded-md border w-[180px] h-[45px] text-[#fff]">
              Create Account
            </button>
            <button
              onClick={toggleModal}
             className="mt-5 cursor-pointer rounded-md bg-[#fff] w-[180px] h-[45px] text-[#400215]">
              Request a Demo
            </button>

           
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-6 dark:bg-[#490800] dark:text-white">
        <footer className="mt-20 border-t py-10 border-neutral-700">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <h3 data-aos="fade-up" className="text-md font-semibold mb-4">Resources</h3>
              <ul data-aos="fade-up" className="space-y-2">
                {resourcesLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      className="text-neutral-300 hover:text-white"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 data-aos="fade-up" className="text-md font-semibold mb-4">Platform</h3>
              <ul data-aos="fade-up" className="space-y-2">
                {platformLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      className="text-neutral-300 hover:text-white"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 data-aos="fade-up" className="text-md font-semibold mb-4">Community</h3>
              <ul data-aos="fade-up" className="space-y-2">
                {communityLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      className="text-neutral-300 hover:text-white"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 data-aos="fade-up" className="text-md font-semibold mb-4">Government</h3>
              <ul data-aos="fade-up" className="space-y-2">
                {govenLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      className="text-neutral-300 hover:text-white"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </footer>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-800 text-sm pb-8"
        >
          <span>
            {" "}
            &copy; {`${m.format("YYYY")} Techkraft. All rights reserved`}
          </span>
          <span>Terms · Privacy Policy</span>
          <div className="sm:block flex items-center justify-center w-full">
            <img
              src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* Main modal */}
      {isOpen && (

         <>
          {/* Dark overlay */}
          <div
            className="fixed inset-0 z-40 bg-[#000] opacity-50"
            onClick={toggleModal} // Close modal when clicking on overlay
          ></div>
        <div
          id="authentication-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-red-900">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-800">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Fill the Form
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 1"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4" action="#">
                <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      autocomplete="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Phone Number
                    </label>
                    <input
                      type="number"
                      name="number"
                       autoComplete="number"
                      placeholder="Phone Number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                       autoComplete="text"
                      placeholder="Subject"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Write Comment
                    </label>
                    <textarea                     
                     placeholder="Write comments"
                      name="story"
                      rows="5"
                      cols="33"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>                 
                       
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
                  >
                    SUBMIT NOW
                  </button>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default Footer;
