import React from "react";
import LegalSearch from "../components/Products/LegalDB/LegalSearch/LegalSearch.jsx";
import Navbar from "../components/Layout/Navbar";
import Sponsored from "../components/Route/Sponsored";
import Testimonials from "../components/Route/Testimoney/Testimonials";
import Footer from "../components/Layout/Footer";
import { Tabs, Tab } from "../components/common/Tabs/Tabs.jsx";


const LegalSearchPage = () => {
  return (
    <div className="dark:bg-[#490800] dark:text-white ">
      <Navbar />
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col items-center mt-6 lg:mt-10 mb-10">
          <h1 className="text-4xl sm:text-6xl lg:text-6xl text-center tracking-wide">
            Legal
            <span className="text-[#A71200]">
              {" "}
              Database
            </span>
          </h1>
        </div>
        <div>
      <Tabs  data-aos="fade-up">
        <Tab label="Cases">
          <div className="py-4">
            {/* <h2 className="text-lg font-medium mb-2">Cases Search</h2> */}
            <LegalSearch />
          </div>
        </Tab>
        <Tab label="Laws">
          <div className="py-4">
            <h2 className="text-lg font-medium mb-2 text-center">Law Search</h2>
            <p className="text-lg text-red-700 font-bold">
              <marquee>Dont Have access to this yet</marquee>
            </p>
          </div>
        </Tab>       
      </Tabs>
    </div>
        
        <Testimonials />
        <Sponsored />
      </div>

      <Footer />
    </div>
  );
};

export default LegalSearchPage;
