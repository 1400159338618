import React, { useState } from "react";
import Card from './components/Card';
import PaymentMethod from './components/PaymentMethod';
import InvoiceItem from './components/InvoiceItem';
import mastercardLogo from '../../../Assests/img/logos/mastercard.png';
import visaLogo from '../../../Assests/img/logos/visa.png';
import BillingInformation from './components/BillingInformation';
import YourTransactions from './components/YourTransactions';

const Accounts = () => {
  const [isOpen, setIsOpen] = useState(false);

       const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const cards = [
    {
      backgroundImage: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/card-visa.jpg',
      cardNumber: '4562 1122 4594 7852',
      cardHolder: 'Jack Peterson',
      expiryDate: '11/22',
      logoSrc: mastercardLogo,
    },
    // Add more card data here
  ];

  const paymentMethods = [
    {
      cardType: 'Mastercard',
      cardNumber: '**** **** **** 7852',
      logoSrc: mastercardLogo,
    },
    {
      cardType: 'Visa',
      cardNumber: '**** **** **** 5248',
      logoSrc: visaLogo,
    }
    // Add more payment method data here
  ];

  const invoices = [
    {
      date: 'March, 01, 2020',
      invoiceNumber: '#MS-415646',
      amount: '180'
    },
    {
      date: 'February, 10, 2021',
      invoiceNumber: '#RV-126749',
      amount: '250'
    },
    {
      date: 'April, 05, 2020',
      invoiceNumber: '#FB-212562',
      amount: '560'
    },
    {
      date: 'June, 25, 2019',
      invoiceNumber: '#QW-103578',
      amount: '120'
    },
    {
      date: 'March, 01, 2019',
      invoiceNumber: '#AR-803481',
      amount: '300'
    }
  ];

  const billingInfoData = [
    {
      name: 'Oliver Liam',
      companyName: 'Viking Burrito',
      email: 'oliver@burrito.com',
      vatNumber: 'FRB1235476',
    },
    {
      name: 'Lucas Harper',
      companyName: 'Stone Tech Zone',
      email: 'lucas@stone-tech.com',
      vatNumber: 'FRB1235476',
    },
    {
      name: 'Ethan James',
      companyName: 'Fiber Notion',
      email: 'ethan@fiber.com',
      vatNumber: 'FRB1235476',
    },
  ];

  const transactionsData = {
    dateRange: '23 - 30 March 2020',
    newest: [
      {
        id: 1,
        name: 'Netflix',
        date: '27 March 2020, at 12:30 PM',
        amount: '- $2,500',
        type: 'debit', // debit for outflow, credit for inflow
      },
      {
        id: 2,
        name: 'Apple',
        date: '27 March 2020, at 04:30 AM',
        amount: '+ $2,000',
        type: 'credit',
      },
    ],
    yesterday: [
      {
        id: 1,
        name: 'Stripe',
        date: '26 March 2020, at 13:45 PM',
        amount: '+ $750',
        type: 'credit',
      },
      {
        id: 2,
        name: 'HubSpot',
        date: '26 March 2020, at 12:30 PM',
        amount: '+ $1,000',
        type: 'credit',
      },
      {
        id: 3,
        name: 'Creative Tim',
        date: '26 March 2020, at 08:30 AM',
        amount: '+ $2,500',
        type: 'credit',
      },
      {
        id: 4,
        name: 'Webflow',
        date: '26 March 2020, at 05:00 AM',
        amount: 'Pending',
        type: 'pending',
      },
    ],
  };


  return (
    <div className="flex flex-wrap -mx-3 lg:mt-4">
      <div className="max-w-full px-3 lg:w-2/3 lg:flex-none">
        <div className="flex flex-wrap -mx-3">
        {/* <Card /> */}
          {cards.map((card, index) => (
            <div key={index} className="w-full max-w-full px-3 xl:mb-0 xl:flex-none">
              <Card {...card} />
            </div>
          ))}
          <div className="max-w-full px-3 mb-6 lg:mb-0 lg:w-full lg:flex-none">
            <div className="relative flex flex-col min-w-0 mt-6 break-words bg-white dark:!bg-[#a711007a] border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="p-4 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                <div className="flex flex-wrap -mx-3">
                  <div className="flex items-center flex-none w-1/2 max-w-full px-3">
                    <h6 className="mb-0 dark:text-white">Payment Method</h6>
                  </div>
                  <div className="flex-none w-1/2 max-w-full px-3 text-right">
                    <button onClick={toggleModal} className="inline-block px-5 py-2.5 font-bold leading-normal text-center dark:text-white dark:!bg-[#a711007a] align-middle transition-all bg-transparent rounded-lg cursor-pointer text-sm ease-in shadow-md bg-150 bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 hover:shadow-xs active:opacity-85 hover:-translate-y-px tracking-tight-rem bg-x-25" href="javascript:;">
                      <i className="fas fa-plus"> </i>&nbsp;&nbsp;Add New Card
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-auto p-4">
                <div className="flex flex-wrap -mx-3">
                  {paymentMethods.map((method, index) => (
                    <div key={index} className="max-w-full px-3 mb-6 md:mb-0 md:w-1/2 md:flex-none">
                      <PaymentMethod {...method} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-full px-3 lg:w-1/3 lg:flex-none">
        <div className="relative flex flex-col h-full min-w-0 break-words bg-white dark:!bg-[#a711007a] border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
          <div className="p-4 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
            <div className="flex flex-wrap -mx-3">
              <div className="flex items-center flex-none w-1/2 max-w-full px-3">
                <h6 className="mb-0 dark:text-white">Invoices</h6>
              </div>
              <div className="flex-none w-1/2 max-w-full px-3 text-right">
                <button className="inline-block px-8 py-2 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer text-sm ease-in bg-150 active:opacity-85 hover:-translate-y-px tracking-tight-rem bg-x-25 bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850">
                  View All
                </button>
              </div>
            </div>
          </div>
          <div className="flex-auto p-4">
            <ul className="flex flex-col pl-0 mb-0 rounded-lg">
              {invoices.map((invoice, index) => (
                <InvoiceItem key={index} {...invoice} />
              ))}
            </ul>
          </div>
        </div>
      </div>

       
      <div className="w-full max-w-full px-3 mt-6 md:w-7/12 md:flex-none">
        <BillingInformation billingInfo={billingInfoData} />
      </div>
      <div className="w-full max-w-full px-3 mt-6 md:w-5/12 md:flex-none">
        <YourTransactions transactions={transactionsData} />
      
    </div>

     {/* Main modal */}
      {isOpen && (

         <>
          {/* Dark overlay */}
          <div
            className="fixed inset-0 z-40 bg-[#000] opacity-50"
            onClick={toggleModal} // Close modal when clicking on overlay
          ></div>
        <div
          id="authentication-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:!bg-[#a711007a]">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Fill the Form
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 1"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4" action="#">
                <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      autocomplete="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:!bg-[#a711007a] dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:!bg-[#a711007a] dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Phone Number
                    </label>
                    <input
                      type="number"
                      name="number"
                       autoComplete="number"
                      placeholder="Phone Number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:!bg-[#a711007a] dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                       autoComplete="text"
                      placeholder="Subject"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:!bg-[#a711007a] dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Write Comment
                    </label>
                    <textarea                     
                     placeholder="Write comments"
                      name="story"
                      rows="5"
                      cols="33"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:!bg-[#a711007a] dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>                 
                       
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-bold rounded-xl text-white  bg-gradient-to-r from-orange-500 to-red-800 hover:opacity-80"
                  >
                    SUBMIT NOW
                  </button>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default Accounts;